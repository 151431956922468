import { useTheme } from '@tryrolljs/design-system'

type Props = {
  onClick?: () => void
}

export const IconButton: React.FC<Props> = ({ children, onClick }) => {
  const theme = useTheme()
  return (
    <div
      className="p-3 flex self-auto cursor-pointer"
      onClick={onClick}
      style={{
        maxWidth: 48,
        borderRadius: 100,
        border: `1px solid ${theme.text.highlight}`,
        backgroundColor: theme.background.primary,
      }}
    >
      {children}
    </div>
  )
}
