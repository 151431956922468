import { useState } from "react";
import { CampaignDetailsCtx } from "../context/campaign";

export const ProviderCampaignDetails: React.FC = ({ children }) => {
  const [disableButtons, setDisableButtons] = useState(false);

  return (
    <CampaignDetailsCtx.Provider value={{ disableButtons, setDisableButtons }}>
      {children}
    </CampaignDetailsCtx.Provider>
  );
};
