import { BigNumber } from '@ethersproject/bignumber'
import { constants } from 'ethers'
import { useCallback, useEffect, useMemo } from 'react'
import {
  Button,
  container,
  margin,
  Body,
  Title,
  useTheme,
  Value,
  Tooltip,
} from '@tryrolljs/design-system'
import { Activity } from '../../atoms/activity'
import {
  campaignIsActive,
  campaignIsComplete,
  campaignIsUpcoming,
  isCampaignOwner,
} from '../../core'
import {
  useAmountStaked,
  useApproveToken,
  useCampaign,
  useCheckIsTokenApproved,
  useClaimFreeTokens,
  useEthAddress,
  useGetAmountStaked,
  useModal,
  useTokenAllowance,
  useTokenByAddress,
} from '../../hooks'
import { FormStakeToken } from '../../molecules/formStakeToken'
import { displayAmount } from '../../util'
import { FormWithdraw } from '../formWithdraw'
import { Indicator } from '../../atoms/indicator'
import { ExtendCampaignManager } from '../campaignWizard'
import InfoIcon from '../../assets/svg/info.svg'
import { useCampaignDetailsCtx } from '../../context/campaign'
import { WithdrawButton } from '../../molecules/withdrawLeftOver'
import { TokenLogo } from '../../molecules/tokenLogo'

type Props = {
  address: string
}

export const StakeTokens = ({ address }: Props) => {
  const modal = useModal()
  const theme = useTheme()
  const campaign = useCampaign(address)
  const stakeToken = useTokenByAddress(campaign.tokenAddress)
  const [approveToken, approveTokenState] = useApproveToken()
  const tokenAllowance = useTokenAllowance(campaign.tokenAddress, address)
  const [checkIsApproved] = useCheckIsTokenApproved()
  const [_getAmountStaked] = useGetAmountStaked(address)
  const amountStaked = useAmountStaked(address)
  const ethAddr = useEthAddress()
  const isOwner = isCampaignOwner(ethAddr || '', campaign)
  const [claimFreeTokens, claimAsync] = useClaimFreeTokens()
  const isApproved = useMemo(
    () => tokenAllowance.gt(BigNumber.from(0)),
    [tokenAllowance],
  )

  const _claimFreeTokens = useCallback(async () => {
    claimFreeTokens(address)
  }, [address, claimFreeTokens])

  const _approveToken = useCallback(async () => {
    await approveToken(address, campaign.tokenAddress, constants.MaxUint256)
  }, [address, campaign.tokenAddress, approveToken])

  const openUpdateModal = useCallback(() => {
    modal.setRenderer(
      () => <ExtendCampaignManager campaignAddress={address} />,
      {
        style: { padding: 0 },
      },
    )
    modal.setOpen(true)
  }, [modal, address])

  const openStakeModal = () => {
    modal.setRenderer(() => (
      <FormStakeToken address={address} onSucces={() => _getAmountStaked()} />
    ))
    modal.setOpen(true)
  }

  const openWithdrawModal = () => {
    modal.setRenderer(() => <FormWithdraw campaignAddr={address} />)
    modal.setOpen(true)
  }

  useEffect(() => {
    checkIsApproved(address, campaign.tokenAddress).catch((err) =>
      console.error(err),
    )
  }, [address, campaign.tokenAddress, checkIsApproved])

  useEffect(() => {
    _getAmountStaked().catch((err) => console.error(err))
  }, [_getAmountStaked, address, campaign.tokenAddress])

  return (
    <div className="p-4">
      <div className="flex flex-col">
        <div className="flex items-center">
          <Body weight="bold" style={[container.alignSelfStart, margin.mr8]}>
            Stake
          </Body>
          <Tooltip title="You can unstake, add more tokens.">
            <div className="p-1 cursor-pointer">
              <InfoIcon />
            </div>
          </Tooltip>
        </div>
      </div>
      <div
        className="flex flex-col items-center m-2 p-8 rounded-lg justify-end"
        style={{ backgroundColor: theme.background.page, minHeight: 287 }}
      >
        <TokenLogo token={stakeToken} style={margin.mb16} />
        <Value
          displayValue={displayAmount(amountStaked, stakeToken.decimals)}
          decimals={stakeToken.decimals}
          renderValue={(val) => <Title style={margin.mt16}>{val}</Title>}
        />
        <Body style={margin.mv16}>Staked {stakeToken.symbol}</Body>
        <StakeInteraction
          campaignAddress={address}
          claimFreeTokens={_claimFreeTokens}
          activity={approveTokenState.activity || claimAsync.activity}
          symbol={stakeToken.symbol}
          amountStaked={amountStaked}
          openStakeModal={openStakeModal}
          openWithdrawModal={openWithdrawModal}
          isApproved={isApproved}
          approveToken={_approveToken}
          isOwner={isOwner}
          openUpdateModal={openUpdateModal}
        />
      </div>
    </div>
  )
}

type ActiveStakeProps = {
  onUnStake: () => void
  onStakeMore: () => void
  isActive: boolean
  isUpcoming: boolean
}

const ActiveStakeButtons = ({
  onUnStake,
  onStakeMore,
  isActive,
  isUpcoming,
}: ActiveStakeProps) => {
  const { disableButtons } = useCampaignDetailsCtx()
  return (
    <div className="flex flex-row items-center">
      <Button
        variant="primary"
        disabled={disableButtons}
        style={margin.m16}
        title="Unstake"
        onPress={onUnStake}
      />
      {(isActive || isUpcoming) && (
        <Button
          variant="primary"
          disabled={disableButtons}
          title="Stake More"
          onPress={onStakeMore}
        />
      )}
    </div>
  )
}
type StakeInteractionProps = {
  activity: boolean
  symbol: string
  amountStaked: BigNumber
  openStakeModal: () => void
  openWithdrawModal: () => void
  isApproved: boolean
  approveToken: () => void
  openUpdateModal: () => void
  claimFreeTokens: () => void
  isOwner: boolean
  campaignAddress: string
}

const StakeInteraction = ({
  activity,
  symbol,
  amountStaked,
  openStakeModal,
  openWithdrawModal,
  isApproved,
  approveToken,
  isOwner,
  openUpdateModal,
  claimFreeTokens,
  campaignAddress,
}: StakeInteractionProps) => {
  const campaign = useCampaign(campaignAddress)
  const isActive = campaignIsActive(campaign)
  const isCompleted = campaignIsComplete(campaign)
  const { disableButtons } = useCampaignDetailsCtx()
  const isUpcoming = campaignIsUpcoming(campaign)

  const renderCompleteWarning = () => {
    if (isOwner) {
      return (
        <Indicator
          className="mt-4"
          message="This staking contract is completed. Update to continue."
          level="error"
        />
      )
    }
    return (
      <Indicator
        className="mt-4"
        message="This staking contract is completed."
        level="error"
      />
    )
  }

  const renderButton = () => {
    if (!isActive && !isUpcoming) return null

    if (!isApproved) {
      return (
        <Button
          variant="primary"
          disabled={disableButtons}
          style={[margin.mv16]}
          title={`Approve ${symbol}`}
          onPress={approveToken}
        />
      )
    }

    return (
      <Button
        variant="primary"
        disabled={!isActive && disableButtons}
        style={[margin.mv16]}
        title="Stake"
        onPress={openStakeModal}
      />
    )
  }

  if (activity) {
    return <Activity />
  }

  if (amountStaked.gt(BigNumber.from(0))) {
    return (
      <ActiveStakeButtons
        isUpcoming={isUpcoming}
        isActive={isActive}
        onStakeMore={openStakeModal}
        onUnStake={openWithdrawModal}
      />
    )
  }

  return (
    <div>
      {renderButton()}
      {isCompleted && isOwner && (
        <div className="flex justify-between">
          <WithdrawButton
            campaignAddress={campaignAddress}
            claimFreeTokens={claimFreeTokens}
          />
          <Button
            style={container.flex1}
            variant="primary"
            title="Extend Contract"
            onPress={openUpdateModal}
          />
        </div>
      )}
      {isCompleted && renderCompleteWarning()}
    </div>
  )
}
