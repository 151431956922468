import { Body, useTheme } from '@tryrolljs/design-system'

type StyledLinkProps = {
  title: string
  url: string
  target?: string
  weight?: 'regular' | 'bold' | undefined
}

const StyledLink = ({
  title,
  url,
  target = '_blank',
  weight = 'regular',
}: StyledLinkProps) => {
  const theme = useTheme()

  return (
    <a href={url} target={target}>
      <Body color={theme.text.highlight} weight={weight}>
        {title}
      </Body>
    </a>
  )
}

export default StyledLink
