import {
  useTheme,
  dodgerBlue,
  Body,
  charcoalBlack,
} from '@tryrolljs/design-system'
import { matchPath, useLocation, useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import LogoHorizontal from '../../assets/svg/logo.svg'
import { navigateHome, navigateMyCampaigns } from '../../navigation/actions'
import { MAX_SCREEN_CONTENT_WIDTH } from '../../constants'
import { useEthAddress } from '../../hooks'
import { ConnectWallet } from '../../organisms/connectWallet'

type NavOption = {
  title: string
  path: string
  navigate: any
}

const options = [
  {
    title: 'All Staking Rewards',
    path: '/',
    navigate: navigateHome,
  },
  {
    title: 'My Staking Rewards',
    path: '/my-staking-rewards',
    navigate: navigateMyCampaigns,
  },
]

const NavBarButton = ({
  nav,
  onClick,
}: {
  nav: NavOption
  onClick: () => void
}) => {
  const location = useLocation()
  return (
    <div
      onClick={onClick}
      className="flex items-center h-full w-36 mx-3 cursor-pointer border-b-2"
      style={{
        borderBottomColor: matchPath(location.pathname, nav.path)
          ? dodgerBlue
          : 'transparent',
      }}
    >
      <Body weight="bold" color={charcoalBlack}>
        {nav.title}
      </Body>
    </div>
  )
}

export const PageHeader = () => {
  const theme = useTheme()
  const nav = useNavigate()
  const address = useEthAddress()

  return (
    <div
      className="flex h-16 justify-center shadow-xl"
      style={{ backgroundColor: theme.background.primary }}
    >
      <div
        className="flex flex-row w-4/5 h-full justify-between"
        style={{ maxWidth: MAX_SCREEN_CONTENT_WIDTH }}
      >
        <Link className="self-center" to="/">
          <LogoHorizontal />
        </Link>
        <div className="flex h-full">
          <div className="flex mr-6 justify-between">
            <NavBarButton nav={options[0]} onClick={() => navigateHome(nav)} />
            {address ? (
              <NavBarButton
                nav={options[1]}
                onClick={() => navigateMyCampaigns(nav)}
              />
            ) : null}
          </div>
          <div className="flex items-center h-full">
            <ConnectWallet />
          </div>
        </div>
      </div>
    </div>
  )
}
