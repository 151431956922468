import { useState } from 'react'
import { useTheme, Body } from '@tryrolljs/design-system'
import Copy from '../../assets/svg/copy.svg'
import Check from '../../assets/svg/check.svg'
import { copyClipboard } from '../../util'

type Props = {
  value: string
}

export const ClipBoardText = ({ value }: Props) => {
  const theme = useTheme()
  const [check, setcheck] = useState(false)

  const copy = async () => {
    try {
      await copyClipboard(value)
      setcheck(true)
      setTimeout(() => {
        setcheck(false)
      }, 500)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div onClick={copy} className="border-b flex items-center p-2">
      <Body style={{ marginRight: 16 }} color={theme.text.secondary}>
        {value}
      </Body>
      {check ? <Check /> : <Copy />}
    </div>
  )
}
