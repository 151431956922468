import Big from 'big.js'
import { BigNumber } from '@ethersproject/bignumber'
import { addDays, addMonths } from 'date-fns'
import { FixedNumber } from 'ethers'
import { IndicatorProps } from '../atoms/indicator'
import {
  displayDate,
  displayTime,
  isAfterNow,
  isBeforeNow,
  MILLISECONDS_DAY,
  MILLISECONDS_HOUR,
  MILLISECONDS_MINUTE,
  MILLISECONDS_SECOND,
  durationSeconds,
  sortFuncBigNum,
  displayAmount,
  bigMaxDenom,
  bigMinDenom,
} from '../util'

export type CampaignStatus =
  | 'Active'
  | 'Inactive'
  | 'Complete'
  | 'Upcoming'
  | 'Not Sent'
  | 'Must notify'

export interface StakingRewardToken {
  address: string
  value: BigNumber
  rate?: BigNumber
  free?: BigNumber
}

export type Campaign = {
  address: string
  rewards: StakingRewardToken[]
  start: BigNumber
  end: BigNumber
  duration: BigNumber
  total: BigNumber
  tokenAddress: string // token to be staked
  rewardTokenAddresses: string[] // tokens that are used for rewards
  status: CampaignStatus
  owner: string
}

// TODO - do we want any minimum duration?
export const validateCampaignDuration = (
  start: Date,
  end: Date,
): IndicatorProps | null => {
  if (new Date().getTime() - start.getTime() >= 0) {
    return { message: 'start date must be after now.', level: 'error' }
  }

  // Ideally we just enforce that the dates cannot match, but the date picker is unfortunately innacurate within the minute
  if (durationSeconds(start, end) < 60) {
    return {
      message: 'Start and end date are too close together',
      level: 'error',
    }
  }

  if (end.getTime() - start.getTime() < 0) {
    return { message: 'End date must be after start date.', level: 'error' }
  }

  return null
}

export const setCampaignTokenReward = (
  campaign: Campaign,
  token: string,
  value: BigNumber,
) => {
  for (let i = 0; i < campaign.rewards.length; i++) {
    if (campaign.rewards[i].address === token) {
      campaign.rewards[i].value = value
    }
  }
}

export const setCampaignDuration = (
  campaign: Campaign,
  start: Date,
  end: Date,
) => {
  campaign.start = BigNumber.from(start.getTime())
  campaign.end = BigNumber.from(end.getTime())
}

export const displayCampaignStart = (c: Campaign): string => {
  if (c.start.isZero()) return '-'
  const start = campaignStartDate(c)
  return `${displayDate(start)} ${displayTime(start)} EST`
}

export const displayCampaignEnd = (c: Campaign): string => {
  if (!isDurationSet(c)) return '-'
  const end = campaignEndDate(c)
  return `${displayDate(end)} ${displayTime(end)} EST`
}

export const getRemainingSeconds = (c: Campaign): BigNumber => {
  // if the campaign has not started yet just return the total duration
  if (isAfterNow(campaignStartDate(c))) {
    return campaignDurationSeconds(c)
  }

  // if the end date has passed then there is no remaining seconds
  if (isBeforeNow(campaignEndDate(c))) {
    return BigNumber.from(0)
  }

  // seconds from now till end
  return BigNumber.from(durationSeconds(new Date(), campaignEndDate(c)))
}

export const campaignDurationSeconds = (c: Campaign): BigNumber => {
  return BigNumber.from(
    durationSeconds(campaignStartDate(c), campaignEndDate(c)),
  )
}

export const displayCampaignTimeRemaing = (c: Campaign) => {
  if (!campaignIsActive(c) || !isDurationSet(c)) return '-'

  // max 0 to avoid negative date display
  const secondsTillEnd = Math.max(
    0,
    campaignEndDate(c).getTime() - new Date().getTime(),
  )
  const remaining = new Date(secondsTillEnd)

  // total remaining milliseconds
  const milliseconds = remaining.getTime()

  // total remaining days
  const days = Math.floor(milliseconds / MILLISECONDS_DAY)
  const milliDays = days * MILLISECONDS_DAY

  // hours remaining after days
  const hours = Math.floor((milliseconds - milliDays) / MILLISECONDS_HOUR)
  const milliHours = hours * MILLISECONDS_HOUR

  // minutes remaining after hours
  const minutes = Math.floor(
    (milliseconds - milliDays - milliHours) / MILLISECONDS_MINUTE,
  )

  // human readable
  return `${days}d ${hours}h ${minutes}m`
}

export const campaignIsComplete = (c: Campaign) => c.status === 'Complete'

export const campaignIsUpcoming = (c: Campaign) => c.status === 'Upcoming'

export const campaignIsActive = (c: Campaign) => c.status === 'Active'

export const campaignIsInactive = (c: Campaign) => c.status === 'Inactive'

// note that the campaign date is already in EST, so we shouldn't convert this to EST
export const campaignStartDate = (c: Campaign) => new Date(c.start.toNumber())

// note that the campaign date is already in EST, so we shouldn't convert this to EST
export const campaignEndDate = (c: Campaign) =>
  new Date(c.start.add(c.duration).toNumber())

export const isCampaignOwner = (addr: string, c: Campaign) => addr === c.owner

export const validateReward = (
  start: BigNumber,
  end: BigNumber,
  value: BigNumber,
): string | null => {
  if (value.isZero()) {
    return 'Value cannot be zero'
  }

  if (value.isNegative()) {
    return 'Value cannot be negative'
  }

  const time = end.sub(start)

  if (time.gt(value)) {
    return 'Value must be at least time'
  }

  const rate = value.div(time)

  if (!rate.mul(time).eq(value)) {
    return 'Must be whole'
  }

  return null
}

export const campaignRatePreview = (
  durationSeconds_: number,
  value: BigNumber,
) => {
  const time = BigNumber.from(durationSeconds_)
  const rate = value.div(time)
  const totalDistribute = rate.mul(time)
  const remainder = value.sub(totalDistribute)
  return { rate, totalDistribute, remainder }
}

const hasSetRewards = (campaign: Campaign): boolean => {
  // check if all rewards have been distributed
  for (let i = 0; i < campaign.rewards.length; i++) {
    if (campaign.rewards[i].value.isZero()) {
      return false
    }
  }
  return true
}

export const evaluateCampaignStatus = (campaign: Campaign): CampaignStatus => {
  // campaign has not set duration yet
  if (!isDurationSet(campaign)) {
    return 'Inactive'
  }

  // campaign end date has passed
  if (isBeforeNow(campaignEndDate(campaign))) {
    return 'Complete'
  }

  // does not have a valid reward rate
  if (!hasSetRewards(campaign)) return 'Inactive'

  // campaign has not started yet
  if (isAfterNow(campaignStartDate(campaign))) {
    return 'Upcoming'
  }

  return 'Active'
}

export const isDurationSet = (c: Campaign) => !c.duration.isZero()

export const defaultCampaignDuration = (): [Date, Date] => {
  const start = addDays(new Date(), 1)
  const end = addMonths(start, 1)

  // const start = addMinutes(new Date(), 15);
  // const end = addMinutes(new Date(), 30);

  // const start = addMinutes(new Date(), 5);
  // const end = addMinutes(new Date(), 8);

  return [start, end]
}

export const evaluateMaxRewardAmount = (
  rewardRate: BigNumber, // rate of tokens per second
  originalDuration: BigNumber,
  remainingDuration: BigNumber, // remaining seconds in contract
  currentStakeAmount: BigNumber, // value the user current stakes
  stakeAmount: BigNumber, // tokens that the user is staking
  totalStakedTokens: BigNumber, // total tokens in staked pool
  tokenDecimals: number, // staking token decimals
  currentRewards: BigNumber, // the existing user rewards
) => {
  // the total rewards available for this token
  const totalRewards = rewardRate.mul(originalDuration)

  if (stakeAmount.isZero()) return BigNumber.from(0) // cannot earn any tokens if not staking
  if (totalStakedTokens.isZero()) return totalRewards // earn all tokens if there is no a staked value

  // the total staked tokens is the sum of the existing total plus the hypothetical user addition
  const _totalStakedTokens = bigMaxDenom(
    totalStakedTokens.add(stakeAmount),
    tokenDecimals,
  )

  // the stake amount is the sum of the users current stake value and the new stake value
  const _stakeAmount = bigMaxDenom(
    stakeAmount.add(currentStakeAmount),
    tokenDecimals,
  )

  // pool share is the percentage of the pool owned by the user, eg 0 - 1
  const poolShare = _stakeAmount.div(_totalStakedTokens)

  const _remainingDuration = bigMaxDenom(remainingDuration, tokenDecimals)
  const _ogDuration = bigMaxDenom(originalDuration, tokenDecimals)
  const _totalRewards = bigMaxDenom(totalRewards, tokenDecimals)

  const remainingRewards = _remainingDuration
    .div(_ogDuration)
    .mul(_totalRewards)

  const share = poolShare.mul(remainingRewards)
  const precisionShare = share.toPrecision(tokenDecimals)
  // remaining is the users share of the remaining rewards
  const remainingShare = bigMinDenom(new Big(precisionShare), tokenDecimals)
  const totalRewardEstimate = remainingShare.add(currentRewards)

  return totalRewardEstimate
}

export const asyncSuggestDuration = async (
  startDate: Date,
  endDate: Date,
  value: BigNumber,
): Promise<[Date, Date]> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject()
    }, 3000)
    resolve(suggestDuration(startDate, endDate, value))
  })
}

export const suggestDuration = (
  startDate: Date,
  endDate: Date,
  value: BigNumber,
): [Date, Date] => {
  const duration = new Big(durationSeconds(startDate, endDate))
  const _value = new Big(value.toString())
  const newDurSeconds = findNearestFactor(_value, duration)
  const newDurMilliSeconds = newDurSeconds.mul(MILLISECONDS_SECOND)
  const endDateMilliseconds = new Big(startDate.getTime()).add(
    newDurMilliSeconds,
  )
  return [startDate, new Date(endDateMilliseconds.toNumber())]
}

export const findNearestFactor = (value: Big, duration: Big) => {
  if (value.mod(duration).eq(0)) return duration

  const half = value.div(2)

  if (duration.gt(half)) {
    // return value or half, whichever is closer
    return duration.sub(half).gt(value.sub(duration)) ? value : duration
  }

  let i = 0
  while (true) {
    i += 1
    const x = duration.add(i)
    const y = duration.sub(i)

    if (value.mod(x).eq(0)) {
      return x
    }

    if (y.gt(0) && value.mod(y).eq(0)) {
      return y
    }
  }
}

export const areRewardsMultiples = (
  sortedValues: SuggestDurationForm[],
): [boolean, SuggestDurationForm[]] => {
  if (!sortedValues.length) return [false, []]
  if (sortedValues.length === 1) return [true, []]

  const remainders: SuggestDurationForm[] = []

  const smallest = sortedValues[0]

  for (let i = 1; i < sortedValues.length; i++) {
    const remainder = sortedValues[i].val.mod(smallest.val)
    if (!remainder.isZero()) {
      remainders.push({ val: remainder, decimals: sortedValues[i].decimals })
    }
  }

  return [remainders.length === 0, remainders]
}

export const isSafeRemainderRange = (remainders: SuggestDurationForm[]) =>
  remainders.reduce((acc: boolean, curr: SuggestDurationForm) => {
    const stringVal = displayAmount(curr.val, curr.decimals)

    const safeVal = Big(stringVal)
    if (safeVal.gt(0.5)) {
      return false
    }
    return acc
  }, true)

export interface SuggestDurationForm {
  val: BigNumber
  decimals: number
}

export const suggestMultiTokenDuration = (
  startDate: Date,
  endDate: Date,
  values: SuggestDurationForm[],
): [Date, Date, boolean, boolean] => {
  const sortedValues = values.sort((a, b) => sortFuncBigNum(a.val, b.val))

  const [multiples, remainders] = areRewardsMultiples(sortedValues)
  const isSafe = isSafeRemainderRange(remainders)
  const hasRemainders = remainders.length !== 0

  if (multiples || isSafe) {
    return [
      ...suggestDuration(startDate, endDate, sortedValues[0].val),
      hasRemainders,
      isSafe,
    ]
  }

  return [startDate, endDate, hasRemainders, isSafe]
}

export const suggestValue = (
  startDate: Date,
  endDate: Date,
  value: BigNumber,
): [BigNumber, boolean] => {
  const duration = BigNumber.from(durationSeconds(startDate, endDate))
  return findNearestMultiple(duration, value)
}

export const findNearestMultiple = (
  duration: BigNumber,
  value: BigNumber,
): [BigNumber, boolean] => {
  const newVal = value.sub(value.mod(duration))
  return [newVal, !newVal.eq(value)]
}

export const suggestMultiTokenValue = (
  startDate: Date,
  endDate: Date,
  values: BigNumber[],
): [BigNumber[], boolean] => {
  const duration = BigNumber.from(durationSeconds(startDate, endDate))
  return findNearestMultiples(duration, values)
}

export const findNearestMultiples = (
  duration: BigNumber,
  values: BigNumber[],
): [BigNumber[], boolean] => {
  let hasChanged = false
  const newValues = values.map((v) => {
    const [newVal, changed] = findNearestMultiple(duration, v)
    if (changed) {
      hasChanged = true
    }
    return newVal
  })
  return [newValues, hasChanged]
}

export const evaluateParticipation = (
  stakedAmount: BigNumber,
  totalStakedTokens: BigNumber,
) => {
  if (totalStakedTokens.isZero()) {
    return undefined
  }

  const entireCampaignPercentage = BigNumber.from(100)

  return FixedNumber.fromValue(stakedAmount.mul(entireCampaignPercentage))
    .divUnsafe(FixedNumber.fromValue(totalStakedTokens))
    .round(4)
}
