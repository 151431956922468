import { margin, useTheme, Body } from '@tryrolljs/design-system'
import { TokenCard } from '../tokenCard'
import { TokenValue } from '../../molecules/tokenValue'
import { useCreateCampaignCtx, useDeployCampaignForm } from '../../hooks'
import { displayDate, displayTime } from '../../util'

export const FormCampaignSummary = () => {
  const { values } = useCreateCampaignCtx()
  const deployCampaignForm = useDeployCampaignForm()

  return (
    <div>
      <SummaryItem title="Timeframe (EST)">
        <Body>{`${displayDate(values.start)} ${displayTime(
          values.start,
        )} - ${displayDate(values.end)} ${displayTime(values.end)}`}</Body>
      </SummaryItem>
      <SummaryItem title="Rewards">
        {deployCampaignForm.rewards.map((r, i) => (
          <TokenValue
            key={r.address + i}
            value={r.value}
            address={r.address}
            containerStyle={{ marginBottom: 8 }}
          />
        ))}
      </SummaryItem>
      <SummaryItem title="Eligibility">
        <TokenCard address={deployCampaignForm.stakeTokenAddress} />
      </SummaryItem>
    </div>
  )
}

type SummaryProps = {
  title: string
}

const SummaryItem: React.FC<SummaryProps> = ({ children, title }) => {
  const theme = useTheme()
  return (
    <div className="flex flex-col mb-6">
      <Body
        color={theme.text.secondary}
        style={[margin.mb8, { color: theme.text.secondary }]}
      >
        {title}
      </Body>
      {children}
    </div>
  )
}
