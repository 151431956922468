import { useEffect, useMemo, useState } from 'react'
import { Activity } from '../../atoms/activity'
import { CampaignStatus } from '../../core'
import {
  useCampaigns,
  useGetCampaigns,
  useGetOwnerCampaigns,
  useIsBlackListed,
  useOwnerCampaigns,
} from '../../hooks'
import { CampaignCard } from '../../molecules/campaignCard'

type OwnerCampaignGridProps = {
  style?: React.CSSProperties
  address: string
  onSelect?: (addr: string) => void
}

export const OwnerCampaignGrid = ({
  address,
  onSelect,
  style,
}: OwnerCampaignGridProps) => {
  const getCampaigns = useGetOwnerCampaigns(address)
  const campaigns = useOwnerCampaigns(address)

  return (
    <CampaignGridBase
      campaignAddresses={campaigns}
      onSelect={onSelect}
      getCampaigns={getCampaigns}
      style={style}
    />
  )
}

export type CampaignGridProps = {
  onSelect?: (addr: string) => void
  style?: React.CSSProperties
  statusFilter?: CampaignStatus
}

export const CampaignGrid = ({
  onSelect,
  style,
  statusFilter,
}: CampaignGridProps) => {
  const getCampaigns = useGetCampaigns()
  const campaigns = useCampaigns(statusFilter)
  return (
    <CampaignGridBase
      campaignAddresses={campaigns}
      onSelect={onSelect}
      getCampaigns={getCampaigns}
      style={style}
    />
  )
}

type CampaignGridBaseProps = {
  style?: React.CSSProperties
  onSelect?: (addr: string) => void
  getCampaigns: (onSuccess?: () => void, onFail?: () => void) => void
  campaignAddresses: string[]
}

const CampaignGridBase = ({
  getCampaigns,
  onSelect,
  style,
  campaignAddresses,
}: CampaignGridBaseProps) => {
  const [activity, setActivity] = useState(true)
  const stopActivity = () => setActivity(false)
  const isBlackListed = useIsBlackListed()

  useEffect(() => {
    getCampaigns(stopActivity, stopActivity)
  }, [getCampaigns])

  const validCampaignAddresses = useMemo(() => {
    return campaignAddresses.filter((address) => !isBlackListed(address))
  }, [campaignAddresses, isBlackListed])

  return (
    <>
      <div className="flex flex-wrap justify-center -mx-8" style={style}>
        {validCampaignAddresses.map((contractAddr) => (
          <CampaignCard
            key={contractAddr}
            address={contractAddr}
            onSelect={() => onSelect && onSelect(contractAddr)}
          />
        ))}
      </div>
      {activity ? (
        <div className="flex place-content-center my-6">
          <Activity style={{ justifyContent: 'center' }} />
        </div>
      ) : null}
    </>
  )
}
