import { useState } from "react";
import { useIndicator } from "../../atoms/indicator";
import { defaultEthErrorMessages, EthProviderError, parseEthError } from "../../error";
import { useAmountStaked, useCampaign, useModal, useTokenByAddress, useUnstake } from "../../hooks";
import { FormUserContractInteract } from "../../molecules/formUserContractInteract";
import { displayAmount, parseValue } from "../../util";

type Props = {
  campaignAddr: string;
};

export const FormWithdraw = ({ campaignAddr }: Props) => {
  const { tokenAddress } = useCampaign(campaignAddr);
  const token = useTokenByAddress(tokenAddress);
  const modal = useModal();
  const [value, setValue] = useState("");
  const [ind, setInd] = useIndicator();
  const amountAvailable = useAmountStaked(campaignAddr);
  const [handleUnstake, unstakeState] = useUnstake(campaignAddr);

  const _withdraw = async () => {
    try {
      if (!value) return;
      await handleUnstake(parseValue(value, token.decimals));
      modal.setOpen(false);
    } catch (err) {
      setInd(
        ...parseEthError(err as EthProviderError, defaultEthErrorMessages, {
          message: "Unable to withdraw",
          level: "error"
        })
      );
    }
  };

  return (
    <FormUserContractInteract
      title={`Unstake ${token.symbol}`}
      buttonTitle="Unstake"
      amountAvailable={amountAvailable}
      onSetMax={() => setValue(displayAmount(amountAvailable, token.decimals))}
      token={token}
      onClose={() => modal.setOpen(false)}
      value={value}
      setValue={setValue}
      ind={ind}
      onConfirm={_withdraw}
      activity={unstakeState.activity}
    />
  );
};
