import { useRef } from 'react'
import { CampaignWizardForm } from '../../context/campaign'
import { Campaign, campaignEndDate, campaignIsActive } from '../../core'
import {
  useCampaign,
  useCreateCampaignCtx,
  useNetworkConfig,
} from '../../hooks'
import { LaunchStepsManager, versionLaunchSteps } from './common'
import {
  LaunchStep,
  LaunchStepName,
  launchStepsUpdateV1,
  launchStepsUpdateV2,
} from './steps'

// check if form values are different from campaign
export const stepDurationDone = (
  c: Campaign,
  values: CampaignWizardForm,
): boolean => {
  const campaignEnd = campaignEndDate(c).getTime() // derive the end date
  const formStart = values.start.getTime()
  const formEnd = values.end.getTime()

  if (c.start.eq(formStart) && campaignEnd === formEnd) {
    return true
  }
  return false
}

const checkIsDone = (
  stepName: LaunchStepName,
  c: Campaign,
  values: CampaignWizardForm,
): boolean => {
  switch (stepName) {
    case 'deploy':
      return true

    case 'duration':
      return stepDurationDone(c, values)

    case 'approveRewards':
      // this step is always false, because the contract will return
      // unused rewards before depositing new ones.
      // the contract owner must ensure that they have approved the total value
      return false

    case 'initCampaign':
      return campaignIsActive(c)

    default:
      return false
  }
}

export const buildSteps = (
  c: Campaign,
  values: CampaignWizardForm,
  steps: LaunchStep[],
): LaunchStep[] => {
  const out: LaunchStep[] = []

  steps.forEach((step) => {
    const done = checkIsDone(step.name, c, values)
    if (!done) {
      out.push(step)
    }
  })

  return out
}

export const FormUpdateLaunchSteps = () => {
  const { campaignAddress, values } = useCreateCampaignCtx()
  const networkConfig = useNetworkConfig()
  const campaign = useCampaign(campaignAddress)

  // use ref is a code smell here, need to think about structuring the component architecture better
  const steps = useRef(
    buildSteps(
      campaign,
      values,
      versionLaunchSteps(
        campaignAddress,
        networkConfig,
        launchStepsUpdateV1,
        launchStepsUpdateV2,
      ),
    ),
  )

  return <LaunchStepsManager steps={steps.current} />
}
