import { Body, margin } from '@tryrolljs/design-system'
import { TokenLogo } from '../tokenLogo'
import { useTokenByAddress } from '../../hooks'

type Props = {
  address: string
  bold?: boolean
}

export const TokenCard = ({ address, bold }: Props) => {
  const token = useTokenByAddress(address)

  return (
    <div className="flex flex-row items-center">
      <TokenLogo style={margin.mr8} token={token} size={24} />
      <Body weight={bold ? 'bold' : 'regular'}>{token.symbol}</Body>
    </div>
  )
}
