import { Body, margin, Tooltip } from '@tryrolljs/design-system'
import { TokenCard } from '../tokenCard'
import { Token } from '../../core'
import { useTokensByAddresses } from '../../hooks'
import { MultiTokenLogo } from '../tokenLogo'

type Props = {
  tokens: Token[]
  bold?: boolean
}

const combineSymbols = (tokens: Token[]) =>
  tokens.map((token) => token.symbol).join(', ')

export const MultiToken = ({ tokens, bold }: Props) => {
  if (tokens.length === 1) {
    return <TokenCard bold={bold} address={tokens[0].address} />
  }

  const symbols = combineSymbols(tokens)

  return (
    <div className="flex flex-row items-center">
      <MultiTokenLogo style={margin.mr8} tokens={tokens} size={24} />
      <Tooltip title={symbols}>
        <span>
          <Body numberOfLines={1} weight={bold ? 'bold' : 'regular'}>
            {symbols.length > 12 ? `${symbols.substring(0, 12)}...` : symbols}
          </Body>
        </span>
      </Tooltip>
    </div>
  )
}

type MultiTokenCardProps = {
  bold?: boolean
  addresses: string[]
}

export const MultiTokenCard = ({ addresses, bold }: MultiTokenCardProps) => {
  const tokens = useTokensByAddresses(addresses)
  return <MultiToken tokens={tokens} bold={bold} />
}
