import {
  Button,
  container,
  Body,
  useTheme,
  margin,
  Tooltip,
  Anchor,
} from '@tryrolljs/design-system'
import { useCallback, useEffect } from 'react'
import { Activity } from '../../atoms/activity'
import { Indicator, IndicatorProps } from '../../atoms/indicator'
import { campaignIsInactive } from '../../core'
import {
  useAmountStaked,
  useCampaign,
  useCheckUserRewards,
  useClaimReward,
  useClearError,
  useExitCampaign,
  useHasClaimableValue,
  useIsCampaignOwner,
  useRefreshRewards,
} from '../../hooks'
import { EarnedToken } from '../../molecules/earnedToken'
import InfoIcon from '../../assets/svg/info.svg'
import { useCampaignDetailsCtx } from '../../context/campaign'

type Props = {
  address: string
  openModal: () => void
}

export const ClaimReward = ({ address, openModal }: Props) => {
  const theme = useTheme()
  const campaign = useCampaign(address)
  const isOwner = useIsCampaignOwner(address)
  const isInactive = campaignIsInactive(campaign)
  const hasClaimableValue = useHasClaimableValue(address)
  const [claimReward, claimRewardAsync] = useClaimReward()
  const [exitCampaign, exitCampaignAsyncState] = useExitCampaign(address)
  useClearError(claimRewardAsync, 3000)
  useClearError(exitCampaignAsyncState, 3000)
  const { setDisableButtons, disableButtons } = useCampaignDetailsCtx()
  useRefreshRewards(address)
  const getRewards = useCheckUserRewards()
  const amountStaked = useAmountStaked(address)

  const handleClaim = useCallback(async () => {
    try {
      setDisableButtons(true)
      await claimReward(address)
    } finally {
      setDisableButtons(false)
    }
  }, [address, claimReward, setDisableButtons])

  const handleExit = useCallback(async () => {
    try {
      setDisableButtons(true)
      await exitCampaign()
    } finally {
      setDisableButtons(false)
    }
  }, [exitCampaign, setDisableButtons])

  useEffect(() => {
    getRewards(address)
  }, [address, getRewards])

  return (
    <div
      className="p-4 rounded-xl"
      style={{ backgroundColor: theme.background.primary }}
    >
      <div className="flex flex-col">
        <div className="flex items-center">
          <Body weight="bold" style={[container.alignSelfStart, margin.mr8]}>
            Reward
          </Body>
          <Tooltip
            title={
              <Body>
                You can claim rewards to <Anchor href="#">your wallet</Anchor>{' '}
                or unstake and withdraw rewards at the same time.
              </Body>
            }
          >
            <div className="p-1 cursor-pointer">
              <InfoIcon />
            </div>
          </Tooltip>
        </div>
      </div>
      <div
        className="flex flex-col items-center m-2 p-8 px-24 rounded-lg justify-center"
        style={{ backgroundColor: theme.background.page, minHeight: 287 }}
      >
        {isInactive && isOwner ? (
          <Button
            style={{ width: 300 }}
            variant="primary"
            title="Complete Staking Contract"
            onPress={openModal}
          />
        ) : (
          <>
            <div className="flex flex-row justify-between">
              {campaign.rewardTokenAddresses.map((addr) => (
                <EarnedToken
                  key={addr}
                  tokenAddress={addr}
                  campaignAddress={address}
                />
              ))}
            </div>
            <div className="flex space-x-1 my-4 items-center">
              <ClaimRewardBtn
                activity={claimRewardAsync.activity}
                handleClaim={handleClaim}
                disable={!hasClaimableValue || disableButtons}
              />
              <ClaimAndUnstakeBtn
                activity={exitCampaignAsyncState.activity}
                handleClaim={handleExit}
                disable={
                  !hasClaimableValue || disableButtons || amountStaked.isZero()
                }
              />
            </div>
            <ErrorIndicator
              ind={
                claimRewardAsync.ind.message
                  ? claimRewardAsync.ind
                  : exitCampaignAsyncState.ind
              }
            />
          </>
        )}
      </div>
    </div>
  )
}

const ClaimRewardBtn = ({
  handleClaim,
  activity,
  disable,
}: {
  handleClaim: () => void
  activity: boolean
  disable: boolean
}) => {
  if (activity) return <Activity style={{ marginRight: 24 }} />

  return (
    <Button
      variant="primary"
      disabled={disable}
      title="Claim Reward"
      onPress={handleClaim}
    />
  )
}

const ClaimAndUnstakeBtn = ({
  handleClaim,
  activity,
  disable,
}: {
  handleClaim: () => void
  activity: boolean
  disable: boolean
}) => {
  if (activity) return <Activity style={{ marginLeft: 24 }} />

  return (
    <Button
      variant="primary"
      disabled={disable}
      title="Unstake & Claim Reward"
      onPress={handleClaim}
    />
  )
}

const ErrorIndicator = ({ ind }: { ind: IndicatorProps }) => {
  if (ind.message) {
    return <Indicator message={ind.message} level={ind.level} />
  }

  return null
}
