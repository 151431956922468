import { BigNumber } from '@ethersproject/bignumber'
import { Body, margin, Value } from '@tryrolljs/design-system'
import { useTokenByAddress } from '../../hooks'
import { displayAmount } from '../../util'
import { TokenCard } from '../tokenCard'

type Props = {
  address: string
  value: BigNumber
  containerStyle?: React.CSSProperties
  testId?: string
}

export const TokenValue = ({
  address,
  value,
  containerStyle,
  testId,
}: Props) => {
  const { decimals } = useTokenByAddress(address)
  const displayValue = displayAmount(value, decimals)
  return (
    <div
      data-testid={testId}
      className="flex flex-row items-center"
      style={containerStyle}
    >
      <Value
        style={margin.mr8}
        decimals={decimals}
        displayValue={displayValue}
      />
      <TokenCard address={address} />
    </div>
  )
}

export const TextTokenValue = ({ address, value }: Props) => {
  const { symbol, decimals } = useTokenByAddress(address)
  const displayValue = displayAmount(value, decimals)

  return (
    <Value
      decimals={decimals}
      displayValue={displayValue}
      renderValue={(value_) => (
        <Body weight="bold">
          {value_} {symbol}
        </Body>
      )}
    />
  )
}
