import { CHAIN_ID_MAIN_NET } from '@tryrolljs/design-system'

export const MAX_SCREEN_CONTENT_WIDTH = 1296
export const discordInviteUrl = 'https://discord.gg/AkPnwu2'
export const stakingTermsUrl = 'https://tryroll.com/protocol-terms-of-service/'
export const FAQ_URL =
  'https://tryroll.gitbook.io/resource-center/staking/staking-faq'
export const supportEmail = 'support@tryroll.com'
export const tryrollEmail = 'product@tryroll.com'
export const auditUrl = 'https://tryroll.com/roll-staking-audit-certik/'
export const blacklistCampaigns: Record<number, string[]> = {
  [CHAIN_ID_MAIN_NET]: ['0x5af47bba51712430a6ce5be25d8178ce2c909429'],
}
export const FALLBACK_GAS_LIMIT = 250000
export const DEFAULT_ERROR_MESSAGE = 'Unknown error'
export const CHAIN_ID_FORM_TESTNET = 132902
