import { BigNumber } from "ethers";

export const SAVE_TOKENS = "SAVE_TOKENS";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const SET_APPROVED_TOKEN = "SET_APPROVED_TOKEN";
// export const SET_TOKENS_ALLOWANCE = "SET_TOKENS_ALLOWANCE";

export type ActionSetApprovedToken = {
  tokenAddress: string;
  approved: BigNumber; // allowance
  spenderAddress: string;
};

// export interface ActionSetTokensAllowance {
//   spenderAddr: string;
//   allowances: { tokenAddr: string; allowance: BigNumber }[];
// }
