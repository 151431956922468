import { useState } from 'react'
import { useTheme, Body, margin, Button } from '@tryrolljs/design-system'
import {
  useCreateCampaignCtx,
  useDeployCampaign,
  useDeployCampaignForm,
} from '../../hooks'
import { Activity } from '../../atoms/activity'
import StyledLink from '../../atoms/styledLink'
import { auditUrl, FAQ_URL } from '../../constants'

type Props = {
  onSuccess: (address: string) => void
}

export const DeployContract = ({ onSuccess }: Props) => {
  const theme = useTheme()
  const deploy = useDeployCampaign()
  const { campaignAddress } = useCreateCampaignCtx()
  const [activity, setActivity] = useState(false)
  const deployForm = useDeployCampaignForm()

  const _deploy = async () => {
    setActivity(true)
    const addr = await deploy(deployForm)
    setActivity(false)
    if (addr) {
      onSuccess(addr)
    }
  }

  return (
    <div className="p-4" style={{ backgroundColor: theme.background.page }}>
      <Body>
        You are deploying a new contract to the Ethereum blockchain. The smart
        contracts have been audited and you can view them{' '}
        <StyledLink title="here" url={auditUrl} />. Warning: contract deployment
        can be gas intensive. <StyledLink title="Learn more" url={FAQ_URL} />.
      </Body>
      {activity ? (
        <Activity style={{ marginTop: 24 }} />
      ) : (
        <Button
          style={[margin.mt24, { width: 100 }]}
          variant="primary"
          disabled={!!campaignAddress}
          title="Deploy"
          onPress={_deploy}
        />
      )}
    </div>
  )
}
