import { Provider as EthersProviderType } from '@ethersproject/providers'
import {
  SocialMoneyV1_5,
  StakingV1,
  StakingV2,
} from '@tryrolljs/contract-bindings'
import { BigNumber } from '@ethersproject/bignumber'
import { ContractTransaction } from '@ethersproject/contracts'
import { Signer, Contract } from 'ethers'
import {
  Campaign,
  campaignDurationSeconds,
  evaluateCampaignStatus,
  evaluateMaxRewardAmount,
  getRemainingSeconds,
  StakingRewardToken,
  Token,
} from '../core'
import { isEthereumNetwork, MILLISECONDS_SECOND } from '../util'
import { FALLBACK_GAS_LIMIT } from '../constants'
import { NetworkConfig } from '../config'
import UniswapV2PairABI from './abi/UniswapV2Pair.json'

export const EVENT_DEPLOYED = 'Deployed'

export type TokenGetter = (address: string) => Promise<Token | null>

export type Provider = EthersProviderType | Signer

const connectContracts = ({
  factoryAddress,
  registryAddress,
  provider,
}: {
  factoryAddress: string
  registryAddress: string
  provider: Provider
}) => {
  return {
    factory: StakingV2.RollStakingFactory__factory.connect(
      factoryAddress,
      provider,
    ),
    registry: StakingV2.StakingRegistry__factory.connect(
      registryAddress,
      provider,
    ),
  }
}

const getCampaignsIdByOwner = async ({
  owner,
  registry,
}: {
  owner: string
  registry: StakingV2.StakingRegistry
}) => {
  const count = await registry.contractCountPerOwner(owner)
  const promises: Promise<string>[] = []

  for (let i = 0; i < count.toNumber(); i++) {
    promises.push(
      new Promise(async (resolve, reject) => {
        try {
          const address = await registry.ownerToContracts(owner, i)
          resolve(address)
        } catch (err) {
          reject(err)
        }
      }),
    )
  }

  return Promise.all(promises)
}

const getOldCampaignsByOwner = async ({
  provider,
  owner,
  networkConfig,
}: {
  owner: string
  provider: Provider
  networkConfig: NetworkConfig
}) => {
  let oldCampaignsId: string[] = []
  if (!networkConfig.OLD_CONTRACTS) return []
  const oldcampaings = networkConfig.OLD_CONTRACTS.map((oldContract) => {
    const { registry } = connectContracts({
      factoryAddress: oldContract.FACTORY_ADDRESS,
      registryAddress: oldContract.REGISTRY_ADDRESS,
      provider,
    })
    return getCampaignsIdByOwner({ registry, owner })
  })
  const oldCampaignsIdsResponse = await Promise.all(oldcampaings)
  return oldCampaignsId.concat(...oldCampaignsIdsResponse)
}

export interface GetCampaingsByOwnerProps {
  owner: string
  tokenGetter: TokenGetter
  provider: Provider
  registry: StakingV2.StakingRegistry
  networkConfig: NetworkConfig
}
export const getCampaignsByOwner = async ({
  owner,
  tokenGetter,
  provider,
  registry,
  networkConfig,
}: GetCampaingsByOwnerProps): Promise<Campaign[]> => {
  const oldCampaigns = await getOldCampaignsByOwner({
    owner,
    networkConfig,
    provider,
  })
  const recentContracts = await getCampaignsIdByOwner({ owner, registry })
  const allCampaigns = recentContracts.concat(oldCampaigns)
  const promises = allCampaigns.map((stakingContract) =>
    getCampaignData(stakingContract, tokenGetter, provider),
  )
  return Promise.all(promises)
}

const getCampaignsId = async ({
  factory,
  registry,
  chainId,
}: {
  factory: StakingV2.RollStakingFactory
  registry: StakingV2.StakingRegistry
  chainId: number
}) => {
  if (isEthereumNetwork(chainId)) {
    const filter = factory.filters.Deployed()
    const events = await factory.queryFilter(filter)
    return events.map((event) => event.args.stakingContract)
  } else {
    const [, contracts] = await registry.getContracts()
    return contracts
  }
}

const getOldCampaignsId = async ({
  chainId,
  provider,
  networkConfig,
}: {
  chainId: number
  provider: Provider
  networkConfig: NetworkConfig
}): Promise<string[]> => {
  let oldCampaignsId: string[] = []
  if (!networkConfig.OLD_CONTRACTS) return []
  const oldcampaings = networkConfig.OLD_CONTRACTS.map((oldContract) => {
    const { factory, registry } = connectContracts({
      factoryAddress: oldContract.FACTORY_ADDRESS,
      registryAddress: oldContract.REGISTRY_ADDRESS,
      provider,
    })
    return getCampaignsId({ factory, registry, chainId })
  })
  const oldCampaignsIdsResponse = await Promise.all(oldcampaings)

  return oldCampaignsId.concat(...oldCampaignsIdsResponse)
}

export interface GetCampaignsProps {
  tokenGetter: TokenGetter
  provider: Provider
  factory: StakingV2.RollStakingFactory
  registry: StakingV2.StakingRegistry
  chainId: number
  networkConfig: NetworkConfig
}
export const getCampaigns = async ({
  tokenGetter,
  provider,
  factory,
  registry,
  chainId,
  networkConfig,
}: GetCampaignsProps) => {
  const oldCampaigns = await getOldCampaignsId({
    chainId,
    provider,
    networkConfig,
  })
  const recentContracts = await getCampaignsId({ factory, registry, chainId })
  const allCampaigns = recentContracts.concat(oldCampaigns)

  const promises = allCampaigns.map((stakingContract) =>
    getCampaignData(stakingContract, tokenGetter, provider),
  )
  return Promise.all(promises)
}

export const getCampaignData = async (
  address: string,
  tokenGetter: TokenGetter,
  provider: Provider,
): Promise<Campaign> => {
  const campaign = StakingV2.RollStakingRewards__factory.connect(
    address,
    provider,
  )
  return buildCampaignData(campaign, tokenGetter)
}

// recursively find all tokens used as rewards
// keeps looking for an available index in contract array
// returns found list when encounters an error
const findAllTokens = async (
  contract: StakingV2.RollStakingRewards,
  onFind: (addr: string) => Promise<void>,
) => {
  const finder = async (
    idx: number = 0,
    list: string[] = [],
  ): Promise<string[]> => {
    try {
      const foundTokenAddress = await contract.rewardTokensAddresses(idx)
      list.push(foundTokenAddress)
      await onFind(foundTokenAddress)
      return finder((idx += 1), list)
    } catch (err) {
      return list
    }
  }
  return finder(0, [])
}

export const buildCampaignData = async (
  campaign: StakingV2.RollStakingRewards,
  tokenGetter: TokenGetter,
): Promise<Campaign> => {
  campaign.rewardsDuration()
  const duration = (await campaign.rewardsDuration()).mul(MILLISECONDS_SECOND)
  const start = (await campaign.periodStart()).mul(MILLISECONDS_SECOND)
  const end = (await campaign.periodFinish()).mul(MILLISECONDS_SECOND)

  const total = await campaign.totalSupply()
  const tokenAddress = await campaign.token()
  await tokenGetter(tokenAddress)
  const owner = await campaign.owner()
  const rewards: StakingRewardToken[] = []

  const _tokens = await findAllTokens(campaign, async (tokenAddr) => {
    await tokenGetter(tokenAddr)
    const _reward = await campaign.getRewardForDuration(tokenAddr)
    const free = await campaign.freeTokens(tokenAddr)
    const rewardToken = await campaign.rewardTokens(tokenAddr)

    rewards.push({
      address: tokenAddr,
      value: _reward,
      rate: rewardToken.rewardRate,
      free,
    })
  })

  const c: Campaign = {
    address: campaign.address,
    rewards: rewards,
    start,
    end,
    duration,
    total,
    tokenAddress,
    rewardTokenAddresses: _tokens,
    status: 'Inactive',
    owner,
  }

  c.status = evaluateCampaignStatus(c)

  return c
}

export const getMaxRewardTokens = async (
  campaign: Campaign,
  currentRewards: { token: Token; reward: BigNumber }[],
  currentStakedAmount: BigNumber,
  stakeAmount: BigNumber,
  provider: Provider,
) => {
  const { address } = campaign
  const contract = StakingV2.RollStakingRewards__factory.connect(
    address,
    provider,
  )
  const remainingDuration = getRemainingSeconds(campaign)
  const total = await contract.totalSupply()
  const originalDuration = campaignDurationSeconds(campaign)

  return Promise.all(
    currentRewards.map(
      ({ token, reward }) =>
        new Promise<Reward>(async (resolve) => {
          const { rewardRate } = await contract.rewardTokens(token.address)
          // const currentReward = await contract.getR
          const amount = evaluateMaxRewardAmount(
            rewardRate,
            originalDuration,
            remainingDuration,
            currentStakedAmount,
            stakeAmount,
            total,
            token.decimals,
            reward,
          )

          resolve({ amount, token: token.address })
        }),
    ),
  )
}

export const getUniswapPairByAddress = async (
  address: string,
  provider: Provider,
): Promise<[string | undefined, string | undefined]> => {
  try {
    const contract = new Contract(address, UniswapV2PairABI, provider)

    const [token0, token1] = await Promise.all([
      contract.token0(),
      contract.token1(),
    ])

    return [token0, token1]
  } catch (e) {
    return [undefined, undefined]
  }
}

export const getTokenByAddress = async (
  address: string,
  provider: Provider,
): Promise<Token> => {
  const token = SocialMoneyV1_5.ERC20__factory.connect(address, provider)

  const symbol = await token.symbol()
  const name = await token.name()
  const decimals = await token.decimals()

  const [token0, token1] = await getUniswapPairByAddress(address, provider)

  return {
    symbol,
    name,
    decimals,
    address: address.toLowerCase(),
    logoURI: '',
    token0,
    token1,
  }
}

export type DeployRewardsForm = {
  sent: boolean
  reward: StakingRewardToken
}

export const deployRewardsFormToMap = (f: DeployRewardsForm[]) =>
  f.reduce(
    (acc: { [key: string]: DeployRewardsForm }, curr: DeployRewardsForm) => {
      acc[curr.reward.address] = curr
      return acc
    },
    {},
  )

export type DeployCampaignForm = {
  start: Date
  end: Date
  rewards: StakingRewardToken[]
  stakeTokenAddress: string
}

export const depositReward = async (
  tokenAddr: string,
  value: BigNumber,
  contractAddr: string,
  provider: Provider,
): Promise<ContractTransaction> => {
  const token = SocialMoneyV1_5.ERC20__factory.connect(tokenAddr, provider)
  const transfer = await token.transfer(contractAddr, value)
  return transfer
}

const buildNotifyForm = (rewards: StakingRewardToken[]) =>
  rewards.reduce(
    (
      acc: { tokens: string[]; values: BigNumber[] },
      curr: StakingRewardToken,
    ) => {
      acc.tokens.push(curr.address)
      acc.values.push(curr.value)
      return acc
    },
    {
      tokens: [],
      values: [],
    },
  )

export const deployStakingContract = async (
  rewards: string[],
  stake: string,
  provider: Provider,
  factory: StakingV2.RollStakingFactory,
): Promise<StakingV2.RollStakingRewards> => {
  const contractTx = await factory.createStakingContract(rewards, stake)
  const contractReceipt = await contractTx.wait()
  const event = contractReceipt.events?.find((e) => e.event === EVENT_DEPLOYED)
  return StakingV2.RollStakingRewards__factory.connect(
    event?.args?.stakingContract,
    provider,
  )
}

export const getAmountStaked = async (
  userAddr: string,
  contractAddr: string,
  provider: Provider,
): Promise<BigNumber> => {
  const contract = StakingV2.RollStakingRewards__factory.connect(
    contractAddr,
    provider,
  )
  return await contract.balanceOf(userAddr)
}

export const tokenBalance = (
  userAddr: string,
  tokenAddr: string,
  provider: Provider,
): Promise<BigNumber> => {
  const token = SocialMoneyV1_5.ERC20__factory.connect(tokenAddr, provider)
  return token.balanceOf(userAddr)
}

export type Reward = {
  token: string
  amount: BigNumber
}

export const getUserRewards = async (
  userAddr: string,
  campaign: Campaign,
  provider: Provider,
): Promise<Reward[]> => {
  const contract = StakingV2.RollStakingRewards__factory.connect(
    campaign.address,
    provider,
  )

  return Promise.all(
    campaign.rewardTokenAddresses.map((token) => {
      return new Promise<Reward>(async (resolve, reject) => {
        try {
          const earned = await contract.earned(userAddr, token)
          resolve({ amount: earned, token })
        } catch (err) {
          reject(err)
        }
      })
    }),
  )
}

export const stake = async (
  amount: BigNumber,
  campaignAddr: string,
  provider: Provider,
): Promise<ContractTransaction> => {
  const contract = StakingV2.RollStakingRewards__factory.connect(
    campaignAddr,
    provider,
  )
  return contract.stake(amount)
}

export const unStake = async (
  amount: BigNumber,
  campaignAddr: string,
  provider: Provider,
): Promise<ContractTransaction> => {
  const contract = StakingV2.RollStakingRewards__factory.connect(
    campaignAddr,
    provider,
  )
  return contract.withdraw(amount)
}

export const totalRewards = async (campaign: Campaign, provider: Provider) => {
  const contract = StakingV2.RollStakingRewards__factory.connect(
    campaign.address,
    provider,
  )
  return Promise.all(
    campaign.rewardTokenAddresses.map(
      (addr) =>
        new Promise<Reward>(async (resolve) => {
          const amount = await contract.rewardPerToken(addr)
          resolve({ amount, token: addr })
        }),
    ),
  )
}

export const claimReward = async (
  campaignAddr: string,
  signer: Signer,
): Promise<ContractTransaction> => {
  const contract = StakingV2.RollStakingRewards__factory.connect(
    campaignAddr,
    signer,
  )
  return contract.getReward()
}

export const exitCampaign = async (
  campaignAddr: string,
  signer: Signer,
): Promise<ContractTransaction> => {
  const contract = StakingV2.RollStakingRewards__factory.connect(
    campaignAddr,
    signer,
  )
  return contract.exit()
}

export const getTokenAllowance = async (
  owner: string,
  spender: string,
  token: string,
  provider: Provider,
): Promise<BigNumber> => {
  const t = SocialMoneyV1_5.ERC20__factory.connect(token, provider)
  return t.allowance(owner, spender)
}

export const approveToken = async (
  spender: string,
  token: string,
  provider: Provider,
  value: BigNumber,
) => {
  const t = SocialMoneyV1_5.ERC20__factory.connect(token, provider)
  return t.approve(spender, value)
}

export const claimFreeTokens = async (campaignAddr: string, signer: Signer) => {
  const contract = StakingV2.RollStakingRewards__factory.connect(
    campaignAddr,
    signer,
  )
  return contract.claimFreeTokens()
}

export const checkFreeTokens = async (
  campaignAddr: string,
  tokenAddr: string,
  provider: Provider,
) => {
  const contract = StakingV2.RollStakingRewards__factory.connect(
    campaignAddr,
    provider,
  )
  return contract.getFreeTokenAmount(tokenAddr)
}

export interface InitCampaignOpts {
  start: Date
  end: Date
  rewards: BigNumber[]
  tokens: string[]
}

const toPeriodStart = (start: Date) =>
  Math.floor(start.getTime() / MILLISECONDS_SECOND)

const toDuration = (start: Date, end: Date) =>
  Math.floor((end.getTime() - start.getTime()) / MILLISECONDS_SECOND)

export const initCampaign = async (
  campaignAddr: string,
  signer: Signer,
  opts: InitCampaignOpts,
): Promise<ContractTransaction> => {
  const contract = StakingV2.RollStakingRewards__factory.connect(
    campaignAddr,
    signer,
  )
  let gasLimit = BigNumber.from(FALLBACK_GAS_LIMIT).mul(opts.tokens.length)
  const start = toPeriodStart(opts.start)
  const duration = toDuration(opts.start, opts.end)
  const { rewards, tokens } = opts
  try {
    gasLimit = await contract.estimateGas.initCampaign(
      start,
      duration,
      rewards,
      tokens,
    )
  } catch (error) {}
  return contract.initCampaign(start, duration, rewards, tokens, {
    gasLimit,
  })
}

export const setDuration = async (
  contractAddr: string,
  start: Date,
  end: Date,
  provider: Signer,
): Promise<ContractTransaction> => {
  const contract = StakingV1.RollStakingRewardsV2__factory.connect(
    contractAddr,
    provider,
  )
  return contract.setRewardsDuration(
    toPeriodStart(start),
    toDuration(start, end),
  )
}

export const notifyRewards = async (
  contractAddr: string,
  rewards: StakingRewardToken[],
  signer: Signer,
): Promise<ContractTransaction> => {
  const form = buildNotifyForm(rewards)
  const stakingContract = StakingV1.RollStakingRewardsV2__factory.connect(
    contractAddr,
    signer,
  )
  return stakingContract.notifyRewardAmount(form.values, form.tokens)
}
