import { useEthAddress, useModal } from "../../hooks";
import { OwnerCampaignGrid } from "../../organisms/campaignGrid";
import { CreateCampaignManager } from "../../organisms/campaignWizard";
import { CreateStakingRewardBanner } from "../../molecules/createStakingRewardBanner";
import { SyntheticEvent, useCallback, useEffect, useRef } from "react";
import { decodeWizardUrl, WizardEncodeParams } from "../../organisms/campaignWizard/common";
import { PageTemplate } from "../../templates/page";

type Props = {
  navCampaignDetails: (address: string) => void;
};

export const PageOwnerCampains = ({ navCampaignDetails }: Props) => {
  const modal = useModal();
  const address = useEthAddress();
  const checkedUrl = useRef(false);

  const openCreateWizard = useCallback(
    (e?: SyntheticEvent, wizardState?: WizardEncodeParams) => {
      console.log("wizard state: ", wizardState);
      modal.setRenderer(() => <CreateCampaignManager wizardState={wizardState} />, {
        style: {
          padding: 0,
          maxHeight: 700
        }
      });
      modal.setOpen(true);
    },
    [modal]
  );

  useEffect(() => {
    if (checkedUrl.current) return;
    checkedUrl.current = true;
    const wizardState = decodeWizardUrl(window.location.href);
    if (wizardState.wizardType === "create") {
      openCreateWizard(undefined, wizardState);
    }
  }, [modal, openCreateWizard]);

  return (
    <PageTemplate>
      <CreateStakingRewardBanner onPress={openCreateWizard} />
      <OwnerCampaignGrid address={address as string} onSelect={navCampaignDetails} />
    </PageTemplate>
  );
};
