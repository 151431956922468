import { useState } from "react";
import { IndicatorProps } from "../../atoms/indicator";
import { useCreateCampaignCtx } from "../../hooks";
import { InputToken } from "../tokenInput";

export const validateStakeToken = (address: string): IndicatorProps | null => {
  if (address === "") {
    return { message: "You must specify a token to stake", level: "error" };
  }
  return null;
};

export const FormCampaignEligibility = () => {
  const { values, setValues } = useCreateCampaignCtx();
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <div>
      <InputToken
        value={searchTerm}
        onChange={setSearchTerm}
        onSelect={(token) => setValues({ ...values, stakeTokenAddress: token.address })}
      />
    </div>
  );
};
