import { Body, margin } from '@tryrolljs/design-system'
import { TokenCard } from '../tokenCard'
import { useTokenByAddress } from '../../hooks'
import { SearchToken } from '../../state/tokens/selectors'

type onSelect_ = (token: SearchToken) => void

type TokenInputOptionProps = {
  searchToken: SearchToken
  onSelect: onSelect_
}

export const TokenInputOption = ({
  searchToken,
  onSelect,
}: TokenInputOptionProps) => {
  const token = useTokenByAddress(searchToken.address)

  return (
    <div
      onClick={() => onSelect(token)}
      className="border-b-2 flex flex-row p-4 items-center"
    >
      <div className="flex flex-row items-center">
        <TokenCard address={searchToken.address} />
        <Body style={margin.ml8}>{token.name}</Body>
      </div>
    </div>
  )
}
