import {
  CampaignWizardForm,
  CampaignWizardType,
  CreateCampaignCtx,
  initCreateCampaignForm
} from "../../context/campaign";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useEthAddress,
  useGetTokenBalance,
  useLibrary,
  useMaybeGetToken,
  useModal,
  useSuggestValue,
  useTokenCtx
} from "../../hooks";
import { IndicatorProps } from "../../atoms/indicator";
import { CampaignWizardWrapper } from "../../molecules/campaignWizardWrapper";
import { useBalanceCtx } from "../../hooks/balance";
import {
  WizardEncodeParams,
  WizardStep,
  WIZARD_STEP_ADJUST_VALUE,
  WIZARD_STEP_REWARDS
} from "./common";

export const useCampaignWizardManager = (
  wizardSteps: WizardStep[],
  address: string | null,
  wizardType: CampaignWizardType,
  wizardState?: WizardEncodeParams
) => {
  const [initializing, setInitializing] = useState(true);
  const [values, setValues] = useState<CampaignWizardForm>(initCreateCampaignForm());
  const [step, _setStep] = useState(wizardState ? wizardState.step : 0);
  const { state } = useTokenCtx();
  const balanceState = useBalanceCtx().state;
  const getBal = useGetTokenBalance();
  const modal = useModal();
  const [campaignAddress, setCampaignAddress] = useState(address || "");
  const userAddr = useEthAddress();
  const [getToken, saveTokens] = useMaybeGetToken();
  const lib = useLibrary();
  const [errors, setErrors] = useState<{ [key: number]: IndicatorProps | null }>({});
  const [sentRewards, setSentRewards] = useState<{ [key: string]: boolean }>({});
  const _suggestValue = useSuggestValue();

  const setStep = useCallback(
    (n: number) => wizardSteps[n] && _setStep(n),
    [wizardSteps, _setStep]
  );

  const currentError = useMemo<IndicatorProps>(() => {
    return errors[step] || { message: "", level: "warning" };
  }, [errors, step]);

  const setStepError = useCallback(
    (step: number, err: IndicatorProps | null) => {
      setErrors({ ...errors, [step]: err });
    },
    [errors, setErrors]
  );

  const handleValidation = useCallback(
    (current: WizardStep) => {
      // set error for this step and halt if necessary
      // user cannot proceed to next step until user input is correct
      const err = current.validate(values, state, balanceState, userAddr || "", campaignAddress);
      if (err) {
        setStepError(step, err);
        return false;
      }

      // clear error
      setStepError(step, null);
      return true;
    },
    [values, state, balanceState, userAddr, setStepError, campaignAddress, step]
  );

  const maybeHandleAdjustment = useCallback(
    (current: WizardStep) => {
      if (
        current.name === WIZARD_STEP_REWARDS &&
        wizardSteps[step + 1]?.name === WIZARD_STEP_ADJUST_VALUE
      ) {
        const [suggestedValues, hasChanged] = _suggestValue(values);
        if (hasChanged) {
          setValues({ ...values, suggestValues: suggestedValues });
          return false;
        }
        setStep(step + 2);
        return true;
      }
    },
    [step, setStep, setValues, values, wizardSteps, _suggestValue]
  );

  const nextStep = useCallback(() => {
    const current = wizardSteps[step];
    if (!current) return;

    const valid = handleValidation(current);
    if (!valid) {
      return;
    }

    const adjusted = maybeHandleAdjustment(current);
    if (adjusted) {
      return;
    }

    setStep(step + 1);
  }, [step, setStep, wizardSteps, handleValidation, maybeHandleAdjustment]);

  const previousStep = useCallback(() => {
    setStepError(step, null);
    if (wizardSteps[step - 1]?.name === WIZARD_STEP_ADJUST_VALUE) {
      setStep(step - 2);
    } else {
      setStep(step - 1);
    }
  }, [step, setStep, setStepError, wizardSteps]);

  // retrieve token data for injected form params
  const getHydrateData = useCallback(
    async (_wizardState: WizardEncodeParams) => {
      _wizardState.rewards.forEach(async (r) => {
        await getToken(r.address);
        if (userAddr) {
          await getBal(r.address, userAddr);
        }
      });
      await getToken(_wizardState.stakeTokenAddress);
      saveTokens();
      setValues(initCreateCampaignForm(_wizardState));
      setInitializing(false);
    },
    [getToken, saveTokens, setInitializing, setValues, getBal, userAddr]
  );

  // conditionally get injected form data
  useEffect(() => {
    if (!wizardState && initializing) {
      setInitializing(false);
    }

    if (wizardState && initializing && lib) {
      getHydrateData(wizardState);
    }
  }, [wizardState, setInitializing, initializing, getHydrateData, lib]);

  const renderStep = useCallback(() => {
    const current = wizardSteps[step];
    if (!current) return null;
    if (!current.withWrapper) return current.component;
    return (
      <CampaignWizardWrapper
        idx={step}
        disableBack={current.disableBack}
        withButtons={current.withButtons}
        ind={currentError}
        title={current.title}
        description={current.description}
        onNext={nextStep}
        onBack={previousStep}
        onClose={() => modal.setOpen(false)}
        initializing={initializing}
        customBottom={current.customBottom}
      >
        {current.component}
      </CampaignWizardWrapper>
    );
  }, [step, nextStep, previousStep, modal, currentError, wizardSteps, initializing]);

  const render = () => (
    <CreateCampaignCtx.Provider
      value={{
        values,
        setValues,
        nextStep,
        previousStep,
        campaignAddress,
        setCampaignAddress,
        wizardType,
        step,
        ind: currentError,
        sentRewards,
        setSentRewards
      }}
    >
      {renderStep()}
    </CreateCampaignCtx.Provider>
  );

  return {
    render,
    setStep,
    setValues,
    setCampaignAddress
  };
};
