import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import { PageOwnerCampains } from "../pages/ownerCampaigns";
import { PageCampaignDetails } from "../pages/campaignDetails";
import { PageAllCampaigns } from "../pages/campaigns";
import { useNavigate } from "react-router-dom";
import { navigateCampaignDetails } from "./actions";
import { useEffect } from "react";
import { routeCampaignDetails, routeHome, routeMyCampaigns } from "./routes";
export const RootNavigator = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <RoutesDef />
    </BrowserRouter>
  );
};

const RoutesDef = () => {
  const navigate = useNavigate();
  return (
    <Routes>
      <Route
        path={routeHome()}
        element={
          <PageAllCampaigns
            navCampaignDetails={(addr) => navigateCampaignDetails(navigate, addr)}
          />
        }
      />
      <Route
        path={routeMyCampaigns()}
        element={
          <PageOwnerCampains
            navCampaignDetails={(addr) => navigateCampaignDetails(navigate, addr)}
          />
        }
      />
      <Route path={routeCampaignDetails(":address")} element={<PageCampaignDetails />} />
    </Routes>
  );
};

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
