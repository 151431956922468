import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

type Props = {
  children?: JSX.Element;
  overRide?: Function;
};
const BackTab = ({ children, overRide }: Props) => {
  const navigate = useNavigate();
  const handleBack = () => {
    if (overRide) {
      overRide();
    } else navigate(-1);
  };

  return (
    <div className="w-full pl-2 mb-4">
      <div className="cursor-pointer" onClick={handleBack}>
        <ArrowBackIosNewIcon />
      </div>
      {children}
    </div>
  );
};

export default BackTab;
