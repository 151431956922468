import { Header, Button, LargeTitle, margin } from '@tryrolljs/design-system'
import CreateRewardIcon from '../../assets/onboarding/stake-info.svg'

interface Props {
  onPress: () => void
}

export default function StakeInfo({ onPress }: Props) {
  return (
    <div className="px-24">
      <div className="flex flex-row mb-14">
        <div className="flex flex-col justify-center">
          <LargeTitle weight="bold" style={margin.mb16}>
            Roll Staking
          </LargeTitle>
          <Header weight="bold" style={margin.mt48}>
            Stake, create staking rewards and grow liquidity
          </Header>
          <Header style={[margin.mt24, { width: '87%' }]}>
            Roll staking is a simple and secure way to stake and create staking
            rewards for social tokens or any ERC20 token.
          </Header>
          <Header style={[margin.mt24, { width: '87%' }]}>
            You can start liquidity mining, yield farming or distributing your
            tokens with Roll Staking right now.
          </Header>
          <Button
            variant="primary"
            title="Create Staking Rewards"
            onPress={onPress}
            style={[margin.mt40, { width: 232 }]}
          />
        </div>
        <div className="w-39">
          <CreateRewardIcon />
        </div>
      </div>
    </div>
  )
}
