import { BigNumber } from "ethers";
import { BalanceState } from ".";

export const selectTokenBalance = (
  state: BalanceState,
  holderAddr: string,
  tokenAddr: string
): BigNumber => {
  if (state[holderAddr]) {
    return state[holderAddr][tokenAddr] || BigNumber.from(0);
  }
  return BigNumber.from(0);
};
