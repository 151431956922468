export type Token = {
  address: string;
  symbol: string;
  name: string;
  decimals: number;
  logoURI: string;
  color?: Array<string>;
  token0?: string;
  token1?: string;
};

export enum SupportedLPToken {
  Uniswap = "UNI-V2"
}

const SUPPORTED_LP_TOKENS = new Set<string>([SupportedLPToken.Uniswap]);

export const setTokenDisplayValuesIfPair = (
  token: Token,
  token0?: Token,
  token1?: Token
): Token => {
  if (token0 && token1) {
    const { symbol: symbol1 } = token0;
    const { symbol: symbol2 } = token1;

    const isLPTokenSupported = SUPPORTED_LP_TOKENS.has(token.symbol);

    const symbol = isLPTokenSupported
      ? `${symbol1}-${symbol2} ${token.symbol} LP`
      : `${symbol1}-${symbol2} LP`;

    return { ...token, symbol };
  }

  return token;
};
