import { useCallback } from 'react'
import {
  ConnectWeb3Button,
  ConnectWeb3Options,
  useWeb3Conntectors,
} from '@tryrolljs/design-system'
import { useModal } from '../../hooks'

export const ConnectWallet = () => {
  const { isActivating } = useWeb3Conntectors()
  const modal = useModal()

  const onConnectWeb3 = useCallback(() => {
    modal.setRenderer(
      () => (
        <ConnectWeb3Options
          onClose={() => modal.setOpen(false)}
          onSelect={() => modal.setOpen(false)}
        />
      ),
      { style: { padding: 0 } },
    )
    modal.setOpen(true)
  }, [modal])

  return <ConnectWeb3Button activity={isActivating} onPress={onConnectWeb3} />
}
