export type ActionCallbacks = {
  onSuccess?: () => void;
  onFail?: (err?: Error) => void;
};

export interface Action<T = {}> {
  type: string;
  payload: T;
}

export const CLEAR_STATE = "CLEAR_STATE";

export const actionClearState = (): Action<null> => ({
  type: CLEAR_STATE,
  payload: null
});
