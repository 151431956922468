import { useTheme, Button, margin, Body, Input } from '@tryrolljs/design-system'
import { BigNumber } from 'ethers'
import CloseIcon from '../../assets/svg/close.svg'
import { displayAmount } from '../../util'
import { Indicator, IndicatorProps } from '../../atoms/indicator'
import { Activity } from '../../atoms/activity'
import { Token } from '../../core'

type Props = {
  amountAvailable: BigNumber
  onSetMax: () => void
  token: Token
  onClose: () => void
  value: string
  setValue: (val: string) => void
  ind: IndicatorProps
  onConfirm: () => void
  activity: boolean
  buttonTitle: string
  title: string
}

export const FormUserContractInteract = ({
  amountAvailable,
  onSetMax,
  token,
  onClose,
  value,
  setValue,
  ind,
  onConfirm,
  activity,
  buttonTitle,
  title,
}: Props) => {
  const theme = useTheme()

  return (
    <div className="p-2" style={{ width: 480 }}>
      <div className="flex items-center justify-between">
        <Body weight="bold">{title}</Body>
        <div className="cursor-pointer" onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
      <div className="mb-4">
        <Input
          placeholder="Amount"
          style={margin.mt24}
          value={value}
          onChangeText={(val) => {
            if (!isNaN(Number(val))) {
              setValue(val)
            }
          }}
        />
        <Body>
          Amount available: {displayAmount(amountAvailable, token.decimals)}{' '}
          {token.symbol}{' '}
          <Body onPress={onSetMax} color={theme.text.highlight}>
            (Max)
          </Body>
        </Body>
      </div>
      {ind.message && <Indicator message={ind.message} level={ind.level} />}
      <div className="flex flex-row space-x-5 mt-4 justify-end">
        {activity ? (
          <Activity />
        ) : (
          <>
            <Button
              variant="secondary"
              style={[margin.mv16, { width: 50 }]}
              title="Cancel"
              onPress={onClose}
            />
            <Button
              variant="primary"
              style={[margin.mv16, { width: 50 }]}
              title={buttonTitle}
              onPress={onConfirm}
            />
          </>
        )}
      </div>
    </div>
  )
}
