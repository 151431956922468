import { useMemo } from 'react'
import { Button, margin, CopyLink } from '@tryrolljs/design-system'
import { Indicator } from '../../atoms/indicator'
import { useCreateCampaignCtx } from '../../hooks'
import { encodeWizardUrl } from '../campaignWizard/common'

export const CampaignSummaryBottom = () => {
  const { ind, values, step, nextStep } = useCreateCampaignCtx()

  const url = useMemo(
    () =>
      encodeWizardUrl({
        wizardType: 'create',
        step: step,
        start: values.start.getTime(),
        end: values.end.getTime(),
        rewards: values.rewards.map((r) => ({
          symbol: r.symbol,
          value: r.value,
          address: r.address,
        })),
        stakeTokenAddress: values.stakeTokenAddress,
      }),
    [values, step],
  )

  const writeUrl = async () => {
    try {
      await navigator.clipboard.writeText(url)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className="w-full flex flex-col items-end">
      <div className="flex flex-col items-end">
        <div className="flex flex-row mb-4">
          <Button
            title="Next"
            variant="primary"
            onPress={nextStep}
            style={margin.mr8}
          />
          <Button
            title="Share Contract for Review"
            variant="secondary"
            onPress={writeUrl}
          />
        </div>
        <div className="flex flex-col items-start w-full">
          <CopyLink url={url} />
          {ind.message && <Indicator message={ind.message} level={ind.level} />}
        </div>
      </div>
    </div>
  )
}
