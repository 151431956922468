import { Indicator, useIndicator } from "../../atoms/indicator";
import { useCreateCampaignCtx } from "../../hooks";
import { DateTimeInput } from "../dateTimeInput";

export const FormCampaignDetails = () => {
  const { values, setValues } = useCreateCampaignCtx();
  const [indicator] = useIndicator();

  return (
    <div>
      <div>
        <DateTimeInput value={values.start} setValue={(start) => setValues({ ...values, start })} />
        <DateTimeInput value={values.end} setValue={(end) => setValues({ ...values, end })} />
      </div>
      {indicator.message && <Indicator message={indicator.message} level={indicator.level} />}
    </div>
  );
};
