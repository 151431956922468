import { useCreateCampaignCtx, useDeployCampaignForm } from "../../hooks";
import { DeployContract } from "../deployContract";
import { ApproveRewards } from "../depositRewards";
import { InitCampaign, NotifyRewardAmount } from "../initCampaign";
import { SetCampaignDuration } from "../setCampaignDuration";
import { useLaunchManagerCtx } from "./common";
import { ControlNullState } from "./presenter";
import { LaunchStepWrapper } from "./stepWrapper";

export type LaunchStepComponent = React.FC<{ idx: number }>;

export const launchStepDeployTitle = "Deploy the Contract";

export const LaunchStepDeploy: LaunchStepComponent = ({ idx }) => {
  const { campaignAddress } = useCreateCampaignCtx();
  const { onDeploySuccess } = useLaunchManagerCtx();
  return (
    <LaunchStepWrapper
      idx={idx}
      title={launchStepDeployTitle}
      successMessage={`Contract has already been deployed at ${campaignAddress}`}
    >
      <DeployContract onSuccess={(addr) => onDeploySuccess(addr, idx)} />
    </LaunchStepWrapper>
  );
};

export const launchStepDurationTitle = "Set the Start and End Time";

export const LaunchStepDuration: LaunchStepComponent = ({ idx }) => {
  const { campaignAddress } = useCreateCampaignCtx();
  const { onStepSuccess } = useLaunchManagerCtx();
  return (
    <LaunchStepWrapper idx={idx} title={launchStepDurationTitle}>
      <ControlNullState idx={idx}>
        <SetCampaignDuration
          onSuccess={() => onStepSuccess(idx)}
          contractAddress={campaignAddress}
        />
      </ControlNullState>
    </LaunchStepWrapper>
  );
};
export const LaunchStepApproveRewards: LaunchStepComponent = ({ idx }) => {
  const { campaignAddress } = useCreateCampaignCtx();
  const { onStepSuccess } = useLaunchManagerCtx();
  const { rewards } = useDeployCampaignForm();
  return (
    <LaunchStepWrapper idx={idx} title="Approve Reward Token Amount">
      <ControlNullState idx={idx}>
        <ApproveRewards
          rewards={rewards}
          onSuccess={() => onStepSuccess(idx)}
          campaignAddr={campaignAddress}
        />
      </ControlNullState>
    </LaunchStepWrapper>
  );
};

export const buildLaunchTitle = (nRewards: number) =>
  `Send Reward ${nRewards > 1 ? "Tokens" : "Token"} and Launch`;

export const LaunchStepInitCampaign: LaunchStepComponent = ({ idx }) => {
  const { onStepSuccess } = useLaunchManagerCtx();
  const { campaignAddress, values } = useCreateCampaignCtx();

  return (
    <LaunchStepWrapper idx={idx} title={buildLaunchTitle(values.rewards.length)}>
      <ControlNullState idx={idx}>
        <InitCampaign contractAddress={campaignAddress} onSuccess={() => onStepSuccess(idx)} />
      </ControlNullState>
    </LaunchStepWrapper>
  );
};

export const LaunchStepSetRate: LaunchStepComponent = ({ idx }) => {
  const { onStepSuccess } = useLaunchManagerCtx();
  const { campaignAddress, values } = useCreateCampaignCtx();

  return (
    <LaunchStepWrapper idx={idx} title={buildLaunchTitle(values.rewards.length)}>
      <ControlNullState idx={idx}>
        <NotifyRewardAmount
          contractAddress={campaignAddress}
          onSuccess={() => onStepSuccess(idx)}
        />
      </ControlNullState>
    </LaunchStepWrapper>
  );
};

export type LaunchStepName = "deploy" | "approveRewards" | "initCampaign" | "duration" | "setRate";

export type LaunchStep = {
  Component: LaunchStepComponent;
  name: LaunchStepName;
};

const StepDeploy: LaunchStep = {
  name: "deploy",
  Component: LaunchStepDeploy
};

const StepSetDuration: LaunchStep = {
  name: "duration",
  Component: LaunchStepDuration
};

const StepApproveRewards: LaunchStep = {
  name: "approveRewards",
  Component: LaunchStepApproveRewards
};

const StepSetRate: LaunchStep = {
  name: "setRate",
  Component: LaunchStepSetRate
};

const StepInitCampaign: LaunchStep = {
  name: "initCampaign",
  Component: LaunchStepInitCampaign
};

export const launchStepsCreateV2: LaunchStep[] = [StepDeploy, StepApproveRewards, StepInitCampaign];

export const launchStepsCreateV1: LaunchStep[] = [
  StepDeploy,
  StepSetDuration,
  StepApproveRewards,
  StepSetRate
];

export const launchStepsUpdateV2: LaunchStep[] = [StepApproveRewards, StepInitCampaign];

export const launchStepsUpdateV1: LaunchStep[] = [StepSetDuration, StepApproveRewards, StepSetRate];
