import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTheme, Body, Button, SubHeader } from '@tryrolljs/design-system'
import { TokenCard } from '../../molecules/tokenCard'
import {
  useEthAddress,
  useGetTokenBalance,
  useTokenByAddress,
  useApproveToken,
  useCheckIsTokenApproved,
  useTokenCtx,
} from '../../hooks'
import { displayAmount } from '../../util'
import Checkmark from '../../assets/svg/check.svg'
import { Activity } from '../../atoms/activity'
import { useTokenBalance } from '../../hooks/balance'
import { StakingRewardToken } from '../../core'
import { selectTokenAllowance } from '../../state/tokens/selectors'

type Props = {
  campaignAddr: string
  onSuccess: () => void
  rewards: StakingRewardToken[]
}

interface ApprovedMap {
  [key: string]: boolean
}
interface ApprovedRewards {
  approvedAll: boolean
  cache: ApprovedMap
}

const useApprovedRewards = (
  contractAddr: string,
  rewards: StakingRewardToken[],
) => {
  const { state } = useTokenCtx()
  return useMemo<ApprovedRewards>(() => {
    return rewards.reduce(
      (acc: ApprovedRewards, { address, value }: StakingRewardToken) => {
        const allowance = selectTokenAllowance(state, address, contractAddr)

        if (allowance.lt(value)) {
          acc.cache[address] = false
          acc.approvedAll = false
        } else {
          acc.cache[address] = true
        }
        return acc
      },
      { approvedAll: true, cache: {} },
    )
  }, [state, rewards, contractAddr])
}

export const ApproveRewards = ({ campaignAddr, onSuccess, rewards }: Props) => {
  const theme = useTheme()
  const approvedRewards = useApprovedRewards(campaignAddr, rewards)

  useEffect(() => {
    if (approvedRewards.approvedAll) {
      onSuccess()
    }
  }, [approvedRewards.approvedAll, onSuccess])

  return (
    <div className="p-4" style={{ backgroundColor: theme.background.page }}>
      <Body color={theme.text.secondary}>
        Approve reward tokens for your staking contract.
      </Body>
      <div className="mt-4">
        {rewards.map((r, i) => (
          <DepositReward
            sent={approvedRewards.cache[r.address]}
            key={i}
            form={r}
            campaignAddr={campaignAddr}
          />
        ))}
      </div>
    </div>
  )
}

type DepositRewardProps = {
  campaignAddr: string
  form: StakingRewardToken
  sent: boolean
}

export const DepositReward = ({
  form,
  campaignAddr,
  sent,
}: DepositRewardProps) => {
  const theme = useTheme()
  const [approveToken, approveAsync] = useApproveToken()
  const { symbol, decimals } = useTokenByAddress(form.address)
  const getBal = useGetTokenBalance()
  const called = useRef(false)
  const userAddr = useEthAddress()
  const bal = useTokenBalance(userAddr || '', form.address)
  const [checkIsApproved] = useCheckIsTokenApproved()

  const _getBal = useCallback(async () => {
    if (called.current || !userAddr) return
    called.current = true
    await getBal(form.address, userAddr)
  }, [getBal, form.address, userAddr])

  const handleApprove = () =>
    approveToken(campaignAddr, form.address, form.value)

  useEffect(() => {
    checkIsApproved(campaignAddr, form.address)
  }, [campaignAddr, form.address, checkIsApproved])

  useEffect(() => {
    _getBal()
  }, [_getBal])

  const renderInteraction = () => {
    if (sent) {
      return (
        <div data-testid="deposit-reward-success">
          <Checkmark />
        </div>
      )
    }

    if (approveAsync.activity) {
      return <Activity />
    }

    return (
      <Button
        style={{ height: 40 }}
        title="Approve"
        variant="primary"
        onPress={handleApprove}
      />
    )
  }

  return (
    <div className="mb-4">
      <div
        className="flex flex-col rounded-lg p-4 mb-4"
        style={{ backgroundColor: theme.background.primary }}
      >
        <div className="flex flex-row items-center justify-between mb-2">
          <TokenCard address={form.address} />
          <SubHeader color={theme.text.secondary}>
            {displayAmount(form.value, decimals)}
          </SubHeader>
        </div>
        <Body color={theme.text.secondary}>{`Balance: ${displayAmount(
          bal,
          decimals,
        )} ${symbol} `}</Body>
      </div>
      {renderInteraction()}
    </div>
  )
}
