import React, { createContext } from "react";
import { BalanceState, initBalanceState } from "../state/balance";
import { Action } from "../state/common";

export type BalanceContext = {
  state: BalanceState;
  dispatch: React.Dispatch<Action<any>>;
};

export const BalanceCtx = createContext<BalanceContext>({
  state: initBalanceState(),
  dispatch: () => null
});
