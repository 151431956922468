interface NetworkAddresss {
  REGISTRY_ADDRESS: string
  FACTORY_ADDRESS: string
}

export interface NetworkConfig extends NetworkAddresss {
  V1_SUPPORT_CAMPAIGNS?: { [key: string]: boolean }
  OLD_CONTRACTS?: NetworkAddresss[]
}

interface Config {
  NETWORK_GOERLI: NetworkConfig
  NETWORK_MAINNET: NetworkConfig
  NETWORK_LOCAL: NetworkConfig
  NETWORK_MUMBAI: NetworkConfig
  NETWORK_POLYGON: NetworkConfig
  NETWORK_FORM_TESTNET: NetworkConfig
  FRONT_END_URL: string
  FORTMATIC_API_KEY: string
  PORTIS_DAPP_ID: string
}

const networkLocal: NetworkConfig = {
  REGISTRY_ADDRESS: process.env.REACT_APP_REGISTRY_ADDRESS || '',
  FACTORY_ADDRESS: process.env.REACT_APP_FACTORY_ADDRESS || '',
}

const networkMumbai: NetworkConfig = {
  REGISTRY_ADDRESS: '0x8001C1659915774F9b4C21a6C0830bEdE2a93572',
  FACTORY_ADDRESS: '0x80010DDfdfa9bA8Af3E679c9A02BE3AFAb5cBe75',
}

const networkGoerli: NetworkConfig = {
  REGISTRY_ADDRESS: '0x9fFF67Fc64ACF80ADA031B271898a695AAe42c59',
  FACTORY_ADDRESS: '0x9c754ed462ad295Ec757723A7369679Af670Ea0f',
}

const networkMainnet: NetworkConfig = {
  REGISTRY_ADDRESS: '0x5A86193c78634ff31139B73291929b27365F0c87',
  FACTORY_ADDRESS: '0x9dFEc8926DA96B2ffc7fa7f919D65BF2b4F5F3FC',
  V1_SUPPORT_CAMPAIGNS: {
    ['0x200Af1CEA6dd632d93196fB1A341990781c6a7f0'.toLowerCase()]: true,
    ['0x11047f76620aC810A969E08EBe55654e0e53CB96'.toLowerCase()]: true,
    ['0x757B98FCd6E6933b0041881471bcF3C421eC0668'.toLowerCase()]: true,
  },
  OLD_CONTRACTS: [
    {
      REGISTRY_ADDRESS: '0xbBd3dAFB1aF29aDb0E5926E8942396399BD5c3ef',
      FACTORY_ADDRESS: '0x135e1405F2189c1E9dC95A3b7c74A1360bA45B0b',
    },
  ],
}

const networkPolygon: NetworkConfig = {
  REGISTRY_ADDRESS: '0x8001C1659915774F9b4C21a6C0830bEdE2a93572',
  FACTORY_ADDRESS: '0x80010DDfdfa9bA8Af3E679c9A02BE3AFAb5cBe75',
}

const networkFormTestnet: NetworkConfig = {
  REGISTRY_ADDRESS: '0x800198f18Ea7fd1076E8314298d27E60E8a22064',
  FACTORY_ADDRESS: '0x800144efbCa3aba2dc82dBfF93E482b949C7F1EE',
}

export const config: Config = {
  FRONT_END_URL: window.location.origin,
  FORTMATIC_API_KEY: process.env.REACT_APP_FORTMATIC_API_KEY || '',
  PORTIS_DAPP_ID: process.env.REACT_APP_PORTIS_DAPP_ID || '',
  NETWORK_MAINNET: networkMainnet,
  NETWORK_LOCAL: networkLocal,
  NETWORK_GOERLI: networkGoerli,
  NETWORK_MUMBAI: networkMumbai,
  NETWORK_POLYGON: networkPolygon,
  NETWORK_FORM_TESTNET: networkFormTestnet,
}
