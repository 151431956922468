import { BigNumber } from 'ethers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Caption,
  container,
  margin,
  Value,
} from '@tryrolljs/design-system'
import { useCampaign, useCheckFreeTokens, useTokenByAddress } from '../../hooks'
import { displayAmount } from '../../util'

interface LeftoverMap {
  [key: string]: BigNumber
}

export const WithdrawButton = ({
  campaignAddress,
  claimFreeTokens,
}: {
  campaignAddress: string
  claimFreeTokens: () => void
}) => {
  const campaign = useCampaign(campaignAddress)
  const [values, setValues] = useState<LeftoverMap>({})
  const [checkFreeTokens] = useCheckFreeTokens()

  const hasValue = useMemo(() => {
    return Object.keys(values).reduce((acc: boolean, curr: string) => {
      if (!values[curr]?.isZero()) return true
      return acc
    }, false)
  }, [values])

  const getValues = useCallback(async () => {
    try {
      const leftovers: LeftoverMap = {}
      await Promise.all(
        campaign.rewardTokenAddresses.map(async (tokenAddress) => {
          const freeTokens = await checkFreeTokens(
            campaignAddress,
            tokenAddress,
          )
          if (freeTokens) {
            leftovers[tokenAddress] = freeTokens
          }
        }),
      )

      setValues(leftovers)
    } catch (err) {
      console.error(err)
    }
  }, [campaign.rewardTokenAddresses, campaignAddress, checkFreeTokens])

  useEffect(() => {
    getValues()
  }, [getValues])

  return (
    <div>
      <div>
        <Button
          style={[container.flex1, margin.mr16]}
          variant="primary"
          disabled={!hasValue}
          title="Withdraw"
          onPress={claimFreeTokens}
        />
        <div className="flex flex-col mt-2">
          {campaign.rewardTokenAddresses.map((tokenAddress) => {
            return (
              <WithdrawableValue
                key={tokenAddress}
                tokenAddress={tokenAddress}
                value={values[tokenAddress] || BigNumber.from(0)}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

const WithdrawableValue = ({
  tokenAddress,
  value,
}: {
  tokenAddress: string
  value: BigNumber
}) => {
  const token = useTokenByAddress(tokenAddress)

  return (
    <Caption>
      <Value
        displayValue={displayAmount(value, token.decimals)}
        decimals={token.decimals}
      />{' '}
      {token.symbol} left to withdraw
    </Caption>
  )
}
