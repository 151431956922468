import { Header, Body, margin, Button } from '@tryrolljs/design-system'
import Celebrate from '../../assets/svg/celebrate.svg'
import { useCreateCampaignCtx, useModal } from '../../hooks'
import { buildLink, displayTime } from '../../util'
import { ClipBoardText } from '../../molecules/clipboardText'

export const DeploySuccess = () => {
  const { values, campaignAddress } = useCreateCampaignCtx()
  const modal = useModal()

  return (
    <div className="flex flex-col w-94 p-4 m-4 items-center">
      <Celebrate />
      <Header style={[margin.mt24, margin.mb16]} weight="bold">
        Success!
      </Header>
      <Body>{`Your staking reward is scheduled to launch ${values.start.toLocaleDateString(
        'en-us',
        { month: 'short', day: '2-digit', year: 'numeric' },
      )}`}</Body>
      <Body style={{ marginBottom: 24 }}>{`at ${displayTime(
        values.start,
      )}. Here's the campaign link:`}</Body>
      <ClipBoardText value={buildLink('/campaigns/' + campaignAddress)} />
      <Button
        title="Done"
        variant="primary"
        onPress={() => modal.setOpen(false)}
        style={{ marginTop: 24 }}
      />
    </div>
  )
}
