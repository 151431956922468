import { useCallback, useEffect, useRef } from "react";
import { Activity } from "../../atoms/activity";
import { useTokenCtx } from "../../hooks";
import { DerivedForm, updateWizardSteps, useDeriveForm } from "./common";
import { useCampaignWizardManager } from "./manager";

export const deriveStep = (f: DerivedForm): number => {
  let step = 0;

  if (f.hasSetDuration) {
    step += 1;
  }

  if (f.hasSetRewards) {
    step += 1;
  }

  return step;
};

type Props = {
  campaignAddress: string;
};

export const UpdateCampaignManager = ({ campaignAddress }: Props) => {
  const [_deriveForm, asyncState] = useDeriveForm(campaignAddress);
  const { state } = useTokenCtx();
  const hasDerived = useRef(false);
  const { render, setStep, setValues } = useCampaignWizardManager(
    updateWizardSteps,
    campaignAddress,
    "update"
  );

  const handleDeriveForm = useCallback(async () => {
    // block from re-running derive form
    if (!hasDerived.current) {
      hasDerived.current = true;
      const res = await _deriveForm(state);
      if (res) {
        setStep(deriveStep(res));
        setValues(res.form);
      }
    }
  }, [_deriveForm, state, setStep, setValues]);

  // cannot allow this to run multiple times.
  // this will reset the state of the campaign wizard
  useEffect(() => {
    handleDeriveForm();
  }, [handleDeriveForm]);

  return asyncState.activity ? <Activity /> : render();
};
