import {
  Body,
  margin,
  useTheme,
  useChainID,
  shortenAddress,
  getEtherscanLink,
} from '@tryrolljs/design-system'
import { useCallback, useState } from 'react'
import { copyClipboard } from '../../util'
import LinkIcon from '../../assets/svg/link.svg'
import CopyIcon from '../../assets/svg/copy.svg'
import CheckIcon from '../../assets/svg/check.svg'

export const Web3Link = ({
  value,
  type,
  containerStyle,
}: {
  value: string
  type: 'address' | 'token' | 'tx'
  containerStyle?: React.CSSProperties
}) => {
  const chainID = useChainID()
  const theme = useTheme()
  const [showCheck, setShowCheck] = useState(false)

  const handleCopy = useCallback(() => {
    copyClipboard(value)
    setShowCheck(true)
    setTimeout(() => {
      setShowCheck(false)
    }, 300)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex items-center" style={containerStyle}>
      <Body style={margin.mr4}>{shortenAddress(value)}</Body>
      <div className="flex justify-center items-center mr-1 w-6 h-6">
        {showCheck ? (
          <CheckIcon />
        ) : (
          <div className="cursor-pointer" onClick={handleCopy}>
            <CopyIcon />
          </div>
        )}
      </div>

      <a
        className="flex"
        href={getEtherscanLink({ chainId: chainID || 1, type, address: value })}
        target="_blank"
        rel="noreferrer"
      >
        <Body
          style={[margin.mr4, { textDecorationLine: 'underline' }]}
          color={theme.text.highlight}
        >
          See on Etherscan
        </Body>
        <LinkIcon />
      </a>
    </div>
  )
}
