import {
  InvalidNetworkBanner,
  CHAIN_ID_MAIN_NET,
  Footer,
  CHAIN_ID_POLYGON,
} from '@tryrolljs/design-system'
import { PageHeader } from '../../molecules/pageHeader'
import { Background } from '../pageBackground'
import { PageContent } from '../pageContent'
import BackTab from '../../molecules/backTab'
import { CHAIN_ID_FORM_TESTNET } from '../../constants'

export const PageTemplate: React.FC<{ backtab?: boolean }> = ({
  children,
  backtab,
}) => {
  return (
    <Background>
      <PageHeader />
      <InvalidNetworkBanner
        title="Unsupported chain. Please switch to Etheruem Mainnet or Polygon in your wallet"
        supportedChainIDs={[
          CHAIN_ID_MAIN_NET,
          CHAIN_ID_POLYGON,
          CHAIN_ID_FORM_TESTNET,
        ]}
      />
      <PageContent>
        {backtab && <BackTab />}
        {children}
      </PageContent>
      <Footer />
    </Background>
  )
}
