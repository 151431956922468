import { useCallback } from 'react'
import { useSigner } from '@tryrolljs/design-system'
import { Signer } from 'ethers'
import {
  DeployRewardsForm,
  getTokenAllowance,
  tokenBalance,
} from '../../contracts'
import {
  Campaign,
  campaignEndDate,
  campaignStartDate,
  defaultCampaignDuration,
  isDurationSet,
  validateCampaignDuration,
} from '../../core'
import {
  FormCampaignEligibility,
  validateStakeToken,
} from '../../molecules/formCampaignEligibility'
import {
  FormCampaignRewards,
  validateRewards,
} from '../../molecules/formCampaignRewards'
import { FormCampaignSummary } from '../../molecules/formCampaignSummary'
import { TokenState } from '../../state/tokens/reducer'
import { selectTokenByAddress } from '../../state/tokens/selectors'
import { DeploySuccess } from '../deploySuccess'
import { FormLaunchSteps, FormUpdateLaunchSteps } from '../formLaunchSteps'
import { FormCampaignDetails } from '../../molecules/formCampaignDetails'
import {
  CampaignWizardForm,
  CampaignWizardType,
  initCreateCampaignForm,
  RewardForm,
} from '../../context/campaign'
import { AsyncState, useAsync, useCampaign, useTokenCtx } from '../../hooks'
import { BalanceState } from '../../state/balance'
import { displayAmount, isBeforeNow } from '../../util'
import { config } from '../../config'
import { IndicatorProps } from '../../atoms/indicator'
import { CampaignSummaryBottom } from '../campaignSummaryBottom'
import { routeMyCampaigns } from '../../navigation/routes'
import {
  NotifyAdjustDuration,
  NotifyAdjustDurationButtons,
} from '../../molecules/notifyAdjustDuration'
import {
  NotifyAdjustValue,
  NotifyAdjustValueButtons,
} from '../../molecules/notifyAdjustValue'

export const WIZARD_STEP_DURATION = 'WIZARD_STEP_DURATION'
export const WIZARD_STEP_REWARDS = 'WIZARD_STEP_REWARDs'
export const WIZARD_STEP_ELIGIBILITY = 'WIZARD_STEP_ELIGIBILITY'
export const WIZARD_STEP_SUMMARY = 'WIZARD_STEP_SUMMARY'
export const WIZARD_STEP_LAUNCH = 'WIZARD_STEP_LAUNCH'
export const WIZARD_STEP_SUCCESS = 'WIZARD_STEP_SUCCESS'
export const WIZARD_STEP_ADJUST_DURATION = 'WIZARD_STEP_ADJUST_DURATION'
export const WIZARD_STEP_ADJUST_VALUE = 'WIZARD_STEP_ADJUST_VALUE'

export type WizardStep = {
  name: string
  withButtons: boolean
  withWrapper: boolean
  component: JSX.Element
  title: string
  description: string
  disableBack?: boolean
  customBottom?: () => React.ReactElement
  validate: (
    values: CampaignWizardForm,
    state: TokenState,
    balanceState: BalanceState,
    userAddr: string,
    campaignAddr?: string,
  ) => IndicatorProps | null
}

const wizardStepDuration: WizardStep = {
  name: WIZARD_STEP_DURATION,
  withButtons: true,
  withWrapper: true,
  component: <FormCampaignDetails />,
  title: 'Details',
  description: 'Specify how long you want your rewards to run.',
  validate: (v) => validateCampaignDuration(v.start, v.end),
}

const wizardStepRewards: WizardStep = {
  name: WIZARD_STEP_REWARDS,
  withButtons: true,
  withWrapper: true,
  validate: (v, tokenState, balanceState, userAddr, campaignAddr) =>
    validateRewards(
      v.rewards,
      tokenState,
      balanceState,
      userAddr,
      campaignAddr,
    ),
  component: <FormCampaignRewards />,
  title: 'Rewards',
  description:
    "Enter the total amount of rewards for this campaign. Rewards are distributed based on the percentage of the pool a user's tokens represent and how long they're staked for.",
}

const wizardStepEligibility: WizardStep = {
  name: WIZARD_STEP_ELIGIBILITY,
  withButtons: true,
  withWrapper: true,
  validate: (v) => validateStakeToken(v.stakeTokenAddress),
  component: <FormCampaignEligibility />,
  title: 'Eligibility',
  description: 'Specify which token is eligible to stake and earn rewards.',
}

const validateSummary = (
  form: CampaignWizardForm,
  tokenState: TokenState,
  balanceState: BalanceState,
  userAddr: string,
) => {
  const durationMessage = validateCampaignDuration(form.start, form.end)
  if (durationMessage) return durationMessage

  const rewardsMessage = validateRewards(
    form.rewards,
    tokenState,
    balanceState,
    userAddr,
  )
  if (rewardsMessage) return rewardsMessage

  return validateStakeToken(form.stakeTokenAddress)
}

const wizardStepSummaryUpdate: WizardStep = {
  name: WIZARD_STEP_SUMMARY,
  withButtons: true,
  withWrapper: true,
  validate: validateSummary,
  component: <FormCampaignSummary />,
  title: 'Review',
  description: '',
}

// only difference between summary step for create vs update is the custom bottom component (shareable link)
const wizardStepSummaryCreate: WizardStep = {
  ...wizardStepSummaryUpdate,
  customBottom: () => <CampaignSummaryBottom />,
}

export const wizardStepLaunch: WizardStep = {
  name: WIZARD_STEP_LAUNCH,
  withButtons: false,
  withWrapper: true,
  disableBack: true,
  validate: () => null,
  component: <FormLaunchSteps />,
  title: 'Staking Contract',
  description: 'To launch the staking contract, follow these steps:',
}

export const wizardStepUpdateLaunch: WizardStep = {
  name: WIZARD_STEP_LAUNCH,
  withButtons: false,
  withWrapper: true,
  disableBack: true,
  validate: () => null,
  component: <FormUpdateLaunchSteps />,
  title: 'Staking Contract',
  description: 'To launch the staking contract, follow these steps:',
}

export const wizardStepExtendLaunch: WizardStep = {
  name: WIZARD_STEP_LAUNCH,
  withButtons: false,
  withWrapper: true,
  disableBack: true,
  validate: () => null,
  component: <FormUpdateLaunchSteps />,
  title: 'Staking Contract',
  description: 'To update the staking contract, follow these steps:',
}

export const wizardStepDeploySuccess: WizardStep = {
  name: WIZARD_STEP_SUCCESS,
  withButtons: false,
  withWrapper: false,
  validate: () => null,
  component: <DeploySuccess />,
  title: '',
  description: '',
}

export const wizardStepAdjustDuration: WizardStep = {
  name: WIZARD_STEP_ADJUST_DURATION,
  withButtons: true,
  withWrapper: true,
  component: <NotifyAdjustDuration />,
  title: 'Recommended Duration',
  description: '',
  validate: () => null,
  customBottom: () => <NotifyAdjustDurationButtons />,
}

const wizardStepAdjustValue: WizardStep = {
  name: WIZARD_STEP_ADJUST_VALUE,
  withButtons: true,
  withWrapper: true,
  component: <NotifyAdjustValue />,
  title: 'Recommended Rewards',
  description: '',
  validate: () => null,
  customBottom: () => <NotifyAdjustValueButtons />,
}

// all steps
export const wizardSteps: WizardStep[] = [
  wizardStepDuration,
  wizardStepRewards,
  wizardStepAdjustValue,
  wizardStepEligibility, // which token can users stake
  wizardStepSummaryCreate,
  wizardStepLaunch,
  wizardStepDeploySuccess,
]

// does not have eligibility step
export const updateWizardSteps: WizardStep[] = [
  wizardStepDuration,
  wizardStepRewards,
  wizardStepAdjustValue,
  wizardStepSummaryUpdate,
  wizardStepUpdateLaunch,
  wizardStepDeploySuccess,
]

export const extendWizardSteps: WizardStep[] = [
  wizardStepDuration,
  wizardStepRewards,
  wizardStepAdjustValue,
  wizardStepExtendLaunch,
  wizardStepDeploySuccess,
]

export type DerivedForm = {
  hasSetDuration: boolean
  hasSetRewards: boolean
  form: CampaignWizardForm
}

export const buildExistingRewards = async (
  c: Campaign,
  state: TokenState,
  signer: Signer,
): Promise<RewardForm[]> => {
  const address = await signer.getAddress()
  return Promise.all(
    c.rewards.map(async (r) => {
      const { symbol, decimals } = selectTokenByAddress(state, r.address)
      const balance = await tokenBalance(address, r.address, signer)
      const allowance = await getTokenAllowance(
        address,
        c.address,
        r.address,
        signer,
      )
      const value = allowance.gt(balance) ? balance : allowance
      return {
        address: r.address,
        value: displayAmount(value, decimals),
        symbol,
      }
    }),
  )
}

export const deriveForm = async (
  c: Campaign,
  state: TokenState,
  signer: Signer,
): Promise<DerivedForm> => {
  const defaultForm = initCreateCampaignForm()
  const stakeToken = selectTokenByAddress(state, c.tokenAddress)

  let start = defaultForm.start
  let end = defaultForm.end
  let hasSetDuration = false

  if (isDurationSet(c) && !isBeforeNow(campaignEndDate(c))) {
    start = campaignStartDate(c)
    end = campaignEndDate(c)
    hasSetDuration = true
  }

  const rewards = await buildExistingRewards(c, state, signer)

  return {
    hasSetDuration,
    hasSetRewards: false, // this lets the form know that user must set rewards
    form: {
      suggestValues: null,
      suggestedDuration: null,
      start,
      end,
      rewards,
      stakeTokenAddress: stakeToken.address,
    },
  }
}

export const deriveRewardInput = (
  f: DeployRewardsForm[],
  state: TokenState,
): RewardForm[] =>
  f.map((r) => {
    const { symbol, decimals } = selectTokenByAddress(state, r.reward.address)
    return {
      symbol,
      value: displayAmount(r.reward.value, decimals),
      address: r.reward.address,
    }
  })

export const useDeriveForm = (
  campaignAddress: string,
): [(state: TokenState) => Promise<DerivedForm | void>, AsyncState] => {
  const campaign = useCampaign(campaignAddress)
  const { state } = useTokenCtx()
  const async = useAsync()
  const { managedExec } = async
  const signer = useSigner()

  const _deriveForm = useCallback(
    () =>
      managedExec(async () => {
        if (!signer) throw new Error('account is not connected')
        const form = await deriveForm(campaign, state, signer)
        return form
      }),
    [state, campaign, managedExec, signer],
  )

  return [_deriveForm, async]
}

export type WizardEncodeReward = {
  address: string
  symbol: string
  value: string
}

export type WizardEncodeParams = {
  wizardType: CampaignWizardType | null
  step: number
  rewards: WizardEncodeReward[]
  start: number
  end: number
  stakeTokenAddress: string
  // stakeTokenSymbol: string;
}

export const encodeWizardUrl = (params: WizardEncodeParams) => {
  const encodeRewards = encodeURIComponent(JSON.stringify(params))
  const url =
    config.FRONT_END_URL + `${routeMyCampaigns()}?wizardState=${encodeRewards}`
  const encoded = encodeURI(url)
  return encoded
}

const defaultWizardState = (): WizardEncodeParams => {
  const [start, end] = defaultCampaignDuration()
  return {
    wizardType: null,
    step: 0,
    start: start.getTime(),
    end: end.getTime(),
    rewards: [],
    stakeTokenAddress: '',
    // stakeTokenSymbol: ""
  }
}

export const decodeWizardUrl = (url: string): WizardEncodeParams => {
  if (!url) return defaultWizardState()
  const search = url.split('?')[1] || ''
  if (!search) return defaultWizardState()

  const urlParams = new URLSearchParams(search)

  const wizardState = decodeURIComponent(urlParams.get('wizardState') || '')

  return JSON.parse(wizardState) as WizardEncodeParams
}
