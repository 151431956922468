import { margin, Title, Body, Value, text } from '@tryrolljs/design-system'
import { TokenLogo } from '../tokenLogo'
import { useTokenByAddress, useUserTokenRewards } from '../../hooks'
import { displayAmount } from '../../util'

type Props = {
  tokenAddress: string
  campaignAddress: string
}

export const EarnedToken = ({ tokenAddress, campaignAddress }: Props) => {
  const token = useTokenByAddress(tokenAddress)
  const reward = useUserTokenRewards(campaignAddress, tokenAddress)

  return (
    <div className="flex flex-col items-center mx-4">
      <TokenLogo token={token} style={margin.mb16} />
      <Value
        displayValue={displayAmount(reward, token.decimals)}
        decimals={token.decimals}
        renderValue={(val) => <Title>{val}</Title>}
      />
      <Body style={[margin.mv16, text.center]}>Earned {token.symbol}</Body>
    </div>
  )
}
