import { MAX_SCREEN_CONTENT_WIDTH } from "../../constants";

export const PageContent: React.FC = ({ children }) => {
  return (
    <div className="p-8 flex flex-1 justify-center">
      <div className="flex flex-1 flex-col" style={{ maxWidth: MAX_SCREEN_CONTENT_WIDTH }}>
        {children}
      </div>
    </div>
  );
};
