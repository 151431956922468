import { useParams } from 'react-router'
import { useEffect } from 'react'
import {
  Surface,
  margin,
  useTheme,
  container,
  useSigner,
  Spinner,
} from '@tryrolljs/design-system'
import { StakeTokens } from '../../organisms/stakeTokens'
import { ClaimReward } from '../../organisms/claimReward'
import { CampaignDetail } from '../../organisms/campaignDetail'
import {
  useCampaignAsyncActivity,
  useGetCampaign,
  useModal,
  useRefreshCampaign,
} from '../../hooks'
import { RewardDetail } from '../../organisms/rewardDetail'
import { UpdateCampaignManager } from '../../organisms/campaignWizard/update'
import { PageTemplate } from '../../templates/page'
import { ProviderCampaignDetails } from '../../providers/campaignDetails'
import { CampaignDetailsConnectPrompt } from '../../organisms/campaignDetailsConnectPrompt'

export function PageCampaignDetails() {
  const theme = useTheme()
  const params = useParams()
  const getCampaign = useGetCampaign()
  const address = params.address || ''
  const campaignActivity = useCampaignAsyncActivity(address)
  const modal = useModal()
  const signer = useSigner()
  useRefreshCampaign(address)

  const openUpdateModal = () => {
    modal.setRenderer(
      () => <UpdateCampaignManager campaignAddress={address} />,
      {
        style: { padding: 0 },
      },
    )
    modal.setOpen(true)
  }

  useEffect(() => {
    getCampaign(address)
  }, [getCampaign, address])

  return (
    <PageTemplate backtab>
      <ProviderCampaignDetails>
        {!signer ? (
          <div className="flex h-full justify-center items-center">
            <CampaignDetailsConnectPrompt />
          </div>
        ) : (
          <>
            {campaignActivity ? (
              <div className="flex h-full justify-center items-center">
                <Spinner size="lg" />
              </div>
            ) : (
              <div className="flex justify-center">
                <Surface
                  style={[
                    margin.mh8,
                    container.flex1,
                    { backgroundColor: theme.background.primary },
                  ]}
                >
                  <StakeTokens address={address} />
                  <CampaignDetail address={address} />
                </Surface>
                <Surface
                  style={[
                    margin.mh8,
                    container.flex1,
                    { backgroundColor: theme.background.primary },
                  ]}
                >
                  <ClaimReward openModal={openUpdateModal} address={address} />
                  <RewardDetail campaignAddr={address} />
                </Surface>
              </div>
            )}
          </>
        )}
      </ProviderCampaignDetails>
    </PageTemplate>
  )
}
