import { BigNumber } from 'ethers'
import { useCallback, useContext, useRef } from 'react'
import {
  approveToken,
  getTokenAllowance,
  getTokenByAddress,
} from '../contracts'
import { Token } from '../core'
import { defaultEthErrorMessages } from '../error'
import { TokenCtx } from '../providers/tokens'
import { actionGetTokenBalance } from '../state/balance/action'
import {
  actionGetTokenList,
  actionSaveTokens,
  actionSetTokenApproved,
  normalizeToken,
} from '../state/tokens/actions'
import { initTokenState, TokenState } from '../state/tokens/reducer'
import {
  SearchToken,
  selectSearchableList,
  selectTokenAllowance,
  selectTokenByAddress,
  selectTokenExistsByAddress,
} from '../state/tokens/selectors'
import { useBalanceCtx } from './balance'
import {
  AsyncState,
  ethErrorHandler,
  useAsync,
  useEthAddress,
  useLibrary,
  useSigner,
} from '.'

export const useTokenCtx = () => useContext(TokenCtx)

const filterSearchTokens = (
  state: TokenState,
  list: SearchToken[],
  searchTerm: string,
): SearchToken[] => {
  if (searchTerm === '') return list

  const isAddress = searchTerm.startsWith('0x')

  return list.filter((t) => {
    const token = selectTokenByAddress(state, t.address)

    if (token.symbol && token.symbol.toLowerCase().includes(searchTerm))
      return true

    if (isAddress) {
      if (token.address && token.address.toLowerCase().includes(searchTerm))
        return true
    }

    return false
  })
}

export const useFilterSymbols = (symbolOrAddr: string): SearchToken[] => {
  const { state } = useTokenCtx()
  const searchTerm = symbolOrAddr.toLowerCase()
  const searchList = selectSearchableList(state)
  const filteredList = useRef(searchList)
  const prevSearchTerm = useRef(searchTerm)

  const filter = (list: SearchToken[]) => {
    filteredList.current = filterSearchTokens(state, list, searchTerm)
    prevSearchTerm.current = searchTerm
    return filteredList.current
  }

  // means that user is deleting characters
  if (prevSearchTerm.current.length > searchTerm.length) {
    return filter(searchList)
  }

  // means we have already narrowed down some of the tokens
  if (filteredList.current.length > 0) {
    return filter(filteredList.current)
  }

  return filter(searchList)
}

export const useTokenByAddress = (address: string): Token => {
  const { state } = useTokenCtx()
  return selectTokenByAddress(state, address)
}

// utility hook to retrieve token data if it does not already exist.
// will not refetch tokens if it has already been retrieved.
// consumer must access cached token data and dispatch when appropriate
export const useMaybeGetToken = (): [
  (address: string) => Promise<Token | null>,
  () => void,
] => {
  const lib = useLibrary()
  const { state, dispatch } = useTokenCtx()
  const tokenNorm = useRef<TokenState>(initTokenState())

  const getToken = useCallback(
    async (address: string): Promise<Token | null> => {
      try {
        if (!lib) return null
        if (
          !selectTokenExistsByAddress(state, address) &&
          !selectTokenExistsByAddress(tokenNorm.current, address)
        ) {
          const token = await getTokenByAddress(address, lib)
          normalizeToken(tokenNorm.current, token)
          return token
        }

        return null
      } catch (err) {
        return null
      }
    },
    [lib, state],
  )

  const dispatchTokens = useCallback(() => {
    dispatch(actionSaveTokens(tokenNorm.current))
  }, [dispatch])

  return [getToken, dispatchTokens]
}

export const useTokensByAddresses = (addresses: string[]): Token[] => {
  const { state } = useTokenCtx()
  return addresses
    ? addresses.map((addr) => selectTokenByAddress(state, addr))
    : []
}

export const useGetTokenBalance = () => {
  const signer = useSigner()
  const { dispatch } = useBalanceCtx()
  const async = useAsync()
  const { managedExec } = async

  return useCallback(
    async (tokenAddress: string, holderAddr: string) => {
      if (!signer || !tokenAddress || !holderAddr) return null
      const action = await managedExec(
        () => actionGetTokenBalance(holderAddr, tokenAddress, signer),
        ethErrorHandler(
          defaultEthErrorMessages,
          `unable to get ${tokenAddress} balance`,
        ),
      )
      action && dispatch(action)
    },
    [signer, dispatch, managedExec],
  )
}

export const useApproveToken = (): [
  (spenderAddr: string, tokenAddr: string, value: BigNumber) => Promise<void>,
  AsyncState,
] => {
  const asyncState = useAsync()
  const signer = useSigner()
  const address = useEthAddress()
  const { dispatch } = useTokenCtx()

  const _approveToken = useCallback(
    async (
      spenderAddr: string,
      tokenAddr: string,
      value: BigNumber,
    ): Promise<void> =>
      asyncState.managedExec(async () => {
        if (!address || !signer) throw new Error('user account not connected')
        const tx = await approveToken(spenderAddr, tokenAddr, signer, value)
        await tx.wait()
        const allowance = await getTokenAllowance(
          address,
          spenderAddr,
          tokenAddr,
          signer,
        )
        dispatch(actionSetTokenApproved(tokenAddr, spenderAddr, allowance))
      }, ethErrorHandler(defaultEthErrorMessages, `unable to approve contract ${spenderAddr} usage of token ${tokenAddr}`)),
    [address, signer, asyncState, dispatch],
  )

  return [_approveToken, asyncState]
}

export const useTokenAllowance = (tokenAddr: string, spenderAddr: string) => {
  const { state } = useTokenCtx()
  return selectTokenAllowance(state, tokenAddr, spenderAddr)
}

export const useCheckIsTokenApproved = (): [
  (spenderAddr: string, tokenAddr: string) => Promise<BigNumber | undefined>,
  AsyncState,
] => {
  const addr = useEthAddress()
  const signer = useSigner()
  const { dispatch } = useTokenCtx()
  const asyncState = useAsync()
  const { managedExec } = asyncState

  const checkIsApproved = useCallback(
    async (spenderAddr: string, tokenAddr: string) =>
      managedExec(async () => {
        if (!addr || !signer) throw new Error('user account not connected')
        const allowance = await getTokenAllowance(
          addr,
          spenderAddr,
          tokenAddr,
          signer,
        )
        dispatch(actionSetTokenApproved(tokenAddr, spenderAddr, allowance))
        return allowance
      }, ethErrorHandler(defaultEthErrorMessages, `unable to check if contract ${spenderAddr} has been approved for token ${tokenAddr}`)),
    [dispatch, addr, managedExec, signer],
  )

  return [checkIsApproved, asyncState]
}

export const useGetTokenLists = (): [() => void, AsyncState] => {
  const { dispatch } = useTokenCtx()
  const asyncState = useAsync()
  const { managedExec } = asyncState

  const getLists = useCallback(
    () =>
      managedExec(async () => {
        const action = await actionGetTokenList()
        dispatch(action)
      }),
    [dispatch, managedExec],
  )

  return [getLists, asyncState]
}

export const useTokenPair = (
  address0: string,
  address1: string,
): [Token, Token] => {
  const { state } = useTokenCtx()

  const token0 = selectTokenByAddress(state, address0)
  const token1 = selectTokenByAddress(state, address1)

  return [token0, token1]
}
