import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import {
  SUPPORTED_CHAIN_IDS,
  TryrollProvider,
  Web3Provider,
  injectFonts,
} from '@tryrolljs/design-system'
import './App.css'
import DateFnsUtils from '@date-io/date-fns'
import { useEffect } from 'react'
import Modal from 'react-modal'
import { ProviderCampaignState } from './providers/campaigns'
import { ProviderTokenState } from './providers/tokens'
import { RootNavigator } from './navigation'
import { ModalProvider } from './providers/modal'
import { ModalManager } from './organisms/modal'
import 'react-activity/dist/Spinner.css'
import { ProviderBalanceState } from './providers/balance'
import { config } from './config'
import { useAppVersion } from './hooks'
import { ContractPoolProvider } from './providers/contracts'
import { CHAIN_ID_FORM_TESTNET } from './constants'

injectFonts()

function App() {
  useAppVersion()

  useEffect(() => {
    Modal.setAppElement('body')
  }, [])

  return (
    <Web3Provider
      fortmaticApiKey={config.FORTMATIC_API_KEY}
      portisDappID={config.PORTIS_DAPP_ID}
      supportedChainIDs={[...SUPPORTED_CHAIN_IDS, CHAIN_ID_FORM_TESTNET]}
    >
      <ContractPoolProvider>
        <TryrollProvider>
          <ProviderTokenState>
            <ProviderCampaignState>
              <ProviderBalanceState>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <ModalProvider>
                    <ModalManager />
                    <RootNavigator />
                  </ModalProvider>
                </MuiPickersUtilsProvider>
              </ProviderBalanceState>
            </ProviderCampaignState>
          </ProviderTokenState>
        </TryrollProvider>
      </ContractPoolProvider>
    </Web3Provider>
  )
}

export default App
