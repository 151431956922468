import {
  Button,
  Body,
  Surface,
  container,
  CircleImg,
  margin,
  useTheme,
  padding,
} from '@tryrolljs/design-system'
import { TokenCard } from '../tokenCard'
import { useCampaign, useTokenByAddress } from '../../hooks'
import { MultiTokenCard } from '../multipleTokens'

type Props = {
  address: string
  onSelect?: () => void
}

export const CampaignCard = ({ address, onSelect }: Props) => {
  const theme = useTheme()
  const campaign = useCampaign(address)
  const token = useTokenByAddress(campaign.tokenAddress)

  return (
    <Surface
      style={[
        { width: 306, margin: 12, backgroundColor: theme.background.primary },
        container.center,
        container.shadowXL,
        padding.p24,
      ]}
    >
      <CircleImg style={[margin.mb16]} size={72} color={token.color} />
      <Body weight="bold" style={[margin.mb24]}>
        {token.symbol}
      </Body>
      <Info
        style={{ borderBottomWidth: 1 }}
        title="Stake"
        element={<TokenCard address={campaign.tokenAddress} />}
      />
      <Info
        style={{ borderBottomWidth: 1 }}
        title="Earn"
        element={<MultiTokenCard addresses={campaign.rewardTokenAddresses} />}
      />
      <Info title="Status" element={<Body>{campaign.status}</Body>} />
      <Button
        style={[container.fullWidth, margin.mt32]}
        onPress={onSelect}
        variant="primary"
        title="View Details"
      />
    </Surface>
  )
}

type InfoProps = {
  title: string
  element: React.ReactElement
  style?: React.CSSProperties
}

const Info = ({ title, element, style }: InfoProps) => {
  const theme = useTheme()
  return (
    <div style={style} className="w-full flex justify-between mb-4 pb-2">
      <Body color={theme.text.secondary}>{title}</Body>
      {element}
    </div>
  )
}
