import React, { useState } from 'react'
import {
  margin,
  SubHeader,
  useTheme,
  CollapsableView,
} from '@tryrolljs/design-system'
import StyledLink from '../../atoms/styledLink'
import { FAQ_URL, supportEmail } from '../../constants'

const faqList = [
  {
    title: 'What is Roll Staking?',
    content: (
      <>
        <SubHeader>
          Roll Staking is a simple and secure way to create staking contracts
          for social tokens or any ERC20 token. You can start liquidity mining,
          yield farming or distributing your tokens with Roll Staking right now.
        </SubHeader>
        <SubHeader style={[margin.mv24]}>
          If you have a DAO, run growth at a protocol, have an NFT project or
          are a social token creator, you can use Roll Staking to collaborate
          and grow your community and its liquidity.
        </SubHeader>
      </>
    ),
    isExpanded: false,
  },
  {
    title: 'What tokens can I use or stake with Roll Staking?',
    content: (
      <SubHeader>
        Roll Staking is a Web3 feature that supports any and all ERC20 tokens
        minted on the Ethereum blockchain.
      </SubHeader>
    ),
    isExpanded: false,
  },
  {
    title:
      'What are the incentives for me to create or participate in a campaign?',
    content: (
      <>
        <SubHeader>
          You can use Roll Staking to launch a staking contract and create
          staking rewards to grow your community (stake one token, get another)
          or provide liquidity rewards for any ERC20 token. Staking rewards are
          distributed by the smart contract and given to the user proportional
          to the size and duration of the liquidity they provide.
        </SubHeader>
        <SubHeader style={[margin.mv24]}>
          Staking tokens means locking them in a smart contract in exchange for
          a percentage of the reward token. The amount of reward tokens a user
          received is based on how long and how many tokens the user has staked.
        </SubHeader>
      </>
    ),
    isExpanded: false,
  },
  {
    title: 'Can I stake from my Roll wallet?',
    content: (
      <>
        <SubHeader>
          You cannot stake tokens from your Roll wallet at this time.
        </SubHeader>
        <SubHeader style={[margin.mv24]}>
          Staking contracts are interacting directly with the blockchain and
          need a Web3-compatible wallet in order to create and participate in
          staking campaigns.
        </SubHeader>
      </>
    ),
    isExpanded: false,
  },
]

const FAQ: React.FC = () => {
  const [faqs, setFaqs] = useState(faqList)
  const theme = useTheme()
  const onToggle = (index: number) => {
    setFaqs(
      faqs.map((el, i) =>
        i === index ? { ...el, isExpanded: !el.isExpanded } : el,
      ),
    )
  }
  return (
    <div className="my-4 pb-6 border-b">
      {faqs.map((faq, index) => {
        return (
          <div
            key={faq.title}
            className="my-4"
            style={{ backgroundColor: theme.background.primary }}
          >
            <CollapsableView
              title={faq.title}
              content={faq.content}
              isExpanded={faq.isExpanded}
              toggle={() => onToggle(index)}
            />
          </div>
        )
      })}
      <SubHeader>
        Got more questions on how to stake on Roll? Check our{' '}
        <StyledLink title="FAQ" url={FAQ_URL} /> or email{' '}
        <StyledLink title={supportEmail} url={`mailto:${supportEmail}`} />
      </SubHeader>
    </div>
  )
}

export default FAQ
