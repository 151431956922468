import { useTheme } from '@tryrolljs/design-system'
import { Spinner } from 'react-activity'

type Props = {
  style?: React.CSSProperties
}

export const Activity = ({ style }: Props) => {
  const theme = useTheme()
  return (
    <Spinner
      style={
        style
          ? { color: theme.text.highlight, ...style }
          : { color: theme.text.highlight }
      }
    />
  )
}
