import { SubHeader, useTheme } from '@tryrolljs/design-system'
import DescriptionIcon from '../../assets/svg/description.svg'
import { DiscordLink } from '../../atoms/discordLink'
import ExternalLink from '../../atoms/externalLink'
import StyledLink from '../../atoms/styledLink'
import { FAQ_URL, tryrollEmail } from '../../constants'

const SoftLaunchWarning = () => {
  const theme = useTheme()
  return (
    <div
      className="w-full h-24 mb-8 rounded shadow-xl"
      style={{ backgroundColor: theme.background.primary }}
    >
      <div className="flex flex-col m-6 h-16 bg-color-white">
        <SubHeader style={{ lineHeight: 28 }}>
          Roll Staking is currently in beta. Please read the{' '}
          <ExternalLink url={FAQ_URL}>
            <>
              <SubHeader weight="bold">FAQs</SubHeader>{' '}
              <div className="inline-block align-text-top">
                <DescriptionIcon />
              </div>
            </>
          </ExternalLink>
          , and ask questions in <DiscordLink />
          {'   '}
          before staking or creating a staking contract. Please report any UI
          bugs on our <DiscordLink /> or{' '}
          <StyledLink title={tryrollEmail} url={`mailto:${tryrollEmail}`} />
        </SubHeader>
      </div>
    </div>
  )
}

export default SoftLaunchWarning
