import {
  margin,
  Surface,
  Button,
  Header,
  Body,
  padding,
  container,
  useTheme,
} from '@tryrolljs/design-system'
import { SyntheticEvent, useCallback, useEffect, useRef } from 'react'
import ConnectWalletIcon from '../../assets/svg/connectWallet.svg'
import StakingRewardIcon from '../../assets/svg/stakingReward.svg'
import { useEthAddress, useModal } from '../../hooks'
import { decodeWizardUrl, WizardEncodeParams } from '../campaignWizard/common'
import { CreateCampaignManager } from '../campaignWizard'
import { ConnectWallet } from '../connectWallet'

const rewardContent = {
  icon: <StakingRewardIcon />,
  content:
    'Increase liquidity and grow your community by offering staking rewards.',
  btnTitle: 'Create Staking Rewards',
}
const walletContent = {
  icon: <ConnectWalletIcon />,
  content:
    'You can connect your wallet to stake your tokens and begin earning staking rewards!',
}

const CampaignFooter = () => {
  const address = useEthAddress()
  const modal = useModal()
  const theme = useTheme()
  const checkedUrl = useRef(false)

  const openCreateWizard = useCallback(
    (e?: SyntheticEvent, wizardState?: WizardEncodeParams) => {
      modal.setRenderer(
        () => <CreateCampaignManager wizardState={wizardState} />,
        {
          style: {
            padding: 0,
            maxHeight: 700,
          },
        },
      )
      modal.setOpen(true)
    },
    [modal],
  )

  useEffect(() => {
    if (checkedUrl.current) return
    checkedUrl.current = true
    const wizardState = decodeWizardUrl(window.location.href)
    if (wizardState.wizardType === 'create') {
      openCreateWizard(undefined, wizardState)
    }
  }, [modal, openCreateWizard])

  return (
    <Surface
      style={[
        margin.mt32,
        container.fullWidth,
        container.shadowXL,
        { backgroundColor: theme.background.primary },
      ]}
    >
      <div className="flex w-full h-44">
        <div className="flex h-36 w-52 ml-6 mb-5">
          {address ? rewardContent.icon : walletContent.icon}
        </div>
        <div className="flex flex-col ml-10">
          <Header weight="bold" style={margin.mt16}>
            Ready to get started?
          </Header>
          <Body color={theme.text.secondary} style={[margin.mv24, padding.pl4]}>
            {address ? rewardContent.content : walletContent.content}
          </Body>
          {address ? (
            <Button
              variant="primary"
              title={rewardContent.btnTitle}
              style={[margin.mb24, { width: 222 }]}
              onPress={openCreateWizard as () => void}
            />
          ) : (
            <div className="w-40">
              <ConnectWallet />
            </div>
          )}
        </div>
      </div>
    </Surface>
  )
}

export default CampaignFooter
