import { BigNumber, Signer } from "ethers";
import { tokenBalance } from "../../contracts";
import { Action } from "../common";
import { BalanceData, CLEAR_BALANCES, SET_BALANCE } from "./types";

export const actionSaveBalanceData = (
  token: string,
  holder: string,
  bal: BigNumber
): Action<BalanceData> => ({
  type: SET_BALANCE,
  payload: { tokenAddress: token, holderAddress: holder, balance: bal }
});

export const actionGetTokenBalance = async (
  userAddr: string,
  tokenAddr: string,
  lib: Signer
): Promise<Action<BalanceData>> => {
  const bal = await tokenBalance(userAddr, tokenAddr, lib);
  return actionSaveBalanceData(tokenAddr, userAddr, bal);
};

export const actionClearBalances = (): Action<null> => ({
  type: CLEAR_BALANCES,
  payload: null
});
