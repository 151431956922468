import { Body, margin, useTheme, grey } from '@tryrolljs/design-system'
import { TokenCard } from '../../molecules/tokenCard'
import { useCampaign } from '../../hooks'
import {
  campaignIsActive,
  displayCampaignEnd,
  displayCampaignStart,
  displayCampaignTimeRemaing,
} from '../../core'
import { Web3Link } from '../../molecules/web3Link'

type Props = {
  address: string
}

export const CampaignDetail = ({ address }: Props) => {
  const campaign = useCampaign(address)
  const theme = useTheme()

  return (
    <div
      className="flex w-full p-12"
      style={{ backgroundColor: theme.background.primary, padding: 24 }}
    >
      <div className="w-1/2">
        <Info title="Status">
          <Body>{campaign.status}</Body>
        </Info>
        <Info title="Start date">
          <Body>{displayCampaignStart(campaign)}</Body>
        </Info>
        <Info title="End date">
          <Body>{displayCampaignEnd(campaign)}</Body>
        </Info>
        {campaignIsActive(campaign) && (
          <Info title="Time remaining">
            <Body>{displayCampaignTimeRemaing(campaign)}</Body>
          </Info>
        )}
      </div>
      <div className="w-1/2">
        <Info title="Stake">
          <TokenCard address={campaign.tokenAddress} />
          <Web3Link
            containerStyle={{ marginTop: 8 }}
            value={campaign.tokenAddress}
            type="token"
          />
        </Info>
        <Info title="Earn">
          {campaign.rewardTokenAddresses.map((tokenAddr, idx) => (
            <div
              key={tokenAddr}
              className={
                idx !== campaign.rewardTokenAddresses.length - 1
                  ? 'mb-6'
                  : undefined
              }
            >
              <TokenCard address={tokenAddr} />
              <Web3Link
                containerStyle={{ marginTop: 8 }}
                value={tokenAddr}
                type="token"
              />
            </div>
          ))}
        </Info>
        <Info title="Staking Contract Address">
          <Web3Link value={address} type="address" />
        </Info>
      </div>
    </div>
  )
}

type InfoProps = {
  title: string
}

export const Info: React.FC<InfoProps> = ({ title, children }) => {
  return (
    <div className="flex flex-col my-3">
      <Body color={grey} style={[margin.mv8, { fontWeight: '600' }]}>
        {title}
      </Body>
      {children}
    </div>
  )
}
