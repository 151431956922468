import React, { createContext, useReducer } from "react";
import { campaignReducer, CampaignState, initCampaignState } from "../state/campaigns/reducer";
import { Action } from "../state/common";

export type CampaignContext = {
  state: CampaignState;
  dispatch: React.Dispatch<Action<any>>;
};

export const CampaignCtx = createContext<CampaignContext>({
  state: initCampaignState(),
  dispatch: () => null
});

type Props = {
  initState?: CampaignState;
};

export const ProviderCampaignState: React.FC<Props> = ({ children, initState }) => {
  const [state, dispatch] = useReducer(campaignReducer, initState || initCampaignState());
  return <CampaignCtx.Provider value={{ state, dispatch }}>{children}</CampaignCtx.Provider>;
};
