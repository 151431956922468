import { BigNumber } from "ethers";
import { createContext, useContext } from "react";
import { IndicatorProps } from "../atoms/indicator";
import { DeployRewardsForm } from "../contracts";
import { defaultCampaignDuration } from "../core";
import { SuggestedDuration } from "../hooks";
import { WizardEncodeParams } from "../organisms/campaignWizard/common";
import { SearchToken } from "../state/tokens/selectors";

export type CampaignWizardType = "create" | "update" | "extend";
export type CampaignCurrentDeposits = { [key: string]: DeployRewardsForm };

export type CampaignWizardForm = {
  start: Date;
  end: Date;
  rewards: RewardForm[];
  stakeTokenAddress: string;
  suggestedDuration: SuggestedDuration | null;
  suggestValues: BigNumber[] | null;
};

type SentRewardsMap = { [key: string]: boolean };

export type CreateCampaignContext = {
  values: CampaignWizardForm;
  setValues: (v: CampaignWizardForm) => void;
  nextStep: () => void;
  previousStep: () => void;
  campaignAddress: string;
  setCampaignAddress: (s: string) => void;
  wizardType: CampaignWizardType;
  step: number;
  ind: IndicatorProps;
  sentRewards: SentRewardsMap;
  setSentRewards: (m: SentRewardsMap) => void;
};

export const initCreateCampaignForm = (wizardState?: WizardEncodeParams): CampaignWizardForm => {
  const [start, end] = defaultCampaignDuration();
  return {
    suggestValues: null,
    suggestedDuration: null,
    start: wizardState ? new Date(wizardState.start) : start,
    end: wizardState ? new Date(wizardState?.end) : end,
    rewards: wizardState
      ? wizardState.rewards.map((r) => ({ symbol: r.symbol, value: r.value, address: r.address }))
      : [{ symbol: "", value: "", address: "" }],
    stakeTokenAddress: wizardState ? wizardState.stakeTokenAddress : ""
  };
};

export type RewardForm = SearchToken & {
  value: string;
};

export const CreateCampaignCtx = createContext<CreateCampaignContext>({
  values: initCreateCampaignForm(),
  setValues: (f: CampaignWizardForm) => null,
  nextStep: () => null,
  previousStep: () => null,
  campaignAddress: "",
  setCampaignAddress: (s) => null,
  wizardType: "create",
  step: 0,
  ind: { message: "", level: "warning" },
  sentRewards: {},
  setSentRewards: () => null
});

export interface CampaignDetailsContext {
  disableButtons: boolean;
  setDisableButtons: (disable: boolean) => void;
}

export const CampaignDetailsCtx = createContext<CampaignDetailsContext>({
  disableButtons: false,
  setDisableButtons: () => null
});

export const useCampaignDetailsCtx = () => useContext(CampaignDetailsCtx);
