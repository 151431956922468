import { useTheme, Button } from '@tryrolljs/design-system'
import { Indicator } from '../../atoms/indicator'
import { validateCampaignDuration } from '../../core'
import { useCreateCampaignCtx, useSetDuration } from '../../hooks'
import { DateTimeInput } from '../../molecules/dateTimeInput'
import { Activity } from '../../atoms/activity'

type Props = {
  onSuccess: () => void
  contractAddress: string
}

export const SetCampaignDuration = ({ onSuccess, contractAddress }: Props) => {
  const { values, setValues } = useCreateCampaignCtx()
  const theme = useTheme()
  const [setDuration, setDurationState] = useSetDuration()

  const execute = async () => {
    try {
      const err = validateCampaignDuration(values.start, values.end)
      if (err) {
        setDurationState.setInd(err.message, err.level)
        return
      }

      await setDuration(contractAddress, values.start, values.end)
      onSuccess()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className="p-4" style={{ backgroundColor: theme.background.page }}>
      <div className="mb-6">
        <DateTimeInput
          disable={true}
          value={values.start}
          setValue={(start) => setValues({ ...values, start })}
        />
        <DateTimeInput
          disable={true}
          value={values.end}
          setValue={(end) => setValues({ ...values, end })}
        />
      </div>
      <div className="flex justify-center mb-6">
        {setDurationState.ind.message && (
          <Indicator
            message={setDurationState.ind.message}
            level={setDurationState.ind.level}
          />
        )}
      </div>
      {setDurationState.activity ? (
        <Activity />
      ) : (
        <Button variant="primary" title="Set Duration" onPress={execute} />
      )}
    </div>
  )
}
