import {
  useTheme,
  SubHeader,
  Body,
  Button,
  Surface,
  margin,
  container,
} from '@tryrolljs/design-system'
import StyledLink from '../../atoms/styledLink'
import { FAQ_URL } from '../../constants'

type Props = {
  onPress: () => void
}

export const CreateStakingRewardBanner = ({ onPress }: Props) => {
  const theme = useTheme()

  return (
    <Surface style={[margin.mb16, container.shadowXL]}>
      <div
        className="flex justify-between rounded p-4"
        style={{ backgroundColor: theme.background.primary }}
      >
        <div className="flex flex-col justify-between">
          <SubHeader weight="bold">Staking Rewards</SubHeader>
          <Body>
            Increase liquidity and grow your community by offering staking
            rewards. <StyledLink title="Learn more." url={FAQ_URL} />
          </Body>
        </div>
        <Button
          variant="primary"
          title="Create Staking Rewards"
          onPress={onPress}
        />
      </div>
    </Surface>
  )
}
