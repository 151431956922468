import { useEffect, useMemo, useState } from 'react'
import { Body, Caption, margin, useTheme } from '@tryrolljs/design-system'
import { Indicator } from '../../atoms/indicator'
import Checkmark from '../../assets/svg/check.svg'
import { useCreateCampaignCtx } from '../../hooks'
import WarningIcon from '../../assets/svg/warning.svg'
import { LaunchStep } from './steps'
import { useLaunchManagerCtx } from './common'

export const MAX_STEPS = 4
export const STEP_DEPLOY = 1
export const STEP_SET_DURATION = 2
export const STEP_DEPOSIT_REWARDS = 3
export const STEP_NOTIFY = 4

export type MapSuccessSteps = {
  [key: number]: boolean
}

type LaunchProps = {
  steps: LaunchStep[]
}

export const FormLaunchStepsPresenter = ({ steps }: LaunchProps) => {
  const theme = useTheme()
  return (
    <div>
      <div className="flex mb-4">
        <WarningIcon />
        <Caption style={margin.ml8} color={theme.text.secondary}>
          Please do not exit this window until all the steps are complete
        </Caption>
      </div>
      <div className="rounded-lg" style={{ width: 400, borderWidth: 1 }}>
        {steps.map(({ Component }, idx) => (
          <Component key={idx} idx={idx} />
        ))}
      </div>
    </div>
  )
}

type Props = {
  // contractAddress?: string;
  idx: number
}

export const ControlNullState: React.FC<Props> = ({ children, idx }) => {
  const { campaignAddress } = useCreateCampaignCtx()
  const { successSteps } = useLaunchManagerCtx()

  // must complete all previous steps
  const completedSteps = useMemo(() => {
    if (idx > 0) {
      for (let i = 0; i < idx; i++) {
        if (!successSteps[i]) return false
      }
    }
    return true
  }, [successSteps, idx])

  if (!completedSteps) {
    return (
      <div className="flex justify-center mb-6">
        <Indicator
          level="warning"
          message="Please complete the previous step"
        />
      </div>
    )
  }

  if (!campaignAddress) {
    return (
      <div className="flex justify-center mb-6">
        <Indicator level="warning" message="Contract has not been deployed" />
      </div>
    )
  }
  return <>{children}</>
}

type AccordionProps = {
  header: JSX.Element
  content: JSX.Element
  onOpen?: () => void
  onClose?: () => void
  containerStyle?: React.CSSProperties
  open?: boolean
}

export const Accordion = ({
  header,
  content,
  onOpen,
  onClose,
  containerStyle,
  open,
}: AccordionProps) => {
  const [_open, setOpen] = useState(false)

  const _setOpen = (val: boolean) => {
    setOpen(val)
    if (val) {
      onOpen && onOpen()
    } else {
      onClose && onClose()
    }
  }

  useEffect(() => {
    if (open !== undefined && open !== null && open !== _open) setOpen(open)
  }, [open, _open, setOpen])

  return (
    <div style={containerStyle}>
      <div className="cursor-pointer" onClick={() => _setOpen(!open)}>
        {header}
      </div>
      {_open && <div>{content}</div>}
    </div>
  )
}

type DeployStepProps = {
  num: number
  title: string
  success?: boolean
}

export const DeployStepHeader = ({ num, title, success }: DeployStepProps) => {
  return (
    <div className="flex flex-row items-center p-4" style={{ width: 400 }}>
      {success ? (
        <div data-testid="deploy-step-success">
          <Checkmark />
        </div>
      ) : (
        <div
          style={{ borderWidth: 2, height: 32, width: 32 }}
          className="flex rounded-full justify-center items-center"
        >
          <Body>{num}</Body>
        </div>
      )}
      <Body style={margin.ml16}>{title}</Body>
    </div>
  )
}
