import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import {
  IndicatorLevel,
  IndicatorProps,
  useIndicator,
} from '../atoms/indicator'
import { ErrorMessageOptions, EthProviderError, parseEthError } from '../error'
import { Action } from '../state/common'
import packageJson from '../../package.json'
import { semverGreaterThan } from '../util'
import { DEFAULT_ERROR_MESSAGE } from '../constants'
import { useBalanceCtx } from './balance'
import { useCampaignCtx, useTokenCtx } from '.'

type PromiseFunc<T> = () => Promise<T>
type ErrorFunc = (err: Error | EthProviderError) => string

export type AsyncState = {
  activity: boolean
  setActivity: (activity: boolean) => void
  ind: IndicatorProps
  setInd: (message: string, level: IndicatorLevel) => void
  exec: <T>(cb: () => Promise<T>) => Promise<T>
  managedExec: <T>(
    cb: PromiseFunc<T>,
    parseError?: ErrorFunc,
  ) => Promise<T | undefined>
  hasRan: MutableRefObject<boolean>
}

export function ethErrorHandler(
  o: ErrorMessageOptions,
  fallback: string,
): ErrorFunc {
  return (e) =>
    parseEthError(e as EthProviderError, o, {
      message: fallback,
      level: 'error',
    })[0]
}

export const useAsync = (): AsyncState => {
  const [activity, _setActivity] = useState(false)
  const [ind, _setInd] = useIndicator()
  const hasRan = useRef<boolean>(false)

  const setActivity = useRef((a: boolean) => _setActivity(a))
  const setInd = useRef((m: string, l: IndicatorLevel) => _setInd(m, l))

  const exec = useRef(<T>(cb: () => Promise<T>) => {
    _setInd('', 'error')
    _setActivity(true)
    return cb()
  })

  const managedExec = useRef(
    async <T>(cb: PromiseFunc<T>, parseError?: ErrorFunc) => {
      try {
        const res = await exec.current(cb)
        setActivity.current(false)
        return res
      } catch (err) {
        if (err && parseError) {
          setInd.current(parseError(err as Error | EthProviderError), 'error')
        } else {
          setInd.current(DEFAULT_ERROR_MESSAGE, 'error')
        }

        setActivity.current(false)
        // rethrow
        throw err
      }
    },
  )

  return useMemo(() => {
    return {
      activity,
      setActivity: setActivity.current,
      ind,
      setInd: setInd.current,
      exec: exec.current,
      managedExec: managedExec.current,
      hasRan,
    }
  }, [activity, ind])
}

export const useGlobalDispatch = () => {
  const balanceDispatch = useBalanceCtx().dispatch
  const tokenDispatch = useTokenCtx().dispatch
  const campaignDispatch = useCampaignCtx().dispatch

  return (action: Action<any>) => {
    balanceDispatch(action)
    tokenDispatch(action)
    campaignDispatch(action)
  }
}

export function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export const useClearError = (asyncState: AsyncState, milliSeconds: number) => {
  useEffect(() => {
    if (asyncState.ind.message) {
      setTimeout(() => asyncState.setInd('', 'warning'), milliSeconds)
    }
  }, [asyncState, milliSeconds])
}

const KEY_LAST_RELOAD = 'KEY_LAST_RELOAD'

const forceReload = () => {
  window.localStorage.setItem(KEY_LAST_RELOAD, new Date().getTime().toString())
  window.location.reload()
}

const getAppVersion = async () => {
  try {
    const r = await fetch('/meta.json')
    const meta = await r.json()
    if (meta.version) {
      console.log(
        `Welcome to Roll Staking! You are interacting with version ${packageJson.version} latest version is ${meta.version}`,
      )

      const mustReload = semverGreaterThan(meta.version, packageJson.version)
      const lastReloadUnix = window.localStorage.getItem(KEY_LAST_RELOAD)

      if (mustReload) {
        // force reload if there is no record of last load
        if (!lastReloadUnix) {
          forceReload()
        } else {
          const unixNum = Number(lastReloadUnix)
          // reset key and reload if it is invalid
          if (isNaN(unixNum)) {
            window.localStorage.removeItem(KEY_LAST_RELOAD)
            forceReload()
            // force reload if last attempt was more than 3 seconds ago
          } else if (new Date().getTime() - unixNum > 3000) {
            forceReload()
          }
        }
      } else {
        window.localStorage.removeItem(KEY_LAST_RELOAD)
      }
    }
  } catch (err) {
    console.error(err)
  }
}

export const useAppVersion = () => {
  useEffect(() => {
    getAppVersion()
  }, [])
}
