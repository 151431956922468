import { Signer } from '@ethersproject/abstract-signer'
import { Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import { ContractTransaction } from '@ethersproject/contracts'
import { StakingV2 } from '@tryrolljs/contract-bindings'
import { appendStrIdx } from '../../util'
import { TokenState } from '../tokens/reducer'
import {
  buildCampaignData,
  DeployCampaignForm,
  deployStakingContract,
  depositReward,
  getCampaignData,
  getCampaigns,
  getCampaignsByOwner,
  GetCampaignsProps,
  GetCampaingsByOwnerProps,
  getUserRewards,
  Reward,
  setDuration,
  TokenGetter,
} from '../../contracts'
import {
  Campaign,
  evaluateCampaignStatus,
  setCampaignDuration,
  setCampaignTokenReward,
} from '../../core'
import { Action } from '../common'
import {
  ActionSaveAmountStaked,
  ActionSetCampaignActivity,
  SAVE_CAMPAIGNS,
  SAVE_USER_REWARDS,
  SET_AMOUNT_STAKED,
  SET_CAMPAIGN_ACTIVITY,
  UPDATE_CAMPAIGN,
} from './types'
import { CampaignState, initCampaignState } from './reducer'

export const actionSetCampaignActivity = (
  address: string,
  activity: boolean,
): Action<ActionSetCampaignActivity> => ({
  type: SET_CAMPAIGN_ACTIVITY,
  payload: { address, activity },
})

export const actionGetCampaign = async (
  address: string,
  tokenGetter: TokenGetter,
  provider: Provider | Signer,
) => {
  const campaign = await getCampaignData(address, tokenGetter, provider)
  return actionSaveCampaigns(normalizeCampaigns([campaign]))
}

export const actionGetCampaigns = async (
  props: GetCampaignsProps,
): Promise<Action<CampaignState>> => {
  const campaigns = await getCampaigns(props)
  return actionSaveCampaigns(normalizeCampaigns(campaigns))
}

const actionSaveCampaigns = (
  campaigns: CampaignState,
): Action<CampaignState> => ({
  type: SAVE_CAMPAIGNS,
  payload: campaigns,
})

export const actionGetOwnerCampaigns = async (
  props: GetCampaingsByOwnerProps,
): Promise<Action<CampaignState>> => {
  const campaigns = await getCampaignsByOwner(props)
  return actionSaveCampaigns(normalizeCampaigns(campaigns))
}

export type NormCampaigns = {
  campaigns: CampaignState
  tokens: TokenState
}

const normalizeCampaigns = (campaigns: Campaign[]): CampaignState =>
  campaigns.reduce(normalizeCampaign, initCampaignState())

export const normalizeCampaign = (
  acc: CampaignState,
  curr: Campaign,
): CampaignState => {
  acc.addresses.push(curr.address)
  acc.byAddress[curr.address] = curr
  appendStrIdx(acc.indexByOwner, curr.owner, curr.address)
  return acc
}

export const actionDeployCampaign = async (
  form: DeployCampaignForm,
  tokenGetter: TokenGetter,
  provider: Provider | Signer,
  factory: StakingV2.RollStakingFactory,
) => {
  const stakingCampaign = await deployStakingContract(
    form.rewards.map((r) => r.address),
    form.stakeTokenAddress,
    provider,
    factory,
  )
  const campaign = await buildCampaignData(stakingCampaign, tokenGetter)
  return actionSaveCampaigns(normalizeCampaigns([campaign]))
}

export const actionDepositRewards = async (
  tokenAddr: string,
  value: BigNumber,
  campaign: Campaign,
  provider: Provider | Signer,
): Promise<[Action<Campaign>, ContractTransaction]> => {
  const tx = await depositReward(tokenAddr, value, campaign.address, provider)
  const _campaign = { ...campaign }
  setCampaignTokenReward(_campaign, tokenAddr, value)
  return [actionUpdateCampaign(campaign), tx]
}

export const actionUpdateCampaign = (campaign: Campaign): Action<Campaign> => ({
  type: UPDATE_CAMPAIGN,
  payload: campaign,
})

// TODO can remove?
export const actionUpdateStatus = (campaign: Campaign, _provider: Provider) => {
  const status = evaluateCampaignStatus(campaign)
  const _campaign = { ...campaign, status: status }
  return actionUpdateCampaign(_campaign)
}

export type ActionSaveRewards = {
  userAddr: string
  campaignAddr: string
  rewards: Reward[]
}

export const actionSaveUserRewards = (
  userAddr: string,
  campaignAddr: string,
  rewards: Reward[],
): Action<ActionSaveRewards> => ({
  type: SAVE_USER_REWARDS,
  payload: { userAddr, campaignAddr, rewards },
})

export const actionGetUserRewards = async (
  userAddr: string,
  campaign: Campaign,
  provider: Provider | Signer,
): Promise<Action<ActionSaveRewards>> => {
  const rewards = await getUserRewards(userAddr, campaign, provider)
  return actionSaveUserRewards(userAddr, campaign.address, rewards)
}

export const actionSetAmountStaked = (
  campaign: string,
  owner: string,
  token: string,
  value: BigNumber,
): Action<ActionSaveAmountStaked> => ({
  type: SET_AMOUNT_STAKED,
  payload: { campaign, owner, token, value },
})

export const actionSetDuration = async (
  campaign: Campaign,
  start: Date,
  end: Date,
  signer: Signer,
): Promise<[Action<Campaign>, ContractTransaction]> => {
  const tx = await setDuration(campaign.address, start, end, signer)
  const _campaign = { ...campaign }
  setCampaignDuration(_campaign, start, end)
  return [actionUpdateCampaign(_campaign), tx]
}
