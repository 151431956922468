import { Body, Button, margin } from '@tryrolljs/design-system'
import Clock from '../../assets/svg/clock.svg'
import { SuggestedDuration, useCreateCampaignCtx } from '../../hooks'
import {
  MILLISECONDS_HOUR,
  MILLISECONDS_MINUTE,
  MILLISECONDS_SECOND,
} from '../../util'
import { CampaignWizardForm } from '../../context/campaign'
import { defaultCampaignDuration } from '../../core'
import { LeftoverRewards } from './leftoverRewards'
import { DurationComparison } from './durationComparison'

export const buildStr = (hours: number, minutes: number, seconds: number) => {
  let str = ''

  if (hours) {
    str = `${hours} hour(s)`
  }

  if (minutes) {
    const base = `${minutes} minute(s)`
    if (hours && seconds) {
      str = `${str}, ${base}`
    } else if (hours) {
      str = `${str} and ${base}`
    } else {
      str = base
    }
  }

  if (seconds) {
    const base = `${seconds} second(s)`
    str = hours || minutes ? `${str} and ${base}` : base
  }
  return str
}

export const buildDurationText = (diff: number): string => {
  let hours = 0
  let minutes = 0
  let seconds = 0

  hours = Math.floor(diff / MILLISECONDS_HOUR)
  let remainder = diff - hours * MILLISECONDS_HOUR

  if (remainder) {
    minutes = Math.floor(remainder / MILLISECONDS_MINUTE)

    remainder = remainder - minutes * MILLISECONDS_MINUTE

    if (remainder) {
      seconds = Math.floor(remainder / MILLISECONDS_SECOND)
    }
  }

  return buildStr(hours, minutes, seconds)
}

export const buildDiffText = (
  values: CampaignWizardForm,
): [boolean, string] => {
  if (values.suggestedDuration) {
    const diff = values.suggestedDuration.end.getTime() - values.end.getTime()
    if (diff > 0) {
      return [true, buildDurationText(Math.abs(diff))]
    } else {
      return [false, buildDurationText(Math.abs(diff))]
    }
  }

  return [false, 'no diff']
}
const [defaultStart, defaultEnd] = defaultCampaignDuration()

const hasNotAdjusted = (suggestedDuration: SuggestedDuration | null) => {
  return (
    suggestedDuration &&
    !suggestedDuration.diffSeconds &&
    suggestedDuration.hasRemainder
  )
}

export const NotifyAdjustDuration = () => {
  const { values } = useCreateCampaignCtx()
  const { suggestedDuration } = values
  const [increased, msg] = buildDiffText(values)

  if (hasNotAdjusted(suggestedDuration)) {
    return (
      <div>
        <div className="flex flex-row items-center mb-8">
          <div className="mr-2 pt-1" style={{ height: 24, width: 24 }}>
            <Clock />
          </div>
          <Body>
            You will have leftover reward tokens by the end of the contract
          </Body>
        </div>
        <div>
          <LeftoverRewards
            onlyGtZero={true}
            withFAQ={true}
            start={suggestedDuration?.start || defaultStart}
            end={suggestedDuration?.end || defaultEnd}
          />
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="flex flex-row">
        <div className="mr-2 pt-1" style={{ height: 24, width: 24 }}>
          <Clock />
        </div>
        <Body>
          We {increased ? 'increased' : 'decreased'} your contract duration by{' '}
          {msg} so there will not be any leftover reward tokens by the end of
          the contract.
        </Body>
      </div>
      <div className="flex w-full justify-between px-6 mt-4 mb-12">
        <DurationComparison
          title="Original Timeframe (EST)"
          withFAQ={false}
          start={values.start}
          end={values.end}
        />
        <DurationComparison
          title="New Timeframe (EST)"
          withFAQ={true}
          start={suggestedDuration?.start || defaultStart}
          end={suggestedDuration?.end || defaultEnd}
          right
        />
      </div>
    </div>
  )
}

export const NotifyAdjustDurationButtons = () => {
  const { values, nextStep, previousStep, setValues } = useCreateCampaignCtx()

  if (hasNotAdjusted(values.suggestedDuration)) {
    return (
      <div className="flex flex-row">
        <Button
          style={margin.mr8}
          title="Keep"
          variant="secondary"
          onPress={nextStep}
        />
        <Button
          title="Adjust rewards"
          variant="primary"
          onPress={previousStep}
        />
      </div>
    )
  }

  const onAccept = () => {
    if (values.suggestedDuration) {
      setValues({
        ...values,
        start: values.suggestedDuration.start,
        end: values.suggestedDuration.end,
      })
      nextStep()
    }
  }
  return (
    <div className="flex flex-row">
      <Button
        style={margin.mr8}
        title="Keep"
        variant="secondary"
        onPress={nextStep}
      />
      <Button title="Accept Change" variant="primary" onPress={onAccept} />
    </div>
  )
}
