import { BigNumber } from "ethers";
import { Token } from "../../core";
import { Action, CLEAR_STATE } from "../common";
import { ActionSetApprovedToken, SAVE_TOKEN, SAVE_TOKENS, SET_APPROVED_TOKEN } from "./types";

type ByAddress = { [key: string]: Token };

export type TokenState = {
  byAddress: ByAddress;
  approved: { [token: string]: { [spender: string]: BigNumber } };
};

export const initTokenState = (): TokenState => ({
  byAddress: {},
  approved: {}
});

type TokenReducerHandler = (state: TokenState, action: Action<any>) => TokenState;

const preserveToken = (current: Token | undefined, incoming: Token): Token => {
  if (!current) return incoming;
  if (!current.logoURI && incoming.logoURI) return incoming;
  return current;
};

export const saveTokens: TokenReducerHandler = (state, action: Action<TokenState>) => {
  if (!action.payload) return state;
  return {
    ...state,
    byAddress: Object.keys(action.payload.byAddress).reduce(
      (acc: ByAddress, curr: string) => {
        acc[curr.toLowerCase()] = preserveToken(
          state.byAddress[curr],
          action.payload.byAddress[curr]
        );
        return acc;
      },
      { ...state.byAddress }
    )
  };
};

const saveToken: TokenReducerHandler = (state, action: Action<Token>) => {
  const lowerAddr = action.payload.address.toLowerCase();
  return {
    ...state,
    byAddress: {
      ...state.byAddress,
      [lowerAddr]: preserveToken(state.byAddress[lowerAddr], action.payload)
    }
  };
};

const setApprovedToken: TokenReducerHandler = (state, action: Action<ActionSetApprovedToken>) => {
  return {
    ...state,
    approved: {
      ...state.approved,
      [action.payload.tokenAddress]: {
        ...state.approved[action.payload.tokenAddress],
        [action.payload.spenderAddress]: action.payload.approved
      }
    }
  };
};

const handlers: { [key: string]: TokenReducerHandler } = {
  [SAVE_TOKENS]: saveTokens,
  [SAVE_TOKEN]: saveToken,
  [CLEAR_STATE]: initTokenState,
  [SET_APPROVED_TOKEN]: setApprovedToken
};

export const tokenReducer = (state = initTokenState(), action: Action<any>) => {
  return handlers[action.type] ? handlers[action.type](state, action) : state;
};
