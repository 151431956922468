import { useCallback, useEffect, useRef } from "react";
import { Activity } from "../../atoms/activity";
import { useTokenCtx } from "../../hooks";
import { extendWizardSteps, useDeriveForm } from "./common";
import { useCampaignWizardManager } from "./manager";

type Props = {
  campaignAddress: string;
};

export const ExtendCampaignManager = ({ campaignAddress }: Props) => {
  const { state } = useTokenCtx();
  const [deriveForm, asyncState] = useDeriveForm(campaignAddress);
  const hasDerivedForm = useRef(false);
  const { render, setValues } = useCampaignWizardManager(
    extendWizardSteps,
    campaignAddress,
    "extend"
  );

  const initState = useCallback(async () => {
    if (!hasDerivedForm.current) {
      hasDerivedForm.current = true;
      const res = await deriveForm(state);
      if (res) {
        setValues(res.form);
      }
    }
  }, [setValues, state, deriveForm]);

  useEffect(() => {
    initState();
  }, [initState]);

  return asyncState.activity ? (
    <div className="p-20">
      <Activity />
    </div>
  ) : (
    render()
  );
};
