import { useCallback, useEffect, useRef, useState } from 'react'
import { useTheme, Button, margin, Body, Input } from '@tryrolljs/design-system'
import CloseIcon from '../../assets/svg/close.svg'
import {
  useCampaign,
  useDebounce,
  useEthAddress,
  useGetMaxRewardTokens,
  useGetTokenBalance,
  useModal,
  useSigner,
  useTokenByAddress,
  useCheckIsTokenApproved,
  useTokenAllowance,
  useApproveToken,
} from '../../hooks'
import { displayAmount, parseValue } from '../../util'
import { stake, Reward } from '../../contracts'
import { Indicator, useIndicator } from '../../atoms/indicator'
import { Activity } from '../../atoms/activity'
import { useTokenBalance } from '../../hooks/balance'
import { MaxRewardPotential } from '../maxRewardPotential'
import {
  defaultEthErrorMessages,
  EthProviderError,
  parseEthError,
} from '../../error'

type Props = {
  address: string
  onSucces: () => void
}

export const FormStakeToken = ({ address, onSucces }: Props) => {
  const [amount, setAmount] = useState<string>('')
  const [maxRewardTokens, setMaxRewardTokens] = useState<Reward[]>([])
  const campaign = useCampaign(address)
  const token = useTokenByAddress(campaign.tokenAddress)
  const tokenAllowance = useTokenAllowance(campaign.tokenAddress, address)
  const hasCalled = useRef(false)
  const getBal = useGetTokenBalance()
  const getMaxRewardTokens = useGetMaxRewardTokens(address)
  const theme = useTheme()
  const modal = useModal()
  const signer = useSigner()
  const [activity, setActivity] = useState<boolean>(false)
  const [ind, setInd] = useIndicator()
  const userAddress = useEthAddress()
  const bal = useTokenBalance(userAddress || '', campaign.tokenAddress)
  const amountAvailable = displayAmount(bal, token.decimals)
  const debouncedAmount: string = useDebounce(amount, 500)
  const [checkIsApproved, checkIsApprovedAsyncState] = useCheckIsTokenApproved()
  const [approveToken, approveAsyncState] = useApproveToken()

  const _stake = useCallback(async () => {
    try {
      if (!signer || !amount || isNaN(Number(amount))) return
      setInd('', 'warning')
      setActivity(true)
      const tx = await stake(
        parseValue(amount, token.decimals),
        campaign.address,
        signer,
      )
      await tx.wait()
      onSucces()
      modal.setOpen(false)
    } catch (err) {
      setInd(
        ...parseEthError(err as EthProviderError, defaultEthErrorMessages, {
          message: 'Unable to stake tokens',
          level: 'error',
        }),
      )
    } finally {
      setActivity(false)
    }
  }, [
    signer,
    amount,
    token.decimals,
    setActivity,
    setInd,
    campaign.address,
    modal,
    onSucces,
  ])

  const _approve = useCallback(async () => {
    approveToken(
      campaign.address,
      campaign.tokenAddress,
      parseValue(amount, token.decimals),
    )
  }, [
    approveToken,
    amount,
    token.decimals,
    campaign.address,
    campaign.tokenAddress,
  ])

  const _getBal = useCallback(async () => {
    if (hasCalled.current || !userAddress) return
    hasCalled.current = true
    await getBal(campaign.tokenAddress, userAddress)
  }, [getBal, campaign.tokenAddress, userAddress])

  const _getMaxRewardToken = useCallback(async () => {
    const debouncedMaxRewardTokens = await getMaxRewardTokens(
      parseValue(debouncedAmount, token.decimals),
    )
    setMaxRewardTokens(debouncedMaxRewardTokens || [])
  }, [debouncedAmount, getMaxRewardTokens, token.decimals])

  const showApprove =
    !tokenAllowance || parseValue(amount, token.decimals).gt(tokenAllowance)

  useEffect(() => {
    checkIsApproved(campaign.address, campaign.tokenAddress)
  }, [campaign.address, campaign.tokenAddress, checkIsApproved])

  useEffect(() => {
    _getBal()
  }, [_getBal])

  useEffect(() => {
    _getMaxRewardToken()
  }, [debouncedAmount, _getMaxRewardToken])

  useEffect(() => {
    const parsedAmount = parseValue(amount, token.decimals)
    const hasBalance = bal.gt(parsedAmount)
    if (!hasBalance) {
      setInd('You do not have enough tokens to stake', 'error')
    } else {
      setInd('', 'warning')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, bal, token.decimals])

  const _setAmount = (val: string) => !isNaN(Number(val)) && setAmount(val)

  const isLoading =
    approveAsyncState.activity || checkIsApprovedAsyncState.activity || activity

  return (
    // eslint-disable-next-line react-native/no-inline-styles
    <div className="p-2" style={{ width: 480 }}>
      <div className="flex items-center justify-between">
        <Body weight="bold">Deposit {token.symbol}</Body>
        <div className="cursor-pointer" onClick={() => modal.setOpen(false)}>
          <CloseIcon />
        </div>
      </div>
      <div className="mb-4">
        <Input
          placeholder="Amount"
          style={margin.mt24}
          value={amount}
          onChangeText={_setAmount}
        />
        <Body>
          Amount available: {amountAvailable} {token.symbol}{' '}
          <Body
            onPress={() => _setAmount(amountAvailable)}
            color={theme.text.highlight}
          >
            (Max)
          </Body>
        </Body>
        <MaxRewardPotential maxRewardTokens={maxRewardTokens} />
      </div>
      {ind.message && <Indicator message={ind.message} level={ind.level} />}
      <div className="flex flex-row space-x-5 mt-4 justify-end">
        {isLoading ? (
          <Activity />
        ) : (
          <>
            <Button
              variant="secondary"
              // eslint-disable-next-line react-native/no-inline-styles
              style={[margin.mv16, { width: 50 }]}
              title="Cancel"
              onPress={() => modal.setOpen(false)}
            />
            <Button
              variant="primary"
              // eslint-disable-next-line react-native/no-inline-styles
              style={[margin.mv16, { width: 50 }]}
              title={showApprove ? 'Approve' : 'Stake'}
              onPress={showApprove ? _approve : _stake}
              disabled={!amount}
            />
          </>
        )}
      </div>
    </div>
  )
}
