import { BigNumber } from "@ethersproject/bignumber";
import { Reward } from "../../contracts";
import { Campaign } from "../../core";
import { mergeStrArrays, mergeStrIndex, StrIdx } from "../../util";
import { Action, CLEAR_STATE } from "../common";
import { ActionSaveRewards } from "./actions";
import {
  ActionSaveAmountStaked,
  ActionSetCampaignActivity,
  SAVE_CAMPAIGNS,
  SAVE_USER_REWARDS,
  SET_AMOUNT_STAKED,
  SET_CAMPAIGN_ACTIVITY,
  UPDATE_CAMPAIGN
} from "./types";

type CampaignValueByOwner = {
  [key: string]: {
    // campaign addr
    [key: string]: {
      // user addr
      [key: string]: BigNumber; // token addr -> value
    };
  };
};

export type CampaignState = {
  addresses: string[];
  byAddress: { [key: string]: Campaign };
  indexByOwner: StrIdx;
  userRewards: CampaignValueByOwner;
  stakedValue: CampaignValueByOwner;
  asyncActivity: {
    [key: string]: boolean;
  };
};

export const initCampaignState = (): CampaignState => ({
  addresses: [],
  byAddress: {},
  userRewards: {},
  stakedValue: {},
  indexByOwner: {},
  asyncActivity: {}
});

type CampaignReducerHandler = (state: CampaignState, action: Action<any>) => CampaignState;

const saveCampaigns: CampaignReducerHandler = (state, action: Action<CampaignState>) => {
  return {
    ...state,
    addresses: mergeStrArrays(state.addresses, action.payload.addresses),
    indexByOwner: mergeStrIndex(state.indexByOwner, action.payload.indexByOwner),
    byAddress: {
      ...state.byAddress,
      ...action.payload.byAddress
    }
  };
};

const updateCampaign: CampaignReducerHandler = (state, action: Action<Campaign>) => {
  return {
    ...state,
    byAddress: {
      ...state.byAddress,
      [action.payload.address]: {
        ...action.payload
      }
    }
  };
};

export const setCampaignOwnerValue = (
  m: CampaignValueByOwner,
  campaignAddr: string,
  ownerAddr: string,
  tokenAddr: string,
  value: BigNumber
): CampaignValueByOwner => {
  const cp = { ...m, [campaignAddr]: { ...m[campaignAddr] } };
  const byOwner = cp[campaignAddr][ownerAddr];

  // copy values by owner if they exist
  cp[campaignAddr][ownerAddr] = byOwner
    ? { ...byOwner, [tokenAddr]: value }
    : { [tokenAddr]: value };

  return cp;
};

export const saveAmountStakedByOwner: CampaignReducerHandler = (
  state,
  action: Action<ActionSaveAmountStaked>
) => {
  return {
    ...state,
    stakedValue: setCampaignOwnerValue(
      state.stakedValue,
      action.payload.campaign,
      action.payload.owner,
      action.payload.token,
      action.payload.value
    )
  };
};

const saveUserRewards: CampaignReducerHandler = (state, action: Action<ActionSaveRewards>) => {
  return {
    ...state,
    userRewards: {
      // maintian existing reecords of rewards
      ...state.userRewards,
      // update records of rewards for this campaign
      [action.payload.campaignAddr]: {
        // maintain records of rewards for this campaign
        ...state.userRewards[action.payload.campaignAddr],
        // update this users rewards record, map token addr -> reward value
        // allows constant time lookup from selectors
        [action.payload.userAddr]: action.payload.rewards.reduce(
          (acc: { [key: string]: BigNumber }, curr: Reward) => {
            acc[curr.token] = curr.amount;
            return acc;
          },
          {}
        )
      }
    }
  };
};

const setCampaignActivity: CampaignReducerHandler = (
  state,
  action: Action<ActionSetCampaignActivity>
) => {
  return {
    ...state,
    asyncActivity: {
      ...state.asyncActivity,
      [action.payload.address]: action.payload.activity
    }
  };
};

const handlers: { [key: string]: CampaignReducerHandler } = {
  [SAVE_CAMPAIGNS]: saveCampaigns,
  [UPDATE_CAMPAIGN]: updateCampaign,
  [SAVE_USER_REWARDS]: saveUserRewards,
  [CLEAR_STATE]: initCampaignState,
  [SET_CAMPAIGN_ACTIVITY]: setCampaignActivity,
  [SET_AMOUNT_STAKED]: saveAmountStakedByOwner
};

export const campaignReducer = (
  state = initCampaignState(),
  action: Action<any>
): CampaignState => {
  return handlers[action.type] ? handlers[action.type](state, action) : state;
};
