import { useState } from 'react'
import { Body, margin, useTheme, Theme } from '@tryrolljs/design-system'
import ErrorIcon from '../../assets/svg/error.svg'
import WarningIcon from '../../assets/svg/warning.svg'

export type IndicatorLevel = 'warning' | 'error' | 'info'

export type IndicatorProps = {
  message: string
  level: IndicatorLevel
  style?: React.CSSProperties
  suffix?: React.ReactElement
  className?: string
}

const levelColor = (level: IndicatorLevel, theme: Theme): string => {
  switch (level) {
    case 'error':
      return theme.text.error
    case 'warning':
      return theme.text.warning
    case 'info':
      return theme.text.secondary
    default:
      return theme.text.error
  }
}

const levelIcon = (level: IndicatorLevel, theme: Theme): React.ReactElement => {
  switch (level) {
    case 'error':
      // @ts-ignore
      return <ErrorIcon fill={theme.text.error} />
    case 'warning':
      // @ts-ignore
      return <WarningIcon fill={theme.text.warning} />
    case 'info':
      return <ErrorIcon />
    default:
      return <ErrorIcon />
  }
}

export const Indicator = ({
  message,
  level,
  style,
  suffix,
  className,
}: IndicatorProps) => {
  const theme = useTheme()

  return (
    <div className={`flex flex-row items-center ${className}`} style={style}>
      {levelIcon(level, theme)}
      <Body color={levelColor(level, theme)} style={margin.ml8}>
        {message}
      </Body>
      {suffix}
    </div>
  )
}

export const useIndicator = (): [
  IndicatorProps,
  (message: string, level: IndicatorLevel) => void,
] => {
  const [state, setState] = useState<IndicatorProps>({
    message: '',
    level: 'warning',
  })
  return [
    state,
    (message: string, level: IndicatorLevel) => setState({ message, level }),
  ]
}
