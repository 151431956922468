import { Body, margin, useTheme, Button } from '@tryrolljs/design-system'
import { BigNumber } from 'ethers'
import { useMemo } from 'react'
import { useCreateCampaignCtx, useTokenCtx } from '../../hooks'
import Clock from '../../assets/svg/clock.svg'
import { StakingRewardToken } from '../../core'
import { TokenValue } from '../../molecules/tokenValue'
import { displayAmount, durationSeconds, parseValue } from '../../util'
import { selectTokenByAddress } from '../../state/tokens/selectors'

export const NotifyAdjustValue = () => {
  const { state } = useTokenCtx()
  const { values } = useCreateCampaignCtx()
  const { suggestValues, rewards } = values

  const duration = BigNumber.from(durationSeconds(values.start, values.end))

  const [ogRewards, newRewards] = useMemo<
    [StakingRewardToken[], StakingRewardToken[]]
  >(() => {
    if (!suggestValues || !rewards || rewards.length !== suggestValues.length)
      return [[], []]

    return rewards.reduce(
      (acc: [StakingRewardToken[], StakingRewardToken[]], curr, idx) => {
        const { address, decimals } = selectTokenByAddress(state, curr.address)
        const ogReward: StakingRewardToken = {
          address,
          value: parseValue(curr.value, decimals),
        }
        const newReward: StakingRewardToken = {
          address,
          value: suggestValues[idx],
        }
        acc[0].push(ogReward)
        acc[1].push(newReward)
        return acc
      },
      [[], []],
    )
  }, [state, suggestValues, rewards])

  return (
    <div>
      <div className="flex flex-row mb-8">
        <div className="mr-2 pt-1" style={{ height: 24, width: 24 }}>
          <Clock />
        </div>
        <Body>
          We recommend adjusting total token rewards to the suggested value to
          minimize leftover rewards by the end of the contract. You can choose
          to keep the original value and withdraw any leftover tokens after the
          campaign ends
        </Body>
      </div>
      <div className="flex flex-row">
        <ValueComparison
          title={'Original'}
          rewards={ogRewards}
          duration={duration}
        />
        <ValueComparison
          right
          title={'New'}
          rewards={newRewards}
          duration={duration}
        />
      </div>
    </div>
  )
}

const TokenValueComparison = ({
  reward,
  duration,
  style,
}: {
  reward: StakingRewardToken
  duration: BigNumber
  style?: React.CSSProperties
}) => {
  const remainder = reward.value.mod(duration)

  return (
    <div style={style}>
      <div className="flex flex-row items-center mb-1">
        <Body style={margin.mr8}>Total:</Body>
        <TokenValue address={reward.address} value={reward.value} />
      </div>
      <div className="flex flex-row items-center">
        <Body style={margin.mr8}>Leftover:</Body>
        <TokenValue address={reward.address} value={remainder} />
      </div>
    </div>
  )
}

type ValueComparisonProps = {
  title: string
  rewards: StakingRewardToken[]
  duration: BigNumber
  right?: boolean
}

const ValueComparison = ({
  title,
  rewards,
  duration,
  right,
}: ValueComparisonProps) => {
  const theme = useTheme()
  return (
    <div
      className={`flex flex-1 mb-12 ${right && 'border-l-2'}`}
      style={{ justifyContent: right ? 'flex-end' : 'flex-start' }}
    >
      <div className="flex flex-col">
        <Body style={margin.mb16} color={theme.text.secondary}>
          {title}
        </Body>
        {rewards.map((r, idx) => (
          <TokenValueComparison
            style={{ marginBottom: idx !== rewards.length - 1 ? 16 : 0 }}
            reward={r}
            duration={duration}
          />
        ))}
      </div>
    </div>
  )
}

export const NotifyAdjustValueButtons = () => {
  const { values, nextStep, setValues } = useCreateCampaignCtx()
  const { state } = useTokenCtx()

  const onAccept = () => {
    if (values.suggestValues) {
      // set new values in state
      // must parse from BigNumber to string value (max denom)
      setValues({
        ...values,
        rewards: values.rewards.map((r, idx) => {
          const { decimals } = selectTokenByAddress(state, r.address)
          return {
            address: r.address,
            symbol: r.symbol,
            value: values.suggestValues
              ? displayAmount(values.suggestValues[idx] || 0, decimals)
              : '',
          }
        }),
        suggestValues: null,
      })
      nextStep()
    }
  }
  return (
    <div className="flex flex-row">
      <Button
        style={margin.mr8}
        title="Keep Original"
        variant="secondary"
        onPress={nextStep}
      />
      <Button title="Adjust Rewards" variant="primary" onPress={onAccept} />
    </div>
  )
}
