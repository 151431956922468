import { BigNumber } from "ethers";
import { Action, CLEAR_STATE } from "../common";
import { BalanceData, CLEAR_BALANCES, SET_BALANCE } from "./types";

export type BalanceState = {
  [key: string]: {
    // holder address
    [key: string]: BigNumber; // token address -> value
  };
};

export const initBalanceState = (): BalanceState => ({});

type BalanceReducerHandler = (state: BalanceState, action: Action<any>) => BalanceState;

const saveBalance = (state: BalanceState, action: Action<BalanceData>) => {
  return {
    ...state,
    [action.payload.holderAddress]: {
      ...state[action.payload.holderAddress],
      [action.payload.tokenAddress]: action.payload.balance
    }
  };
};

const handlers: { [key: string]: BalanceReducerHandler } = {
  [SET_BALANCE]: saveBalance,
  [CLEAR_BALANCES]: initBalanceState,
  [CLEAR_STATE]: initBalanceState
};

export const balanceReducer = (state: BalanceState = initBalanceState(), action: Action<any>) => {
  return handlers[action.type] ? handlers[action.type](state, action) : state;
};
