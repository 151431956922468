import { SyntheticEvent, useCallback, useEffect, useRef } from 'react'
import { ConnectWeb3Options } from '@tryrolljs/design-system'
import { CampaignGrid } from '../../organisms/campaignGrid'
import { useEthAddress, useModal } from '../../hooks'
import { ConnectWallet } from '../../organisms/connectWallet'
import { PageTemplate } from '../../templates/page'
import CampaignFooter from '../../organisms/campaignFooter'
import StakeInfo from '../../organisms/stakeInfo'
import SoftLaunchWarning from '../../organisms/softLaunchWarning'
import FAQ from '../../organisms/faq'
import {
  decodeWizardUrl,
  WizardEncodeParams,
} from '../../organisms/campaignWizard/common'
import { CreateCampaignManager } from '../../organisms/campaignWizard'

type Props = {
  navCampaignDetails: (address: string) => void
}

export const PageAllCampaigns = ({ navCampaignDetails }: Props) => {
  const modal = useModal()
  const address = useEthAddress()
  const checkedUrl = useRef(false)

  const onConnectWeb3 = useCallback(() => {
    modal.setRenderer(
      () => (
        <ConnectWeb3Options
          onClose={() => modal.setOpen(false)}
          onSelect={() => modal.setOpen(false)}
        />
      ),
      { style: { padding: 0 } },
    )
    modal.setOpen(true)
  }, [modal])

  const openCreateWizard = useCallback(
    (e?: SyntheticEvent, wizardState?: WizardEncodeParams) => {
      modal.setRenderer(
        () => <CreateCampaignManager wizardState={wizardState} />,
        {
          style: {
            padding: 0,
            maxHeight: 700,
          },
        },
      )
      modal.setOpen(true)
    },
    [modal],
  )

  useEffect(() => {
    if (checkedUrl.current) return
    checkedUrl.current = true
    const wizardState = decodeWizardUrl(window.location.href)
    if (wizardState.wizardType === 'create') {
      openCreateWizard(undefined, wizardState)
    }
  }, [modal, openCreateWizard])

  return (
    <PageTemplate>
      <SoftLaunchWarning />
      <StakeInfo onPress={address ? openCreateWizard : onConnectWeb3} />
      {address ? (
        <div className="w-full mt-2">
          <CampaignGrid onSelect={(addr) => navCampaignDetails(addr)} />
        </div>
      ) : (
        <div className="flex justify-center items-center w-full h-72">
          <ConnectWallet />
        </div>
      )}
      <FAQ />
      <CampaignFooter />
    </PageTemplate>
  )
}
