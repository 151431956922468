import { getTokenByAddress } from "../../contracts";
import { Token, setTokenDisplayValuesIfPair } from "../../core";
import { Action } from "../common";
import { initTokenState, TokenState } from "./reducer";
import { ActionSetApprovedToken, SAVE_TOKEN, SAVE_TOKENS, SET_APPROVED_TOKEN } from "./types";
import { Signer } from "@ethersproject/abstract-signer";
import { Provider } from "@ethersproject/providers";
import colors from "nice-color-palettes";
import { BigNumber } from "ethers";

const ROLL_TOKEN_LIST_URL = "https://app.tryroll.com/tokens.json";
const COINGECKO_TOKEN_LIST_URL = "https://tokens.coingecko.com/uniswap/all.json";

const paletteColors = colors;
const getRandomPaletteIndex = (): number => Math.floor(Math.random() * paletteColors.length);

export const actionSaveTokens = (normTokens: TokenState): Action<TokenState> => ({
  type: SAVE_TOKENS,
  payload: normTokens
});

export const actionSaveToken = (token: Token): Action<Token> => ({
  type: SAVE_TOKEN,
  payload: token
});

export const getTokenList = async (url: string) => {
  const resp = await fetch(url);
  const tokenList = await resp.json();
  const tokens = tokenList.tokens as Token[];
  return tokens;
};

export const actionGetTokenList = async () => {
  const rollTokens = await getTokenList(ROLL_TOKEN_LIST_URL);
  const coinGeckoTokens = await getTokenList(COINGECKO_TOKEN_LIST_URL);
  const tokens = coinGeckoTokens.concat(rollTokens);
  return actionSaveTokens(normalizeTokens(tokens));
};

const normalizeTokens = (tokens: Token[]): TokenState =>
  tokens.reduce(normalizeToken, initTokenState());

export const normalizeToken = (acc: TokenState, curr: Token): TokenState => {
  acc.byAddress[curr.address.toLowerCase()] = {
    ...curr,
    color: paletteColors[getRandomPaletteIndex()]
  };
  return acc;
};

export const actionGetTokenByAddress = async (addr: string, provider: Provider | Signer) => {
  const token = await getTokenByAddress(addr, provider);

  const pairTokens = await Promise.all(
    [token.token0, token.token1]
      .filter(Boolean)
      .map((childToken) => getTokenByAddress(childToken!, provider))
  );
  const updatedToken = setTokenDisplayValuesIfPair(token, ...pairTokens);

  return actionSaveTokens(normalizeTokens([updatedToken, ...pairTokens]));
};

export const actionSetTokenApproved = (
  tokenAddress: string,
  spenderAddress: string,
  allowance: BigNumber
): Action<ActionSetApprovedToken> => ({
  type: SET_APPROVED_TOKEN,
  payload: {
    tokenAddress,
    spenderAddress,
    approved: allowance
  }
});

// export const actionSetTokensAllowance = (
//   tokens: string[],
//   allowances: BigNumber[],
//   spender: string
// ): Action<ActionSetTokensAllowance> => {
//   return {
//     type: SET_TOKENS_ALLOWANCE,
//     payload: {
//       spenderAddr: spender,
//       allowances: tokens.map((token, idx) => ({ tokenAddr: token, allowance: allowances[idx] }))
//     }
//   };
// };
