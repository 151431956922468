import { IndicatorLevel, IndicatorProps } from "../atoms/indicator";

interface EthErrorCodes {
  readonly rpc: {
    readonly invalidInput: -32000;
    readonly resourceNotFound: -32001;
    readonly resourceUnavailable: -32002;
    readonly transactionRejected: -32003;
    readonly methodNotSupported: -32004;
    readonly limitExceeded: -32005;
    readonly parse: -32700;
    readonly invalidRequest: -32600;
    readonly methodNotFound: -32601;
    readonly invalidParams: -32602;
    readonly internal: -32603;
  };
  readonly provider: {
    readonly userRejectedRequest: 4001;
    readonly unauthorized: 4100;
    readonly unsupportedMethod: 4200;
    readonly disconnected: 4900;
    readonly chainDisconnected: 4901;
  };
}

export const ethErrorCodes: EthErrorCodes = {
  rpc: {
    invalidInput: -32000,
    resourceNotFound: -32001,
    resourceUnavailable: -32002,
    transactionRejected: -32003,
    methodNotSupported: -32004,
    limitExceeded: -32005,
    parse: -32700,
    invalidRequest: -32600,
    methodNotFound: -32601,
    invalidParams: -32602,
    internal: -32603
  },
  provider: {
    userRejectedRequest: 4001,
    unauthorized: 4100,
    unsupportedMethod: 4200,
    disconnected: 4900,
    chainDisconnected: 4901
  }
};

export type EthProviderError = {
  code: number;
  message: string;
};

export type ErrorMessageOptions = {
  [key: number]: IndicatorProps;
};

export const defaultEthErrorMessages: ErrorMessageOptions = {
  [ethErrorCodes.provider.userRejectedRequest]: {
    message: "Rejected transaction",
    level: "warning"
  }
};

export const parseEthError = (
  err: EthProviderError,
  messages: ErrorMessageOptions,
  fallback: IndicatorProps
): [message: string, level: IndicatorLevel] => {
  // fallback if arg is faulty
  if (typeof err !== "object" || !err.code) return [fallback.message, fallback.level];

  // fallback if code is not in options
  return messages[err.code]
    ? [messages[err.code].message, messages[err.code].level]
    : [fallback.message, fallback.level];
};
