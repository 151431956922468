import { useTheme } from '@tryrolljs/design-system'

export const Background: React.FC = ({ children }) => {
  const theme = useTheme()
  return (
    <div
      className="flex flex-col flex-1"
      style={{
        minHeight: '100vh',
        minWidth: '100%',
        height: '100%',
        width: '100%',
        backgroundColor: theme.background.page,
      }}
    >
      {children}
    </div>
  )
}
