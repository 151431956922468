import {
  SubHeader,
  useTheme,
  Body,
  Button,
  margin,
} from '@tryrolljs/design-system'
import Back from '../../assets/svg/back.svg'
import Close from '../../assets/svg/close.svg'
import { Activity } from '../../atoms/activity'
import { Indicator, IndicatorProps } from '../../atoms/indicator'

type Props = {
  title: string
  description: string
  onBack: () => void
  onClose: () => void
  onNext: () => void
  customBottom?: () => React.ReactElement
  ind: IndicatorProps
  withButtons: boolean
  idx: number
  initializing?: boolean
  disableBack?: boolean
}

export const CampaignWizardWrapper: React.FC<Props> = ({
  children,
  description,
  title,
  onBack,
  onClose,
  onNext,
  ind,
  withButtons,
  idx,
  initializing,
  customBottom,
  disableBack,
}) => {
  const theme = useTheme()

  return (
    <div
      className=" flex flex-col"
      style={{
        minHeight: 300,
        minWidth: 430,
        maxWidth: 500,
        maxHeight: '80vh',
      }}
    >
      <div className="flex justify-between items-center border-b p-4">
        <div>
          {!disableBack && (
            <div className="cursor-pointer" onClick={onBack}>
              <Back />
            </div>
          )}
        </div>
        <SubHeader weight="bold">{title}</SubHeader>
        <div className="cursor-pointer" onClick={onClose}>
          <Close />
        </div>
      </div>
      <div className="p-6 flex flex-1 flex-col overflow-auto">
        <Body color={theme.text.secondary}>{description}</Body>
        {initializing ? (
          <div className="flex w-full h-full justify-center items-center">
            <Activity />
          </div>
        ) : (
          <div className="pt-4">{children}</div>
        )}
        <div className="flex flex-1 flex-col justify-end items-center">
          <WrapperFooter
            withButtons={withButtons}
            idx={idx}
            onNext={onNext}
            onClose={onClose}
            initializing={initializing}
            ind={ind}
            customBottom={customBottom}
          />
        </div>
      </div>
    </div>
  )
}

type WrapperButtonProps = {
  withButtons?: boolean
  idx: number
  onNext: () => void
  onClose: () => void
  initializing?: boolean
  ind: IndicatorProps
  customBottom?: () => React.ReactElement
}

const WrapperFooter = ({
  ind,
  onClose,
  initializing,
  onNext,
  idx,
  withButtons,
  customBottom,
}: WrapperButtonProps) => {
  if (withButtons === false) return null

  return (
    <div className="flex w-full justify-end">
      {customBottom ? (
        customBottom()
      ) : (
        <>
          {ind.message && <Indicator message={ind.message} level={ind.level} />}
          {idx < 1 && !initializing && (
            <Button
              style={margin.ml8}
              variant="secondary"
              title="Cancel"
              onPress={onClose}
            />
          )}
          {!initializing && (
            <Button
              style={margin.ml8}
              variant="primary"
              title="Next"
              onPress={onNext}
            />
          )}
        </>
      )}
    </div>
  )
}
