import { ReactElement } from "react";

type ExternalLinkProps = {
  children: ReactElement;
  url: string;
  target?: string;
};

const ExternalLink = ({ children, url, target = "_blank" }: ExternalLinkProps) => {
  return (
    <a href={url} target={target}>
      {children}
    </a>
  );
};

export default ExternalLink;
