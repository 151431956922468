import { useWeb3React } from 'web3-react-core'
import React, { useCallback, useEffect, useState } from 'react'
import { Input, Body, margin } from '@tryrolljs/design-system'
import { useFilterSymbols, useLibrary, useTokenCtx } from '../../hooks'
import {
  actionGetTokenByAddress,
  actionGetTokenList,
} from '../../state/tokens/actions'
import { isMainNet } from '../../web3/connectors'
import { Dropdown } from '../dropDown'
import { SearchToken } from '../../state/tokens/selectors'
import { TokenInputOption } from '../tokenInputOption'

type onSelect_ = (token: SearchToken) => void
type onChange_ = (symbol: string) => void

type Props = {
  value: string
  onChange: onChange_
  onSelect: onSelect_
  disable?: boolean
}

const isBlockchainAddress = (val: string) =>
  val.toLowerCase().startsWith('0x') && val.length === 42

export const InputToken = ({ value, onChange, onSelect, disable }: Props) => {
  const [openDropdown, setOpenDropdown] = useState(false)
  const { chainId } = useWeb3React()
  const { dispatch } = useTokenCtx()
  const lib = useLibrary()

  const getTokens = useCallback(async () => {
    try {
      const action = await actionGetTokenList()
      dispatch(action)
    } catch (err) {
      console.log(err)
    }
  }, [dispatch])

  useEffect(() => {
    if (isMainNet(chainId)) {
      getTokens()
    }
  }, [chainId, getTokens])

  const _selectToken = (searchToken: SearchToken) => {
    setOpenDropdown(false)
    onChange(searchToken.symbol)
    onSelect(searchToken)
  }

  // allows the onclick of the list item to fire before the component is closed
  const delayCloseDropdown = () => {
    setTimeout(() => {
      setOpenDropdown(false)
    }, 300)
  }

  const _getTokenByAddress = useCallback(
    async (addr: string) => {
      try {
        if (!lib) return
        onChange(addr)
        const action = await actionGetTokenByAddress(addr, lib)
        dispatch(action)
      } catch (err) {
        console.log(err)
      }
    },
    [dispatch, onChange, lib],
  )

  const _onChange = (val: string) => {
    if (!openDropdown) {
      setOpenDropdown(true)
    }

    // async search for a token
    if (isBlockchainAddress(val)) {
      console.log('getting token!', val)
      _getTokenByAddress(val)
    } else {
      // update value
      onChange(val)
    }
  }

  return (
    <Dropdown
      open={openDropdown}
      renderDropdown={() => (
        <FilteredTokens symbol={value} onSelect={_selectToken} />
      )}
    >
      <Input
        editable={!disable}
        style={[margin.mb8]}
        onFocus={() => setOpenDropdown(true)}
        onBlur={delayCloseDropdown}
        // label="Token"
        // placeholder="Select token or paste address"
        placeholder={value ? 'Token' : 'Select token or paste address'}
        value={value}
        onChangeText={_onChange}
      />
    </Dropdown>
  )
}

const step = 10

const renderTokens = (
  length: number,
  tokens: SearchToken[],
  onSelect: onSelect_,
) => {
  const out = []
  for (let i = 0; i < length; i++) {
    if (tokens[i]) {
      out.push(
        <TokenInputOption
          key={tokens[i].address}
          searchToken={tokens[i]}
          onSelect={() => onSelect(tokens[i])}
        />,
      )
    }
  }
  return out
}

type FilteredTokensProps = {
  symbol: string
  onSelect: onSelect_
}

const FilteredTokens = ({ symbol, onSelect }: FilteredTokensProps) => {
  const tokens = useFilterSymbols(symbol)
  const [length, setLength] = useState(10)

  // render more elements when scroll to end
  const onScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      e.currentTarget.clientHeight
    ) {
      setLength(length + step)
    }
  }

  return (
    <div className="max-h-60 overflow-auto" onScroll={onScroll}>
      {!tokens.length ? (
        <NullFilteredTokens />
      ) : (
        renderTokens(length, tokens, onSelect)
      )}
    </div>
  )
}

export const NullFilteredTokens = () => (
  <div className="p-4 justify-center flex flex1">
    <Body>No results found.</Body>
  </div>
)
