import { MutableRefObject, useEffect, useMemo, useRef } from 'react'
import { useTheme, Input, margin, Body } from '@tryrolljs/design-system'
import { BigNumber } from 'ethers'
import Plus from '../../assets/svg/add.svg'
import { IconButton } from '../../atoms/iconButton'
import { CampaignCurrentDeposits, RewardForm } from '../../context/campaign'
import { DeployRewardsForm } from '../../contracts'
import {
  useCreateCampaignCtx,
  useEthAddress,
  useGetTokenBalance,
  useTokenByAddress,
} from '../../hooks'
import { TokenState } from '../../state/tokens/reducer'
import { SearchToken, selectTokenByAddress } from '../../state/tokens/selectors'
import { displayAmount, durationSeconds, parseValue } from '../../util'
import { InputToken } from '../tokenInput'
import { Indicator, IndicatorProps } from '../../atoms/indicator'
import Close from '../../assets/svg/close.svg'
import { useTokenBalance } from '../../hooks/balance'
import { BalanceState } from '../../state/balance'
import { selectTokenBalance } from '../../state/balance/selectors'

export const buildFormRewards = (
  currentDeposits: CampaignCurrentDeposits,
  input: RewardForm[],
  state: TokenState,
): DeployRewardsForm[] => {
  // build map of existing reward form for easy lookup based on address
  // currentFormValues is built based off of existing campaign

  // map the reward form input to a deployable reward form
  return input.map((r) => {
    const token = selectTokenByAddress(state, r.address) // get token from state
    const val = parseValue(r.value, token.decimals) // parse input value
    // find difference between new reward input and existing rewards
    const diff = currentDeposits[token.address]
      ? val.sub(currentDeposits[token.address].reward.value)
      : val

    return {
      sent: diff.isZero(), // if there is no difference, the rewards can be considered already sent
      reward: {
        address: token.address,
        value: diff, // this value will be used to create the transfer from the user to the contract. Only need to send the difference here.
      },
    }
  })
}

// check if input value is less than current value deposited in contract
const isGreaterThanCurrentReward = (
  map: { [key: string]: DeployRewardsForm },
  address: string,
  decimals: number,
  inputVal: string,
): boolean => {
  const val = parseValue(inputVal, decimals)
  if (map[address] && val.lt(map[address].reward.value)) {
    return false
  }
  return true
}

export const validateRewards = (
  rewards: RewardForm[],
  tokenState: TokenState,
  balanceState: BalanceState,
  userAddr: string,
  campaignAddr?: string, // may or may not be deployed (create vs update)
): IndicatorProps | null => {
  if (rewards.length <= 0) {
    return {
      message: 'You must choose atleast one reward token',
      level: 'error',
    }
  }

  const usedSymbols: { [key: string]: boolean } = {}

  for (let i = 0; i < rewards.length; i++) {
    const { address, value } = rewards[i]
    const { symbol, decimals } = selectTokenByAddress(tokenState, address)

    if (!symbol) {
      return {
        message: `Please provide a valid symbol for all inputs`,
        level: 'error',
      }
    }

    if (value === '') {
      return {
        message: `Please provide a valid ${symbol} value`,
        level: 'error',
      }
    }

    if (usedSymbols[address]) {
      return { message: `${symbol} is used more than once`, level: 'error' }
    }

    const val = parseValue(value, decimals) // parse input value

    if (val.isZero() || val.isNegative()) {
      return { message: `Please provide more than 0 ${symbol}`, level: 'error' }
    }

    const availableBal = selectTokenBalance(balanceState, userAddr, address) // get available balance from state (should already be populated)

    // get current balance of contract
    // defaults to 0 if campaignAddr is empty
    const currentAmount = selectTokenBalance(
      balanceState,
      campaignAddr || '',
      address,
    )

    // get amount to be sent by subtracting the existing value in the contract from the input value
    const sendAmount = val.sub(currentAmount)

    // check if amount is less than amount to send
    if (availableBal.lt(sendAmount)) {
      return { message: `Insufficient ${symbol} balance`, level: 'error' }
    }

    // user cannot decrease tokens in contract
    if (val.lt(currentAmount)) {
      return {
        message: `Cannot decrease amount of ${symbol} in contract`,
        level: 'error',
      }
    }

    usedSymbols[address] = true
  }

  return null
}

export const FormCampaignRewards = () => {
  const { wizardType, values, setValues, campaignAddress } =
    useCreateCampaignCtx()
  const currentRewardsMap = useRef({})

  const _updateReward = (idx: number, reward: RewardForm) => {
    const _rewards = [...values.rewards]
    _rewards[idx] = reward
    setValues({ ...values, rewards: _rewards })
  }

  const _push = () => {
    if (values.rewards.length < 3) {
      setValues({
        ...values,
        rewards: [...values.rewards, { value: '', symbol: '', address: '' }],
      })
    }
  }

  const _pop = (idx: number) => {
    if (values.rewards[idx] && values.rewards.length > 1) {
      const _rewards = [...values.rewards]
      _rewards.splice(idx, 1)
      setValues({ ...values, rewards: _rewards })
    }
  }

  return (
    <div className="mb-6 flex flex-col items-end">
      <div className="w-full">
        {values.rewards.map((r, idx) => (
          <CampaignReward
            key={idx}
            contractAddress={campaignAddress}
            currentRewards={currentRewardsMap}
            rewardForm={r}
            onClickClose={() => _pop(idx)}
            onSelectToken={(t) => _updateReward(idx, { ...r, ...t })}
            onChangeSymbol={(symbol) => _updateReward(idx, { ...r, symbol })}
            onChangeAmount={(value) => _updateReward(idx, { ...r, value })}
          />
        ))}
      </div>
      {values.rewards.length < 3 && wizardType === 'create' && (
        <IconButton onClick={_push}>
          <Plus />
        </IconButton>
      )}
    </div>
  )
}

type CampaignRewardProps = {
  contractAddress?: string
  rewardForm: RewardForm
  onClickClose: () => void
  onChangeSymbol: (symbol: string) => void
  onSelectToken: (token: SearchToken) => void
  onChangeAmount: (amount: string) => void
  currentRewards: MutableRefObject<{
    [key: string]: DeployRewardsForm
  }>
}

const CampaignReward = ({
  rewardForm,
  onClickClose,
  onChangeSymbol,
  onSelectToken,
  onChangeAmount,
  currentRewards,
}: CampaignRewardProps) => {
  const theme = useTheme()
  const { values, wizardType } = useCreateCampaignCtx()
  const { decimals } = useTokenByAddress(rewardForm.address)
  const parsedAmount = parseValue(rewardForm.value, decimals)
  const getBal = useGetTokenBalance()
  const userAddr = useEthAddress()
  const bal = useTokenBalance(userAddr || '', rewardForm.address)
  const isGreaterThanCurrent = isGreaterThanCurrentReward(
    currentRewards.current,
    rewardForm.address,
    decimals,
    rewardForm.value,
  )
  const isCreateWizard = wizardType === 'create'

  const [minValue, minValueDisplay] = useMemo<[BigNumber, string]>(() => {
    const minVal = BigNumber.from(durationSeconds(values.start, values.end))
    const val = displayAmount(minVal, decimals)
    return [minVal, val]
  }, [values.start, values.end, decimals])

  useEffect(() => {
    if (!userAddr) return
    getBal(rewardForm.address, userAddr)
  }, [getBal, userAddr, rewardForm])

  const handleChangeAmount = (val: string) =>
    !isNaN(Number(val)) && onChangeAmount(val)

  const addMax = () => {
    if (rewardForm.address) {
      handleChangeAmount(displayAmount(bal, decimals))
    }
  }

  return (
    <div className="shadow-md mb-4 rounded">
      <div className="flex p-2">
        <div className="flex flex-1 flex-col pt-5">
          <InputToken
            disable={!isCreateWizard} // update and extend wizards have disabled token input fields
            value={rewardForm.symbol}
            onChange={(symbol) => onChangeSymbol(symbol)}
            onSelect={(token) => onSelectToken(token)}
          />
          <Input
            placeholder="Amount"
            value={rewardForm.value}
            onChangeText={handleChangeAmount}
          />
          <Body color={theme.text.secondary} style={[margin.mt16]}>
            {`Amount available: ${displayAmount(bal, decimals)} ${
              rewardForm.symbol
            } - `}
            <Body onPress={addMax} color={theme.text.highlight}>
              Add Max
            </Body>
          </Body>
          {/* {contractAddress && (
            <>
              <Body color={theme.textMuted} style={{ marginBottom: 8 }}>
                {`Contract balance: ${displayAmount(contractBal, decimals)} ${rewardForm.symbol}`}
              </Body>
              <Body weight="bold" color={theme.textMuted}>
                {`Total to Send: ${displayAmount(
                  parseValue(rewardForm.value, decimals).sub(contractBal),
                  decimals
                )} ${rewardForm.symbol}`}
              </Body>
            </>
          )} */}
          {!isGreaterThanCurrent && (
            <Indicator
              style={{ marginTop: 16 }}
              message="Cannot decrease tokens in contract"
              level="error"
            />
          )}
          {typeof decimals === 'number' && parsedAmount.lt(minValue) && (
            <Indicator
              style={{ marginTop: 16 }}
              message={`Minimum rewards for duration is ${minValueDisplay} ${rewardForm.symbol}.`}
              level="warning"
              suffix={
                <Body
                  onPress={() => onChangeAmount(minValueDisplay)}
                  color={theme.text.highlight}
                  style={margin.ml8}
                >
                  Set
                </Body>
              }
            />
          )}
        </div>
        {isCreateWizard && (
          <div className="cursor-pointer" onClick={onClickClose}>
            <Close />
          </div>
        )}
      </div>
    </div>
  )
}
