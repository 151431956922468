import { Indicator } from "../../atoms/indicator";
import { useLaunchManagerCtx } from "./common";
import { Accordion, DeployStepHeader } from "./presenter";

type Props = {
  idx: number;
  title: string;
  last?: boolean;
  successMessage?: string;
};

const Success = ({ message }: { message: string }) => {
  return (
    <div className="flex justify-center mb-6 px-4">
      <Indicator message={message} level="warning" />
    </div>
  );
};

export const LaunchStepWrapper: React.FC<Props> = ({
  title,
  children,
  idx,
  last,
  successMessage
}) => {
  const { step, setStep, successSteps } = useLaunchManagerCtx();
  const success = successSteps[idx];
  return (
    <Accordion
      open={step === idx}
      onOpen={() => setStep(idx)}
      onClose={() => setStep(null)}
      containerStyle={{ borderBottomWidth: last ? 0 : 1 }}
      header={<DeployStepHeader success={success} num={idx + 1} title={title} />}
      content={
        success ? (
          <Success message={successMessage || "Step completed"} />
        ) : (
          (children as React.ReactElement)
        )
      }
    />
  );
};
