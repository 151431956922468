// eslint-disable-next-line import/namespace
import { ImageStyle, StyleProp, View } from 'react-native'
import { CircleImg, DEFAULT_CIRCLE_IMG_SIZE } from '@tryrolljs/design-system'
import { Token } from '../../core'
import { useTokenPair } from '../../hooks'

type SingleTokenLogoProps = {
  token: Token
  size?: number
  style?: StyleProp<ImageStyle>
}

export const SingleTokenLogo = ({
  token,
  size = DEFAULT_CIRCLE_IMG_SIZE,
  style,
}: SingleTokenLogoProps) => {
  return (
    <CircleImg
      uri={token.logoURI}
      color={token.color}
      size={size}
      style={style}
    />
  )
}

const MAX_LOGO_COUNT = 3

type MultiTokenLogoProps = {
  tokens: Token[]
  size?: number
  style?: StyleProp<ImageStyle>
}

export const MultiTokenLogo = ({
  tokens,
  size = DEFAULT_CIRCLE_IMG_SIZE,
  style,
}: MultiTokenLogoProps) => {
  const tokensToShow = tokens.slice(0, MAX_LOGO_COUNT)
  const defaultStyle = {
    width: (size / 1.5) * tokensToShow.length,
    height: size,
    position: 'relative',
  } as const

  return (
    <View style={[defaultStyle, style]}>
      {tokensToShow.map((token, index) => {
        return (
          <SingleTokenLogo
            key={token.address}
            token={token}
            size={size}
            style={{
              position: 'absolute',
              left: index * (size / 2),
              backgroundColor: index % 2 === 0 ? 'grey' : 'pink',
            }}
          />
        )
      })}
    </View>
  )
}

interface PairTokenLogoProps {
  pair: [string, string]
  size?: number
  style?: StyleProp<ImageStyle>
}

export const PairTokenLogo = ({ pair, size, style }: PairTokenLogoProps) => {
  const [token0, token1] = useTokenPair(...pair)

  return <MultiTokenLogo tokens={[token0, token1]} size={size} style={style} />
}

export const TokenLogo = ({ token, size, style }: SingleTokenLogoProps) => {
  if (token.token0 && token.token1) {
    return (
      <PairTokenLogo
        pair={[token.token0, token.token1]}
        size={size}
        style={style}
      />
    )
  }

  return <SingleTokenLogo token={token} size={size} style={style} />
}
