import { SubHeader } from '@tryrolljs/design-system'
import DiscordIcon from '../../assets/svg/discord.svg'
import { discordInviteUrl } from '../../constants'
import ExternalLink from '../externalLink'

export const DiscordLink: React.FC = () => {
  return (
    <ExternalLink url={discordInviteUrl}>
      <>
        <SubHeader weight="bold">Discord</SubHeader>{' '}
        <div className="inline-block align-text-top">
          <DiscordIcon />
        </div>
      </>
    </ExternalLink>
  )
}
