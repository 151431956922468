import React, { createContext, useEffect, useReducer } from 'react'
import { useChainID, CHAIN_ID_MAIN_NET } from '@tryrolljs/design-system'
import { useGetTokenLists } from '../hooks'
import { Action } from '../state/common'
import {
  initTokenState,
  tokenReducer,
  TokenState,
} from '../state/tokens/reducer'

export type TokenContext = {
  state: TokenState
  dispatch: React.Dispatch<Action<any>>
}

export const TokenCtx = createContext<TokenContext>({
  state: initTokenState(),
  dispatch: () => null,
})

const TokenListManager = ({ children }: { children: React.ReactNode }) => {
  const [getTokenLists] = useGetTokenLists()
  const chainID = useChainID()

  useEffect(() => {
    if (chainID === CHAIN_ID_MAIN_NET) {
      getTokenLists()
    }
  }, [getTokenLists, chainID])

  return <>{children}</>
}

export const ProviderTokenState: React.FC<{ initState?: TokenState }> = ({
  children,
  initState,
}) => {
  const [state, dispatch] = useReducer(
    tokenReducer,
    initState || initTokenState(),
  )
  return (
    <TokenCtx.Provider value={{ state, dispatch }}>
      <TokenListManager>{children}</TokenListManager>
    </TokenCtx.Provider>
  )
}
