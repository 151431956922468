import { BigNumber } from "ethers";

export const SAVE_CAMPAIGNS = "SAVE_CAMPAIGNS";
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const SAVE_USER_REWARDS = "SAVE_USER_REWARDS";
export const SET_CAMPAIGN_ACTIVITY = "SET_CAMPAIGN_ACTIVITY";
export const SET_AMOUNT_STAKED = "SET_AMOUNT_STAKED";

export type ActionSetCampaignActivity = {
  address: string;
  activity: boolean;
};

export type ActionSaveAmountStaked = {
  campaign: string;
  owner: string;
  token: string;
  value: BigNumber;
};
