import { BigNumber } from "ethers";
import { useContext } from "react";
import { BalanceCtx } from "../context/balance";
import { selectTokenBalance } from "../state/balance/selectors";

export const useBalanceCtx = () => useContext(BalanceCtx);

export const useTokenBalance = (holderAddr: string, tokenAddr: string): BigNumber => {
  const { state } = useBalanceCtx();
  return selectTokenBalance(state, holderAddr, tokenAddr);
};
