import { BigNumber, ethers } from 'ethers'
import { useEffect, useState } from 'react'
import { CampaignStatus, StakingRewardToken } from '../../core'
import { MILLISECONDS_SECOND } from '../../util'
import { TokenValue } from '../tokenValue'

interface RemainingRewardProps {
  reward: StakingRewardToken
  status: CampaignStatus
  end: ethers.BigNumber
}

const SET_REMAINING_REWARD_INTERVAL = 10000 // 10 seconds

export const RemainingReward: React.FC<RemainingRewardProps> = ({
  reward,
  status,
  end,
}) => {
  const [remainingReward, setRemainingReward] = useState<ethers.BigNumber>(
    ethers.BigNumber.from(0),
  )

  useEffect(() => {
    const setRemainingReward_ = () => {
      if (status === 'Upcoming') {
        setRemainingReward(reward.value.add(reward.free || 0))
      } else {
        const timeLeft = Math.floor(
          Math.max(0, end.sub(Date.now()).toNumber()) / MILLISECONDS_SECOND,
        )
        setRemainingReward(reward.rate?.mul(timeLeft) || BigNumber.from(0))
      }
    }
    const interval = setInterval(
      setRemainingReward_,
      SET_REMAINING_REWARD_INTERVAL,
    )
    setRemainingReward_()
    return () => clearInterval(interval)
  }, [status, reward, end])

  return (
    <TokenValue
      testId="reward-token"
      address={reward.address}
      value={remainingReward}
      // eslint-disable-next-line react-native/no-inline-styles
      containerStyle={{ marginBottom: 4 }}
    />
  )
}
