import {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { Signer } from '@ethersproject/abstract-signer'
import { Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import { ContractTransaction } from '@ethersproject/contracts'
import { useChainID } from '@tryrolljs/design-system'
import {
  CampaignWizardForm,
  CreateCampaignContext,
  CreateCampaignCtx,
  RewardForm,
} from '../context/campaign'
import {
  checkFreeTokens,
  claimFreeTokens,
  claimReward,
  DeployCampaignForm,
  exitCampaign,
  getAmountStaked,
  getMaxRewardTokens,
  initCampaign,
  InitCampaignOpts,
  notifyRewards,
  TokenGetter,
  unStake,
} from '../contracts'
import {
  Campaign,
  campaignEndDate,
  campaignIsActive,
  campaignIsComplete,
  campaignIsUpcoming,
  campaignStartDate,
  CampaignStatus,
  StakingRewardToken,
  suggestDuration,
  SuggestDurationForm,
  suggestMultiTokenDuration,
  suggestMultiTokenValue,
} from '../core'
import { CampaignCtx } from '../providers/campaigns'
import {
  actionDeployCampaign,
  actionDepositRewards,
  actionGetCampaign,
  actionGetCampaigns,
  actionGetOwnerCampaigns,
  actionGetUserRewards,
  actionSetAmountStaked,
  actionSetCampaignActivity,
  actionSetDuration,
  actionUpdateStatus,
} from '../state/campaigns/actions'
import { CampaignState } from '../state/campaigns/reducer'
import { Action } from '../state/common'

import {
  durationMilliseconds,
  durationSeconds,
  isSecondsAgo,
  parseValue,
} from '../util'
import {
  selectAmountStaked,
  selectCampaign,
  selectCampaignAsyncActivity,
  selectCampaignsByStatus,
  selectOwnerCampaigns,
  selectUserRewardsByCampaign,
  selectUserRewardsByCampaignToken,
} from '../state/campaigns/selectors'
import { selectTokenByAddress } from '../state/tokens/selectors'
import { defaultEthErrorMessages } from '../error'
import { TokenState } from '../state/tokens/reducer'
import { useContractPool } from '../providers/contracts'
import { blacklistCampaigns, DEFAULT_ERROR_MESSAGE } from '../constants'
import { useTokenCtx, useMaybeGetToken } from './tokens'
import { AsyncState, ethErrorHandler, useAsync } from './util'
import { useEthAddress, useLibrary, useNetworkConfig, useSigner } from './web3'

export const useCampaignCtx = () => useContext(CampaignCtx)

export const useCampaigns = (status?: CampaignStatus): string[] => {
  const { state } = useCampaignCtx()
  return status ? selectCampaignsByStatus(state, status) : state.addresses
}

export const useCampaign = (address: string): Campaign => {
  const { state } = useCampaignCtx()
  return selectCampaign(state, address)
}

export const useCreateCampaignCtx = (): CreateCampaignContext =>
  useContext(CreateCampaignCtx)

// utility hook to interact with campaign getter actions
// ensures token requests are optimized
const useCampaignGetter = (
  actionGetter: (
    tokenGetter: TokenGetter,
    provider: Provider | Signer,
  ) => Promise<Action<CampaignState>>,
) => {
  const { dispatch } = useCampaignCtx()
  const [getToken, dispatchTokens] = useMaybeGetToken()
  const lib = useLibrary()
  const lastRun = useRef<Date | null>(null)

  return useCallback(
    async (onSuccess?: () => void, onFail?: () => void) => {
      try {
        if (!lib || (lastRun.current && isSecondsAgo(lastRun.current, 10)))
          return
        const saveCampaigns = await actionGetter(getToken, lib)
        dispatchTokens()
        dispatch(saveCampaigns)
        lastRun.current = new Date()
        onSuccess && onSuccess()
      } catch (err) {
        console.log('get campaign error: ', err)
        onFail && onFail()
      }
    },
    [lib, dispatch, actionGetter, getToken, dispatchTokens],
  )
}

// get campaigns by owner
export const useGetOwnerCampaigns = (address: string) => {
  const { stakingRegistry } = useContractPool()
  const networkConfig = useNetworkConfig()

  const getOwnerCampaigns = useCallback(
    (tokenGetter: TokenGetter, provider: Provider | Signer) => {
      if (!stakingRegistry) throw new Error('missing staking registry')
      return actionGetOwnerCampaigns({
        owner: address,
        tokenGetter,
        provider,
        registry: stakingRegistry,
        networkConfig,
      })
    },
    [address, stakingRegistry, networkConfig],
  )

  return useCampaignGetter(getOwnerCampaigns)
}

// get all campaigns
export const useGetCampaigns = () => {
  const { stakingFactory, stakingRegistry } = useContractPool()
  const chainId = useChainID()
  const networkConfig = useNetworkConfig()
  const handleGetCampaigns = useCallback(
    (tokenGetter: TokenGetter, provider: Provider | Signer) => {
      if (!stakingFactory) throw new Error('missing staking factory')
      if (!stakingRegistry) throw new Error('missing chain ID')
      if (!chainId) throw new Error('missing chain ID')
      return actionGetCampaigns({
        tokenGetter,
        provider,
        factory: stakingFactory,
        registry: stakingRegistry,
        chainId,
        networkConfig,
      })
    },
    [stakingFactory, stakingRegistry, chainId, networkConfig],
  )

  return useCampaignGetter(handleGetCampaigns)
}

// get single campaign based on address
export const useGetCampaign = () => {
  const { dispatch } = useCampaignCtx()
  const lib = useLibrary()
  const [getToken, dispatchTokens] = useMaybeGetToken()
  const lastRun = useRef<Date | null>(null)

  return useCallback(
    async (address: string) => {
      try {
        if (!lib || (lastRun.current && isSecondsAgo(lastRun.current, 10))) {
          return
        }
        lastRun.current = new Date()
        dispatch(actionSetCampaignActivity(address, true))
        const action = await actionGetCampaign(address, getToken, lib)
        dispatchTokens()
        dispatch(action)
      } catch (err) {
      } finally {
        dispatch(actionSetCampaignActivity(address, false))
      }
    },
    [dispatch, lib, getToken, dispatchTokens],
  )
}

export const useDeployCampaign = (): ((
  form: DeployCampaignForm,
) => Promise<string | null>) => {
  const { dispatch } = useCampaignCtx()
  const signer = useSigner()
  const [getToken, dispatchTokens] = useMaybeGetToken()
  const { stakingFactory } = useContractPool()

  return useCallback(
    async (form: DeployCampaignForm): Promise<string | null> => {
      try {
        if (!signer) throw new Error('missing signer')
        if (!stakingFactory) throw new Error('missing staking factory')
        const action = await actionDeployCampaign(
          form,
          getToken,
          signer,
          stakingFactory,
        )
        dispatchTokens()
        dispatch(action)
        return action.payload.addresses[0] ? action.payload.addresses[0] : null
      } catch (err) {
        return null
      }
    },
    [signer, dispatch, getToken, dispatchTokens, stakingFactory],
  )
}

export const useDepositRewards = () => {
  const { state, dispatch } = useCampaignCtx()
  const signer = useSigner()

  return useCallback(
    async (
      tokenAddr: string,
      value: BigNumber,
      campaignAddr: string,
    ): Promise<ContractTransaction | null> => {
      try {
        const campaign = selectCampaign(state, campaignAddr)
        if (!signer || !campaign.address) return null
        const [action, tx] = await actionDepositRewards(
          tokenAddr,
          value,
          campaign,
          signer,
        )
        dispatch(action)
        return tx
      } catch (err) {
        console.log(err)
        return null
      }
    },
    [state, signer, dispatch],
  )
}

export const useCheckCampaignStatus = () => {
  const lib = useLibrary()
  const { state, dispatch } = useCampaignCtx()
  const lastRun = useRef<Date | null>(null)
  const lastCampaign = useRef<string>('')

  return useCallback(
    async (campaignAddr: string) => {
      try {
        if (
          !lib ||
          (lastRun.current &&
            isSecondsAgo(lastRun.current, 10) &&
            campaignAddr === lastCampaign.current)
        )
          return
        lastRun.current = new Date()
        lastCampaign.current = campaignAddr
        const campaign = selectCampaign(state, campaignAddr)
        const action = await actionUpdateStatus(campaign, lib)
        dispatch(action)
      } catch (err) {
        console.log(err)
      }
    },
    [lib, state, dispatch],
  )
}

export const useCheckUserRewards = () => {
  const addr = useEthAddress()
  const signer = useSigner()
  const { state, dispatch } = useCampaignCtx()
  const lastRun = useRef<Date | null>(null)

  return useCallback(
    async (campaignAddr: string) => {
      try {
        const campaign = selectCampaign(state, campaignAddr)
        if (!signer || !addr || !campaign.address || throttledBlock(lastRun, 3))
          return
        lastRun.current = new Date()
        const action = await actionGetUserRewards(addr, campaign, signer)
        dispatch(action)
      } catch (err) {
        console.log(err)
      }
    },
    [addr, signer, state, dispatch],
  )
}

const throttledBlock = (
  lastRun: MutableRefObject<Date | null>,
  seconds: number,
) => lastRun.current && isSecondsAgo(lastRun.current, seconds)

export const useGetMaxRewardTokens = (campaignAddress: string) => {
  const library = useLibrary()
  const campaign = useCampaign(campaignAddress)
  const currentStakeAmount = useAmountStaked(campaignAddress)
  const rewards = useUserRewards(campaignAddress)
  const { state: tokenState } = useTokenCtx()

  const tokenRewards = useMemo(() => {
    return Object.keys(rewards).map((addr) => {
      const token = selectTokenByAddress(tokenState, addr)
      return { token, reward: rewards[addr] }
    })
  }, [rewards, tokenState])

  return useCallback(
    async (stakeAmount: BigNumber) => {
      try {
        if (!library || !stakeAmount || isNaN(Number(stakeAmount))) return
        const maxRewardTokens = await getMaxRewardTokens(
          campaign,
          tokenRewards,
          currentStakeAmount,
          stakeAmount,
          library,
        )
        return maxRewardTokens
      } catch (err) {
        console.log(err)
      }
    },
    [library, campaign, currentStakeAmount, tokenRewards],
  )
}

export const useUserRewards = (campaignAddr: string) => {
  const addr = useEthAddress()
  const { state } = useCampaignCtx()
  return selectUserRewardsByCampaign(state, addr || '', campaignAddr)
}

export const useUserTokenRewards = (
  campaignAddr: string,
  tokenAddr: string,
) => {
  const addr = useEthAddress()
  const { state } = useCampaignCtx()
  return selectUserRewardsByCampaignToken(
    state,
    addr || '',
    campaignAddr,
    tokenAddr,
  )
}

export const useIsCampaignOwner = (campaignAddr: string) => {
  const addr = useEthAddress()
  const campaign = useCampaign(campaignAddr)
  if (!addr) return false
  return addr === campaign.owner
}

export const useOwnerCampaigns = (owner: string) => {
  const { state } = useCampaignCtx()
  return selectOwnerCampaigns(state, owner)
}

export const useHasClaimableValue = (campaignAddr: string): boolean => {
  const rewards = useUserRewards(campaignAddr)
  return Object.keys(rewards).reduce((acc: boolean, curr: string) => {
    if (!rewards[curr].isZero() && !rewards[curr].isNegative()) {
      return true
    }
    return acc
  }, false)
}

const buildSuggestRewardForm = (
  r: RewardForm,
  state: TokenState,
): SuggestDurationForm => {
  const { decimals } = selectTokenByAddress(state, r.address)
  const val = parseValue(r.value, decimals)
  return { val, decimals }
}

export const useSuggestValue = () => {
  const { state } = useTokenCtx()

  return useCallback(
    (values: CampaignWizardForm) => {
      const bigValues = values.rewards.map((v) => {
        const { decimals } = selectTokenByAddress(state, v.address)
        return parseValue(v.value, decimals)
      })

      return suggestMultiTokenValue(values.start, values.end, bigValues)
    },
    [state],
  )
}

export interface SuggestedDuration {
  safe: boolean
  hasRemainder: boolean
  diffSeconds: number
  start: Date
  end: Date
}

export const useSuggestDuration = () => {
  const { state } = useTokenCtx()

  return useCallback(
    (values: CampaignWizardForm) => {
      let _start: Date
      let _end: Date
      let hasRemainder: boolean
      let safe: boolean

      if (values.rewards.length === 1) {
        const r = values.rewards[0]
        const { decimals } = selectTokenByAddress(state, r.address)
        const val = parseValue(r.value, decimals)
        ;[_start, _end] = suggestDuration(values.start, values.end, val)
        hasRemainder = false
        safe = true
      } else {
        ;[_start, _end, hasRemainder, safe] = suggestMultiTokenDuration(
          values.start,
          values.end,
          values.rewards.map((v) => buildSuggestRewardForm(v, state)),
        )
      }

      const newDuration = durationSeconds(_start, _end)
      const ogDuration = durationSeconds(values.start, values.end)

      return {
        safe: safe,
        hasRemainder,
        start: _start,
        end: _end,
        diffSeconds: Math.abs(ogDuration - newDuration),
      }
    },
    [state],
  )
}

export const useClaimReward = (): [
  (campaignAddr: string) => Promise<void>,
  AsyncState,
] => {
  const signer = useSigner()
  const _async = useAsync()
  const { managedExec } = _async
  const checkUserRewards = useCheckUserRewards()

  const _claimReward = useCallback(
    async (campaignAddr: string) => {
      if (!signer) return
      try {
        // execute transaction
        await managedExec(async () => {
          const tx = await claimReward(campaignAddr, signer)
          if (tx) {
            // wait for transaction to complete
            await tx?.wait()
            // check user rewards once transaction has completed
            await checkUserRewards(campaignAddr)
          }
        }, ethErrorHandler(defaultEthErrorMessages, 'unable to claim reward'))
      } catch (err) {
        // todo@errorhandling show alert
        console.error(err)
      }
    },
    [signer, managedExec, checkUserRewards],
  )

  return [_claimReward, _async]
}

// TODO remove this feature?
export const useCampaignAsyncActivity = (address: string): boolean => {
  const { state } = useCampaignCtx()
  return selectCampaignAsyncActivity(state, address)
}

export const useGetAmountStaked = (
  campaignAddr: string,
): [() => Promise<void>, AsyncState] => {
  const signer = useSigner()
  const asyncState = useAsync()
  const { managedExec } = asyncState
  const { dispatch } = useCampaignCtx()
  const campaign = useCampaign(campaignAddr)

  const _getAmount = useCallback(
    () =>
      managedExec(async () => {
        if (!signer || !campaign.address || !campaign.tokenAddress) return
        const addr = await signer.getAddress()
        const amount = await getAmountStaked(addr, campaign.address, signer)
        if (amount) {
          dispatch(
            actionSetAmountStaked(
              campaign.address,
              addr,
              campaign.tokenAddress,
              amount,
            ),
          )
        }
      }),
    [signer, managedExec, dispatch, campaign.address, campaign.tokenAddress],
  )
  return [_getAmount, asyncState]
}

export const useAmountStaked = (campaignAddr: string) => {
  const { state } = useCampaignCtx()
  const addr = useEthAddress()
  const { tokenAddress } = selectCampaign(state, campaignAddr)
  return selectAmountStaked(state, campaignAddr, addr || '', tokenAddress)
}

export const useExitCampaign = (
  campaignAddress: string,
): [() => Promise<void>, AsyncState] => {
  const asyncState = useAsync()
  const { managedExec } = asyncState
  const signer = useSigner()
  const getRewards = useCheckUserRewards()
  const [_getAmountStaked] = useGetAmountStaked(campaignAddress)

  const _exitCampaign = useCallback(async () => {
    if (!signer) return
    await managedExec(async () => {
      const tx = await exitCampaign(campaignAddress, signer)
      if (tx) {
        await tx.wait()
        await getRewards(campaignAddress)
        await _getAmountStaked()
      }
    }, ethErrorHandler(defaultEthErrorMessages, 'Failed to exit campaign'))
  }, [managedExec, signer, getRewards, _getAmountStaked, campaignAddress])

  return [_exitCampaign, asyncState]
}

export const useCampaignsByStatus = (status: CampaignStatus): string[] => {
  const { state } = useCampaignCtx()
  return selectCampaignsByStatus(state, status)
}

export const useDeployCampaignForm = (): DeployCampaignForm => {
  const { values } = useCreateCampaignCtx()
  const { state } = useTokenCtx()

  const rewards = useMemo<StakingRewardToken[]>(() => {
    return values.rewards.map((r) => {
      const token = selectTokenByAddress(state, r.address)
      const value = parseValue(r.value, token.decimals)
      return {
        address: token.address,
        value,
      }
    })
  }, [values.rewards, state])

  return {
    rewards,
    start: values.start,
    end: values.end,
    stakeTokenAddress: values.stakeTokenAddress,
  }
}

export const useClaimFreeTokens = (): [
  (campaignAddr: string) => Promise<void>,
  AsyncState,
] => {
  const asyncState = useAsync()
  const { managedExec } = asyncState
  const signer = useSigner()

  const claim = useCallback(
    async (campaignAddr: string) => {
      if (!signer) throw new Error('missing connected wallet')
      return managedExec(async () => {
        const tx = await claimFreeTokens(campaignAddr, signer)
        await tx.wait()
      })
    },
    [signer, managedExec],
  )

  return [claim, asyncState]
}

export const useCheckFreeTokens = (): [
  (campaignAddr: string, tokenAddr: string) => Promise<BigNumber | undefined>,
  AsyncState,
] => {
  const asyncState = useAsync()
  const { managedExec } = asyncState
  const signer = useSigner() as Signer

  const checkFree = useCallback(
    (campaignAddr: string, tokenAddr: string) => {
      if (!signer) throw new Error('missing connected wallet')
      return managedExec<BigNumber>(() =>
        checkFreeTokens(campaignAddr, tokenAddr, signer),
      )
    },
    [signer, managedExec],
  )

  return [checkFree, asyncState]
}

export const useRefreshCampaign = (address: string) => {
  const campaign = useCampaign(address)
  const getCampaign = useGetCampaign()

  const refreshCampaign = useCallback(() => {
    if (!campaignIsComplete(campaign) && address) {
      getCampaign(address)
    }
  }, [getCampaign, address, campaign])

  useEffect(() => {
    const now = new Date()
    let remainingMilliseconds = 0
    if (campaignIsActive(campaign)) {
      remainingMilliseconds = durationMilliseconds(
        now,
        campaignEndDate(campaign),
      )
    } else if (campaignIsUpcoming(campaign)) {
      remainingMilliseconds = durationMilliseconds(
        now,
        campaignStartDate(campaign),
      )
    }

    if (remainingMilliseconds > 0) {
      const timeoutId = setTimeout(refreshCampaign, remainingMilliseconds)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [address, refreshCampaign, campaign])
}

export const useRefreshRewards = (campaignAddr: string) => {
  const getRewards = useCheckUserRewards()

  useEffect(() => {
    const interval = setInterval(function () {
      getRewards(campaignAddr)
    }, 10000) // 10 seconds
    return () => clearInterval(interval)
  }, [getRewards, campaignAddr])
}

export const useUnstake = (
  campaignAddr: string,
): [(amount: BigNumber) => Promise<void>, AsyncState] => {
  const asyncState = useAsync()
  const { managedExec } = asyncState
  const signer = useSigner()
  const [getStakeAmount] = useGetAmountStaked(campaignAddr)

  const handleUnstake = useCallback(
    (amount: BigNumber) =>
      managedExec(async () => {
        if (!signer) throw new Error('wallet is not connected')
        const tx = await unStake(amount, campaignAddr, signer)
        await tx.wait()
        await getStakeAmount()
      }),
    [managedExec, signer, campaignAddr, getStakeAmount],
  )

  return [handleUnstake, asyncState]
}

type HandleInitCampaign = (
  campaignAddr: string,
  opts: InitCampaignOpts,
) => Promise<void>

export const useInitCampaign = (): [HandleInitCampaign, AsyncState] => {
  const asyncState = useAsync()
  const { managedExec } = asyncState
  const signer = useSigner()
  const getCampaign = useGetCampaign()

  const handleInitCampaign: HandleInitCampaign = useCallback(
    (campaignAddr, opts) =>
      managedExec(
        async () => {
          if (!signer) throw new Error('wallet is not connected')
          const tx = await initCampaign(campaignAddr, signer, opts)
          await tx.wait()
          await getCampaign(campaignAddr)
        },
        (error) => {
          const _error = error as unknown as any
          if (_error && _error.transactionHash) {
            return 'Check failed transaction on metamask'
          }
          return DEFAULT_ERROR_MESSAGE
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return [handleInitCampaign, asyncState]
}

type HandleSetDuration = (addr: string, start: Date, end: Date) => Promise<void>

export const useSetDuration = (): [HandleSetDuration, AsyncState] => {
  const signer = useSigner()
  const { state, dispatch } = useCampaignCtx()
  const _async = useAsync()
  const { managedExec } = _async

  const exec: HandleSetDuration = useCallback(
    (campaignAddr, start, end) =>
      managedExec(async () => {
        const campaign = selectCampaign(state, campaignAddr)
        if (!signer || !campaign.address) {
          throw new Error('unable to find campaign address in state')
        }
        const [action, tx] = await actionSetDuration(
          campaign,
          start,
          end,
          signer,
        )
        await tx.wait()
        dispatch(action)
      }),
    [signer, managedExec, dispatch, state],
  )

  return [exec, _async]
}

type HandleNotifyRewards = (
  addr: string,
  rewards: StakingRewardToken[],
) => Promise<void>

export const useNotifyRewards = (): [HandleNotifyRewards, AsyncState] => {
  const signer = useSigner()
  const _async = useAsync()
  const { managedExec } = _async
  const getCampaign = useGetCampaign()

  const exec: HandleNotifyRewards = useCallback(
    (contractAddress, rewards) =>
      managedExec(async () => {
        if (!signer) return
        const tx = await notifyRewards(contractAddress, rewards, signer)
        await tx.wait()
        await getCampaign(contractAddress) // refresh campaign data
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return [exec, _async]
}

export const useIsBlackListed = () => {
  const chainId = useChainID()
  return useCallback(
    (id: string) => {
      if (!chainId) return false
      const blacklist = blacklistCampaigns[chainId]
      if (!blacklist) return false
      if (blacklist.includes(id.toLowerCase())) return true
      return false
    },
    [chainId],
  )
}
