import { LargeHeader, margin, text } from '@tryrolljs/design-system'
import { ConnectWallet } from '../../organisms/connectWallet'

export const CampaignDetailsConnectPrompt = () => {
  return (
    <div className="flex flex-col items-center">
      <LargeHeader style={[margin.mb48, text.bold, text.center]}>
        Connect your wallet to start staking <br /> and earning rewards
      </LargeHeader>
      <ConnectWallet />
    </div>
  )
}
