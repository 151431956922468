import React from 'react'
import { margin, Caption, Value } from '@tryrolljs/design-system'
import MagicWand from '../../assets/svg/magicWand.svg'
import { Reward } from '../../contracts'
import { useTokenByAddress } from '../../hooks'
import { displayAmount } from '../../util'

const DisplayAmount: React.FC<{ reward: Reward; suffix?: string }> = ({
  reward,
  suffix,
}) => {
  const token = useTokenByAddress(reward.token)

  return (
    <Value
      displayValue={displayAmount(reward.amount, token.decimals)}
      decimals={token.decimals}
      renderValue={(val) => (
        <Caption>{`${val} ${
          suffix ? token.symbol + suffix : token.symbol
        } `}</Caption>
      )}
    />
  )
}

const getSuffix = (len: number, idx: number): string => {
  if (idx + 2 === len) return ', and' // oxford comma?
  if (len > idx + 1) return ','
  if (len === idx + 1) return '.'
  return ''
}

export const MaxRewardPotential = ({
  maxRewardTokens,
}: {
  maxRewardTokens: Reward[]
}) => {
  return (
    <div className="flex items-center mt-1">
      <MagicWand />
      <Caption style={margin.ml4}>
        If you stake till the end of the contract, you can earn up to{' '}
        {maxRewardTokens.map((reward: Reward, idx) => {
          return (
            <DisplayAmount
              reward={reward}
              suffix={getSuffix(maxRewardTokens.length, idx)}
            />
          )
        })}
      </Caption>
    </div>
  )
}
