import { BigNumber } from '@ethersproject/bignumber'
import Big from 'big.js'
import { utils as etherUtils } from 'ethers'
import { CHAIN_ID_GOERLI, CHAIN_ID_MAIN_NET } from '@tryrolljs/design-system'
import { config } from '../config'

const maybePush = (
  arr: string[],
  cache: { [key: string]: boolean },
  val: string,
) => {
  if (!cache[val]) {
    cache[val] = true
    arr.push(val)
  }
}

export const mergeStrArrays = (x: string[], y: string[]): string[] => {
  const out: string[] = []
  const cache: { [key: string]: boolean } = {}

  x.forEach((val) => maybePush(out, cache, val))
  y.forEach((val) => maybePush(out, cache, val))

  return out
}

export const isSecondsAgo = (date: Date, seconds: number): boolean =>
  new Date().getTime() - date.getTime() < seconds * 1000

export const addMinutes = (d: Date, minutes: number) =>
  new Date(d.getTime() + minutes * 60000)

export const addDays = (d: Date, days: number) =>
  new Date(d.getTime() + days * 86400000)

export const displayDate = (d: Date) => {
  const s = d.toLocaleDateString('en-us', { timeZone: 'America/New_York' })
  return s.slice(0, s.length - 4) + s.slice(s.length - 2)
}

export const displayTime = (d: Date) =>
  d.toLocaleTimeString('en-us', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'America/New_York',
  })

export const displayAmount = (value: BigNumber, decimals: number) => {
  return etherUtils.formatUnits(value, decimals)
}

export const parseValue = (value: string, decimals: number): BigNumber => {
  try {
    return etherUtils.parseUnits(value, decimals)
  } catch (err) {
    return BigNumber.from(0)
  }
}

export const buildLink = (endpoint: string): string =>
  config.FRONT_END_URL + endpoint

export const copyClipboard = (text: string) =>
  navigator.clipboard.writeText(text)

export const isBeforeNow = (d: Date): boolean =>
  d.getTime() < new Date().getTime()

export const isAfterNow = (d: Date): boolean =>
  d.getTime() > new Date().getTime()

export function shortenAddress(address: string, digits: number = 4) {
  if (!isAddress(address)) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${address.substring(0, digits + 2)}...${address.substring(
    42 - digits,
  )}`
}

export function isAddress(value: string) {
  try {
    return etherUtils.getAddress(value.toLowerCase())
  } catch {
    return false
  }
}

export type StrIdx = {
  [key: string]: string[]
}

export const appendStrIdx = (map: StrIdx, key: string, value: string) => {
  if (map[key]) {
    map[key].push(value)
  } else {
    map[key] = [value]
  }
}

export const mergeStrIndex = (a: StrIdx, b: StrIdx): StrIdx =>
  Object.keys(b).reduce((acc: StrIdx, curr: string) => {
    if (a[curr]) {
      acc[curr] = mergeStrArrays(a[curr], b[curr])
    } else {
      acc[curr] = b[curr]
    }
    return acc
  }, {})

export const MILLISECONDS_HOUR = 3600000
export const MILLISECONDS_DAY = 86400000
export const MILLISECONDS_MINUTE = 60000
export const MILLISECONDS_SECOND = 1000

export const durationMilliseconds = (start: Date, end: Date) =>
  end.getTime() - start.getTime()

export const durationSeconds = (start: Date, end: Date) =>
  Math.floor(durationMilliseconds(start, end) / MILLISECONDS_SECOND)

export const bigMilliseconds = (d: Date): BigNumber =>
  BigNumber.from(d.getTime())

export const isValidDate = (d: Date) => {
  //@ts-ignore
  return d instanceof Date && !isNaN(d)
}

export const sortFuncBigNum = (a: BigNumber, b: BigNumber) => {
  if (a.lt(b)) return -1
  if (a.eq(b)) return 0
  return 1
}
export const sortBigNum = (values: BigNumber[]) => values.sort(sortFuncBigNum)

export const isWholeNum = (n: Big): boolean => n.mod(1).eq(0)

export const semverGreaterThan = (newVersion: string, oldVersion: string) => {
  if (!oldVersion) return true

  const versionsA = newVersion.split(/\./g)

  const versionsB = oldVersion.split(/\./g)
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift())

    const b = Number(versionsB.shift())

    if (a === b) continue

    return a > b || isNaN(b)
  }
  return false
}

export const bigMaxDenom = (minDenom: BigNumber, decimals: number) => {
  return new Big(minDenom.toString()).div(Math.pow(10, decimals))
}

export const bigMinDenom = (maxDenom: Big, decimals: number) => {
  const max = maxDenom.mul(Math.pow(10, decimals))
  return BigNumber.from(max.toString())
}

export const isEthereumNetwork = (chainId: number) =>
  chainId === CHAIN_ID_MAIN_NET || chainId === CHAIN_ID_GOERLI
