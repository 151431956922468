import { createContext, useCallback, useContext, useState } from 'react'
import { NetworkConfig } from '../../config'
import { useCreateCampaignCtx } from '../../hooks'
import {
  FormLaunchStepsPresenter,
  MapSuccessSteps,
  MAX_STEPS,
} from './presenter'
import { LaunchStep } from './steps'

type Props = {
  steps: LaunchStep[]
}

type LaunchManagerContext = {
  step: number | null
  setStep: (step: number | null) => void
  successSteps: MapSuccessSteps
  onDeploySuccess: (addr: string, idx: number) => void
  onStepSuccess: (step: number) => void
}

const LaunchManagerCtx = createContext<LaunchManagerContext>({
  step: null,
  setStep: () => null,
  successSteps: {},
  onDeploySuccess: (_addr: string) => null,
  onStepSuccess: (_step: number) => null,
})

export const useLaunchManagerCtx = () => useContext(LaunchManagerCtx)

export const versionLaunchSteps = (
  campaignAddr: string,
  networkConfig: NetworkConfig,
  stepsV1: LaunchStep[],
  stepsV2: LaunchStep[],
): LaunchStep[] => {
  if (
    networkConfig.V1_SUPPORT_CAMPAIGNS &&
    networkConfig.V1_SUPPORT_CAMPAIGNS[campaignAddr.toLowerCase()]
  ) {
    return stepsV1
  }

  return stepsV2
}

export const LaunchStepsManager = ({ steps }: Props) => {
  const [activeStep, setActiveStep] = useState<number | null>(0)
  const [successfulSteps, setSuccessfulSteps] = useState<MapSuccessSteps>({})
  const { nextStep, setCampaignAddress } = useCreateCampaignCtx()

  const setStep = useCallback(
    (step: number | null) => {
      if (step === null) {
        setActiveStep(null)
        return
      }

      if (step <= MAX_STEPS) {
        setActiveStep(step)
      }
    },
    [setActiveStep],
  )

  const onDeploySuccess = (addr: string, step: number) => {
    setCampaignAddress(addr)
    onSuccess(step)
  }

  const onSuccess = useCallback(
    (step: number) => {
      setSuccessfulSteps({ ...successfulSteps, [step]: true })
      setStep(step + 1)
      if (step === steps.length - 1) {
        nextStep()
      }
    },
    [setStep, setSuccessfulSteps, successfulSteps, nextStep, steps],
  )

  return (
    <LaunchManagerCtx.Provider
      value={{
        step: activeStep,
        setStep,
        successSteps: successfulSteps,
        onDeploySuccess,
        onStepSuccess: onSuccess,
      }}
    >
      <FormLaunchStepsPresenter steps={steps} />
    </LaunchManagerCtx.Provider>
  )
}
