import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { displayTime, isValidDate } from "../../util";

type DateInputProps = {
  disable?: boolean;
  value: Date;
  setValue: (d: Date) => void;
};

export const setTime = (currentDate: Date, inputDate: Date | undefined): Date => {
  if (!inputDate || !isValidDate(inputDate)) return currentDate;

  const date = new Date();

  // set date from current
  date.setMonth(currentDate.getMonth());
  date.setDate(currentDate.getDate());
  date.setFullYear(currentDate.getFullYear());

  // set time from input
  date.setMinutes(inputDate.getMinutes());
  date.setHours(inputDate.getHours());

  return date;
};

export const setMonthYear = (currentDate: Date, inputDate: Date | undefined): Date => {
  if (!inputDate || !isValidDate(inputDate)) return currentDate;

  const date = new Date();

  // update date from input
  date.setMonth(inputDate.getMonth());
  date.setDate(inputDate.getDate());
  date.setFullYear(inputDate.getFullYear());

  // get time from current
  date.setMinutes(currentDate.getMinutes());
  date.setHours(currentDate.getHours());

  return date;
};

const formatTimeEST = (date?: MaterialUiPickersDate) => (date ? `${displayTime(date)} EST` : "-");

export const DateTimeInput = ({ value, setValue, disable }: DateInputProps) => {
  const updateTime = (val?: Date) => val && !disable && setValue(setTime(value, val));

  const updateDate = (val?: Date) => val && !disable && setValue(setMonthYear(value, val));

  return (
    <div className="flex flex-row mb-2">
      <KeyboardDatePicker
        style={{ marginRight: 16 }}
        value={value}
        onChange={(val) => updateDate(val as Date)}
        disabled={disable}
      />
      <KeyboardTimePicker
        labelFunc={formatTimeEST}
        value={value}
        onChange={(val) => updateTime(val as Date)}
        disabled={disable}
      />
    </div>
  );
};
