import { BigNumber } from "ethers";
import { Token } from "../../core";
import { TokenState } from "./reducer";

export const selectTokenByAddress = (state: TokenState, address: string = ""): Token =>
  state.byAddress[address.toLowerCase()] || {};

export const selectTokenExistsByAddress = (state: TokenState, address: string): boolean =>
  state.byAddress[address.toLowerCase()] ? true : false;

export const selectTokenAllowance = (
  state: TokenState,
  tokenAddr: string,
  spenderAddr: string
): BigNumber => {
  if (!state.approved[tokenAddr] || !state.approved[tokenAddr][spenderAddr])
    return BigNumber.from(0);
  return state.approved[tokenAddr][spenderAddr];
};

export interface SearchToken {
  address: string;
  symbol: string;
}

export const selectSearchableList = (state: TokenState): SearchToken[] => {
  return Object.values(state.byAddress).map((t) => ({ address: t.address, symbol: t.symbol }));
};
