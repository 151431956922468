import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { StakingV2 } from '@tryrolljs/contract-bindings'
import {
  useChainID,
  useSigner,
  CHAIN_ID_HARDHAT,
  CHAIN_ID_MAIN_NET,
  CHAIN_ID_MUMBAI,
  CHAIN_ID_GOERLI,
  CHAIN_ID_POLYGON,
} from '@tryrolljs/design-system'
import { config, NetworkConfig } from '../config'
import { CHAIN_ID_FORM_TESTNET } from '../constants'

export interface ContractPoolContext {
  stakingFactory: StakingV2.RollStakingFactory | null
  stakingRegistry: StakingV2.StakingRegistry | null
}

const ContractPoolCtx = createContext<ContractPoolContext>({
  stakingFactory: null,
  stakingRegistry: null,
})

export const useContractPool = () => useContext(ContractPoolCtx)

export const ContractPoolProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const chainID = useChainID()
  const signer = useSigner()
  const [{ stakingFactory, stakingRegistry }, setContracts] =
    useState<ContractPoolContext>({
      stakingFactory: null,
      stakingRegistry: null,
    })

  const connectContracts = useCallback(
    (networkConfig: NetworkConfig) => {
      if (!signer) throw new Error('unable to init contracts, missing signer')
      const factory = StakingV2.RollStakingFactory__factory.connect(
        networkConfig.FACTORY_ADDRESS,
        signer,
      )
      const registry = StakingV2.StakingRegistry__factory.connect(
        networkConfig.REGISTRY_ADDRESS,
        signer,
      )
      console.log('connected to contracts...', networkConfig)
      setContracts({ stakingFactory: factory, stakingRegistry: registry })
    },
    [signer],
  )

  useEffect(() => {
    try {
      if (chainID) {
        switch (chainID) {
          case CHAIN_ID_MAIN_NET:
            console.log('connecting to mainnet', config.NETWORK_MAINNET)
            connectContracts(config.NETWORK_MAINNET)
            break

          case CHAIN_ID_GOERLI:
            console.log('connecting to goerli', config.NETWORK_GOERLI)
            connectContracts(config.NETWORK_GOERLI)
            break

          case CHAIN_ID_MUMBAI:
            console.log('connecting to mumbai', config.NETWORK_MUMBAI)
            connectContracts(config.NETWORK_MUMBAI)
            break
          case CHAIN_ID_POLYGON:
            console.log('connecting to polygon', config.NETWORK_POLYGON)
            connectContracts(config.NETWORK_POLYGON)
            break
          case CHAIN_ID_HARDHAT:
            console.log('connecting to local', config.NETWORK_LOCAL)
            connectContracts(config.NETWORK_LOCAL)
            break
          case CHAIN_ID_FORM_TESTNET:
            console.log(
              'connecting to form testnet',
              config.NETWORK_FORM_TESTNET,
            )
            connectContracts(config.NETWORK_FORM_TESTNET)
            break
          default:
            throw new Error(`unsupported chainID ${chainID}`)
        }
      }
    } catch (err) {
      console.error(err)
    }
  }, [chainID, connectContracts])

  return (
    <ContractPoolCtx.Provider value={{ stakingFactory, stakingRegistry }}>
      {children}
    </ContractPoolCtx.Provider>
  )
}
