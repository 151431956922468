import { useWeb3React } from 'web3-react-core'
import { Web3Provider } from '@ethersproject/providers'
import { useMemo } from 'react'
import {
  useChainID,
  CHAIN_ID_GOERLI,
  CHAIN_ID_HARDHAT,
  CHAIN_ID_MAIN_NET,
  CHAIN_ID_MUMBAI,
  CHAIN_ID_POLYGON,
} from '@tryrolljs/design-system'
import { config } from '../config'
import { CHAIN_ID_FORM_TESTNET } from '../constants'

// TODO remove in favor of roll-ui utils
export const useLibrary = () => {
  const { library } = useWeb3React<Web3Provider>()
  return library
}

// TODO remove in favor of roll-ui utils
export const useSigner = () => {
  const library = useLibrary()
  const signer = useMemo(
    () => (library ? library.getSigner() : undefined),
    [library],
  )
  return signer
}

// TODO remove in favor of roll-ui utils
export const useEthAddress = () => {
  const { account } = useWeb3React<Web3Provider>()
  return account || ''
}

export const useNetworkConfig = () => {
  const chainID = useChainID()

  return useMemo(() => {
    switch (chainID) {
      case CHAIN_ID_MAIN_NET:
        return config.NETWORK_MAINNET

      case CHAIN_ID_GOERLI:
        return config.NETWORK_GOERLI

      case CHAIN_ID_MUMBAI:
        return config.NETWORK_MUMBAI
      case CHAIN_ID_POLYGON:
        return config.NETWORK_POLYGON
      case CHAIN_ID_FORM_TESTNET:
        return config.NETWORK_FORM_TESTNET
      case CHAIN_ID_HARDHAT:
        return config.NETWORK_LOCAL

      default:
        return config.NETWORK_LOCAL
    }
  }, [chainID])
}
