import { BigNumber } from "@ethersproject/bignumber";
import { Campaign, CampaignStatus } from "../../core";
import { CampaignState } from "./reducer";

export const selectCampaign = (state: CampaignState, addr: string) =>
  state.byAddress[addr] || buildDefaultCampaign();

export const buildDefaultCampaign = (): Campaign => ({
  rewards: [],
  rewardTokenAddresses: [],
  start: BigNumber.from(0),
  end: BigNumber.from(0),
  duration: BigNumber.from(0),
  owner: "",
  address: "",
  tokenAddress: "",
  total: BigNumber.from(0),
  status: "Inactive"
});

// returns map tokenAddr -> reward value
export const selectUserRewardsByCampaign = (
  state: CampaignState,
  userAddr: string,
  campaignAddr: string
): { [key: string]: BigNumber } => {
  if (state.userRewards[campaignAddr] && state.userRewards[campaignAddr][userAddr]) {
    return state.userRewards[campaignAddr][userAddr];
  }
  return {};
};

export const selectUserRewardsByCampaignToken = (
  state: CampaignState,
  userAddr: string,
  campaignAddr: string,
  tokenAddr: string
): BigNumber => {
  if (state.userRewards[campaignAddr] && state.userRewards[campaignAddr][userAddr]) {
    return state.userRewards[campaignAddr][userAddr][tokenAddr] || BigNumber.from(0);
  }
  return BigNumber.from(0);
};

export const selectOwnerCampaigns = (state: CampaignState, owner: string): string[] =>
  state.indexByOwner[owner] || [];

export const selectCampaignAsyncActivity = (state: CampaignState, address: string) =>
  state.asyncActivity[address] || false;

export const selectAmountStaked = (
  state: CampaignState,
  campaign: string,
  owner: string,
  token: string
): BigNumber => {
  if (
    !state.stakedValue[campaign] ||
    !state.stakedValue[campaign][owner] ||
    !state.stakedValue[campaign][owner][token]
  ) {
    return BigNumber.from(0);
  }
  return state.stakedValue[campaign][owner][token];
};

export const selectCampaignsByStatus = (state: CampaignState, status: CampaignStatus): string[] =>
  state.addresses.filter((addr) => selectCampaign(state, addr).status === status);
