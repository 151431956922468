import { Body, margin, useTheme, Caption } from '@tryrolljs/design-system'
import { evaluateParticipation } from '../../core'
import { useAmountStaked, useCampaign, useUserRewards } from '../../hooks'
import { RemainingReward } from '../../molecules/remainingReward'
import { TokenValue, TextTokenValue } from '../../molecules/tokenValue'

type Props = {
  campaignAddr: string
}

export const RewardDetail = ({ campaignAddr }: Props) => {
  const campaign = useCampaign(campaignAddr)
  const rewards = useUserRewards(campaignAddr)
  const amountStaked = useAmountStaked(campaignAddr)
  const participation = evaluateParticipation(amountStaked, campaign.total)
  const theme = useTheme()

  return (
    <div className="w-full p-12">
      <div className="flex w-full">
        <div className="w-1/2">
          <Body color={theme.text.secondary}>Remaining Rewards</Body>
        </div>
        <div className="flex flex-col w-1/2 items-end">
          {campaign.rewards.map((r) => (
            <RemainingReward
              key={r.address}
              status={campaign.status}
              end={campaign.end}
              reward={r}
            />
          ))}
        </div>
      </div>

      <div className="h-px w-full border-b my-6" />

      <div className="flex w-full">
        <div className="w-1/2">
          <Body color={theme.text.secondary}>Claimable Staking Rewards</Body>
        </div>
        <div className="flex flex-col w-1/2 items-end">
          {Object.keys(rewards).map((tokenAddr) => (
            <TokenValue
              key={tokenAddr}
              testId="claimable-reward-token"
              address={tokenAddr}
              value={rewards[tokenAddr]}
              containerStyle={{ marginBottom: 4 }}
            />
          ))}
        </div>
      </div>

      <div className="flex w-full mt-12">
        <div className="flex flex-col w-1/2">
          <Body style={margin.mb4} color={theme.text.secondary}>
            Total Staked Value
          </Body>
          <TextTokenValue
            address={campaign.tokenAddress}
            value={campaign.total}
          />
        </div>
        <div className="flex flex-col w-1/2">
          <Body style={margin.mb4} color={theme.text.secondary}>
            Your Stake
          </Body>
          <TextTokenValue
            address={campaign.tokenAddress}
            value={amountStaked}
          />
          {participation && (
            <Caption color={theme.text.secondary}>
              {participation.toString()}% participation
            </Caption>
          )}
        </div>
      </div>
    </div>
  )
}
