import { WizardEncodeParams, wizardSteps } from "./common";
import { useCampaignWizardManager } from "./manager";

type Props = {
  wizardState?: WizardEncodeParams;
};

export const CreateCampaignManager = ({ wizardState }: Props) => {
  const { render } = useCampaignWizardManager(wizardSteps, null, "create", wizardState);
  return render();
};
