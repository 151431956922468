import { useTheme, Button } from '@tryrolljs/design-system'
import { BigNumber } from 'ethers'
import { useCallback } from 'react'
import { Indicator } from '../../atoms/indicator'
import {
  AsyncState,
  useDeployCampaignForm,
  useInitCampaign,
  useNotifyRewards,
} from '../../hooks'
import { Activity } from '../../atoms/activity'
import { validateCampaignDuration } from '../../core'

type Props = {
  contractAddress: string
  onSuccess: () => void
}

export const InitCampaign = ({ contractAddress, onSuccess }: Props) => {
  const deployForm = useDeployCampaignForm()
  const [initCampaign, asyncState] = useInitCampaign()

  const handleClick = useCallback(async () => {
    try {
      const err = validateCampaignDuration(deployForm.start, deployForm.end)
      if (err) {
        asyncState.setInd(err.message, err.level)
        return
      }

      const { rewards, tokens } = deployForm.rewards.reduce(
        (acc: { rewards: BigNumber[]; tokens: string[] }, curr) => {
          acc.rewards.push(curr.value)
          acc.tokens.push(curr.address)
          return acc
        },
        { rewards: [], tokens: [] },
      )

      await initCampaign(contractAddress, {
        start: deployForm.start,
        end: deployForm.end,
        rewards: rewards,
        tokens: tokens,
      })

      onSuccess()
    } catch (err) {
      console.error(err)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deployForm, initCampaign, asyncState])

  return (
    <Presenter
      asyncState={asyncState}
      onClick={handleClick}
      nRewards={deployForm.rewards.length}
    />
  )
}

export const NotifyRewardAmount = ({ contractAddress, onSuccess }: Props) => {
  const deployForm = useDeployCampaignForm()
  const [notify, asyncState] = useNotifyRewards()

  const handleClick = useCallback(async () => {
    await notify(contractAddress, deployForm.rewards)
    onSuccess()
  }, [notify, contractAddress, deployForm, onSuccess])

  return (
    <Presenter
      asyncState={asyncState}
      onClick={handleClick}
      nRewards={deployForm.rewards.length}
    />
  )
}

export const Presenter = ({
  asyncState,
  onClick,
  nRewards,
}: {
  asyncState: AsyncState
  onClick: () => void
  nRewards: number
}) => {
  const theme = useTheme()
  return (
    <div className="p-4" style={{ backgroundColor: theme.background.page }}>
      {asyncState.activity ? (
        <Activity />
      ) : (
        <Button
          title={`Deposit ${nRewards > 1 ? 'Tokens' : 'Token'} and Launch`}
          variant="primary"
          onPress={onClick}
        />
      )}
      {asyncState.ind.message && (
        <div className="mt-4 flex justify-center">
          <Indicator
            message={asyncState.ind.message}
            level={asyncState.ind.level}
          />
        </div>
      )}
    </div>
  )
}
