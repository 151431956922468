import { BigNumber } from "ethers";

export const SET_BALANCE = "SET_BALANCE";
export const CLEAR_BALANCES = "CLEAR_BALANCES";

export type BalanceData = {
  tokenAddress: string;
  holderAddress: string;
  balance: BigNumber;
};
